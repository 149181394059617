import React from "react";
import {
  Show,
  TextField,
  SimpleShowLayout,
  DateField,
  TopToolbar,
  EditButton,
  DeleteWithConfirmButton,
  BooleanInput,
  ImageField,
  BooleanField,
} from "react-admin";
import { ContentPageSX, SimpleShowLayoutSX } from "../../../styled/custom";

const TestimonialShowActions = () => (
  <TopToolbar>
    <EditButton />
    <DeleteWithConfirmButton />
  </TopToolbar>
);

const TestimonialShow = (props) => (
  <Show
    title="Depoimento"
    sx={ContentPageSX}
    actions={<TestimonialShowActions />}
  >
    <SimpleShowLayout sx={SimpleShowLayoutSX}>
      <TextField source="id" />
      <TextField source="name" label="Nome" sortable={false} />
      <TextField source="position" label="Cargo" sortable={false} />
      <TextField source="radio" label="Radio" sortable={false} />
      <TextField source="local" label="Local" sortable={false} />
      <TextField source="message" label="Mensagem" sortable={false} />
      <ImageField source="url" title="Image" />
      <BooleanField source="active" label="Ativo" />

      <DateField
        source="created_at"
        locales="pt-BR"
        label="Criado em"
        sortable={false}
        showTime
      />
    </SimpleShowLayout>
  </Show>
);

export default TestimonialShow;

import { React, useState } from "react";
import {
  Create,
  SimpleForm,
  FileInput,
  FileField,
  useGetList,
} from "react-admin";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { DateInput, TimeInput } from "../../../ui/custom-date-time-pickers";
import { ContentPageSX } from "../../../styled/custom";
import CustomTextField from "../../../ui/custom-text-field";
import CustomSelect from "../../../ui/custom-select";
import CustomAutocomplete from "../../../ui/custom-autocomplete";
import GroupFields from "../../../styled/group-fields";
import {
  hasValue,
  minLength,
  nowOrFutureDate,
  isHour,
  filesSize,
  leastOneOption,
} from "../../../utils/schemas";
import CustomMultiDatePicker from "../../../ui/custom-multi-date-picker";

const Schema = Yup.object({
  client_id: hasValue("Selecione um cliente"),
  type_plan_id: hasValue("Selecione um tipo de plano"),
  days_group_id: hasValue("Selecione um grupo de dias"),
  type_spot_id: hasValue("Selecione um tipo de spot"),
  type_scheme_id: hasValue("Selecione um esquema de veiculação"),
  name: minLength(3, "Digite o nome"),
  agency: minLength(3, "Digite a agência"),
  commercial_executive: minLength(3, "Digite a executiva comercial"),
  broadcaster: minLength(3, "Digite a emissora"),
  date_init_broadcast: nowOrFutureDate(
    "Selecione a data de início do período de veiculação"
  ),
  date_end_broadcast: nowOrFutureDate(
    "Selecione a data de término do período de veiculação"
  ),
  hour_init_broadcast: isHour(
    "Selecione a hora de início do período de veiculação"
  ),
  hour_end_broadcast: isHour(
    "Selecione a hora de término do período de veiculação"
  ),
  days_broadcast: leastOneOption("Selecione o(s) dia(s) de veiculação"),
  files: filesSize(
    200000000,
    "Selecione 1 ou mais arquivos com tamanho máximo de 200 MB no total"
  ),
});

const SpotCreate = () => {
  const [typePlanFilter, setTypePlanFilter] = useState(null);
  const { data: dataClient, isLoading: isLoadingClient } = useGetList("client");
  const { data: dataTypePlan, isLoading: isLoadingTypePlan } =
    useGetList("type-plan");
  const { data: dataGroup, isLoading: isLoadingGroup } =
    useGetList("days-group");
  const { data: dataTypeSpot, isLoading: isLoadingTypeSpot } =
    useGetList("type-spot");
  const { data: dataDaysGroup, isLoading: isLoadingDaysGroup } =
    useGetList("type-scheme");
  const { data: dataCities, isLoading: isLoadingCities } =
    useGetList("setup/cities");
  const { data: dataRegion, isLoading: isLoadingRegion } = useGetList("region");
  const { data: dataCustomer, isLoading: isLoadingCustomer } = useGetList(
    "customer",
    { filter: { type_plan_id: typePlanFilter } }
  );

  const [initDate, setInitDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  return (
    <Create title="Novo Mapa de Programação" redirect="list" sx={ContentPageSX}>
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
      >
        <GroupFields>
          {!isLoadingClient && (
            <CustomSelect
              data={dataClient}
              name="client_id"
              label="Cliente"
              multiple={false}
              fullWidth
              isRequired
            />
          )}
          {!isLoadingTypePlan && (
            <CustomSelect
              data={dataTypePlan}
              onChange={(e) => {
                setTypePlanFilter(e);
              }}
              name="type_plan_id"
              label="Tipo de Plano"
              multiple={false}
              fullWidth
              isRequired
            />
          )}
        </GroupFields>
        <GroupFields>
          {!isLoadingGroup && (
            <CustomSelect
              data={dataGroup}
              name="days_group_id"
              label="Dias"
              multiple={false}
              fullWidth
              isRequired
            />
          )}
          {!isLoadingTypeSpot && (
            <CustomSelect
              data={dataTypeSpot}
              name="type_spot_id"
              label="Tempo de Spot"
              multiple={false}
              fullWidth
              isRequired
            />
          )}
          {!isLoadingDaysGroup && (
            <CustomSelect
              data={dataDaysGroup}
              name="type_scheme_id"
              label="Tipo de Veiculação"
              multiple={false}
              fullWidth
              isRequired
            />
          )}
        </GroupFields>

        <CustomTextField
          name="name"
          label="Nome da Campanha"
          fullWidth
          isRequired
        />

        <CustomTextField name="agency" label="Agência" fullWidth isRequired />
        <CustomTextField
          name="commercial_executive"
          label="Executiva Cormercial"
          fullWidth
          isRequired
        />
        <CustomTextField name="note" label="Observação" fullWidth />
        <CustomTextField
          name="broadcaster"
          label="Emissora"
          fullWidth
          isRequired
        />
        <GroupFields>
          <DateInput
            source="date_init_broadcast"
            label="Data de início do período de veiculação"
            fullWidth
            isRequired
            onAccept={(value) => setInitDate(value)}
          />

          <DateInput
            source="date_end_broadcast"
            label="Data de término do período de veiculação"
            fullWidth
            isRequired
            onAccept={(value) => setEndDate(value)}
          />
        </GroupFields>
        <GroupFields>
          <TimeInput
            source="hour_init_broadcast"
            label="Hora de Início da Veiculação"
            fullWidth
            isRequired
          />
          <TimeInput
            source="hour_end_broadcast"
            label="Hora de Término da Veiculação"
            fullWidth
            isRequired
          />
        </GroupFields>
        <GroupFields>
          <CustomMultiDatePicker
            name="days_broadcast"
            initDate={initDate}
            endDate={endDate}
            label="Dias de veiculação"
            callToAction="Adicionar"
            fullWidth
            isRequired
          />
        </GroupFields>
        <FileInput
          source="files"
          label="Tamanho Máximo dos Arquivos Somados: 200MB"
          multiple={true}
          maxSize={200000000}
          accept="application/*, video/*, audio/*, image/*"
        >
          <FileField src="src" title="title" />
        </FileInput>
        {!isLoadingCities && (
          <CustomAutocomplete
            data={dataCities}
            name="cities"
            label="Cidade(s)"
            fullWidth
            isrequired="true"
          />
        )}
        {!isLoadingRegion && (
          <CustomSelect
            data={dataRegion}
            name="regions"
            label="Região(ões)"
            fullWidth
          />
        )}
        {!isLoadingCustomer && (
          <CustomSelect
            data={dataCustomer}
            name="customers"
            label="Afiliado(s)"
            text="profile.name"
            fullWidth
          />
        )}
      </SimpleForm>
    </Create>
  );
};

export default SpotCreate;

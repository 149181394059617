import React from 'react';
import { Show, TextField, SimpleShowLayout, DateField, BooleanField } from 'react-admin';
import { ContentPageSX, SimpleShowLayoutSX } from '../../../styled/custom';

const NotificationShow = props => (
  <Show
    title="Notificação"
    sx={ContentPageSX}>
    <SimpleShowLayout
      sx={SimpleShowLayoutSX}>
      <TextField source="id" />
      <TextField source="customer_name" label="Afiliado" sortable={false} />
      <TextField source="title" label="Título" sortable={false} />
      <TextField source="message" label="Mensagem" sortable={false} />
      <BooleanField source="read" label="Lida" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
    </SimpleShowLayout>
  </Show>
);

export default NotificationShow;

import React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { DatagridSX, ListSX } from '../../../styled/custom';
import CustomFilterCustomerMedia from '../../../filter/custom-filter-customer-media';
import ButtonDownloadField from '../../../ui/button-download-field';
import ControlAudio from '../../../ui/control-audio';
import rowCustomerMediaStyle from '../../../ui/row-customer-media-style';

const CustomerMediaList = (props) => {

  return (
    <List
      title="Cesta de Produtos"
      filters={ CustomFilterCustomerMedia }
      actions={ null }
      sx={ListSX}>
      <Datagrid
        rowClick="show"
        bulkActionButtons={false}
        rowStyle={rowCustomerMediaStyle()}
        sx={DatagridSX}>
        <TextField source="id" sortable={false} />
        <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
        <TextField source="category_media_name" label="Categoria" sortable={false} />
        <TextField source="name" label="Nome" sortable={false} />
        <TextField source="type" label="Tipo" sortable={false} />
        <ButtonDownloadField source="media" label="Download" />
        <ControlAudio label="Player" />
      </Datagrid>
    </List>
  );
}

export default CustomerMediaList;

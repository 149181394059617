import React from "react";
import { useRecordContext } from "react-admin";

const styles =
{
  main:
  {
    display: "flex",
    flexWrap: "wrap"
  },
};

const LinkField = ({ source, text }) =>
{
  const record = useRecordContext();
  if (record)
  {
    const recordSource = record[source];
    if (recordSource)
    {
      return (
        <span style={styles.main}>
        {
          <a
            href={recordSource}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {text}
          </a>
        }
        </span>
      );
    } else return null;
  } else return null;
};

LinkField.defaultProps =
{
  addLabel: true,
  source: "Link",
};

export default LinkField;

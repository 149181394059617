import React from 'react';
import { Show, TextField, SimpleShowLayout, DateField } from 'react-admin';
import { ContentPageSX, SimpleShowLayoutSX } from '../../../styled/custom';
import CustomerMediaShowTitle from '../../../ui/customer-media-show-title';
import ButtonDownloadField from '../../../ui/button-download-field';
import ControlAudio from '../../../ui/control-audio';

const CustomerMediaShow = props => (
  <Show
    title={<CustomerMediaShowTitle />}
    sx={ContentPageSX}>
    <SimpleShowLayout
      sx={SimpleShowLayoutSX}>
      <TextField source="id" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
      <TextField source="category_media_name" label="Categoria" sortable={false} />
      <TextField source="name" label="Nome" sortable={false} />
      <TextField source="type" label="Tipo" sortable={false} />
      <ButtonDownloadField source="media" label="Download" />
      <ControlAudio label="Player" />
    </SimpleShowLayout>
  </Show>
);

export default CustomerMediaShow;

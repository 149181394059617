import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = typesPlan =>
{
  const data = typesPlan.map(typePlan => (
  {
    id: typePlan.id,
    nome: typePlan.name,
    criado_em: typePlan.created_at_format
  }));
  const csv = convertToCSV(
  {
      data,
      fields: ['id', 'nome', 'criado_em']
  });
  downloadCSV(csv, 'types-plan');
}

export default exporter;

import React from 'react';
import { Show, TextField, SimpleShowLayout, DateField, ArrayField, SingleFieldList, ChipField } from 'react-admin';
import { ContentPageSX, SimpleShowLayoutSX } from '../../../styled/custom';
import LinkField from '../../../ui/link-field';
import ControlAudio from '../../../ui/control-audio';

const SpotShow = props => (
  <Show
    title="Mapa de Programação"
    sx={ContentPageSX}>
    <SimpleShowLayout
      sx={SimpleShowLayoutSX}>
      <TextField source="id" />
      <TextField source="type_plan.name" label="Plano" sortable={false} />
      <TextField source="client_name" label="Cliente" sortable={false} />
      <TextField source="days_group.name" label="Dias da Semana" sortable={false} />
      <TextField source="type_spot.name" label="Tipo de Spot" sortable={false} />
      <TextField source="type_scheme.name" label="Esquema de Veiculação" sortable={false} />
      <TextField source="name" label="Nome da Campanha" sortable={false} />
      <TextField source="agency" label="Agência" sortable={false} />
      <TextField source="commercial_executive" label="Executiva Comercial" sortable={false} />
      <TextField source="note" label="Observação" sortable={false} />
      <TextField source="broadcaster" label="Emissora" sortable={false} />
      <TextField source="date_broadcast" label="Período" sortable={false} />
      <TextField source="hour_broadcast" label="Horário" sortable={false} />
      <TextField source="number_downloads" label="Downloads" sortable={false} />
      <ControlAudio multiple={true} label="Player" />
      <LinkField source="url" label="Url" text="Ver" sortable={false} />
      <TextField source="type" label="Tipo" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
      <ArrayField label="Cidades" source="cities">
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField label="Regiões" source="regions">
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField label="Afiliados" source="customers">
        <SingleFieldList linkType={false}>
          <ChipField source="profile.name" />
        </SingleFieldList>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export default SpotShow;

import React from "react";
import {
  Show,
  TextField,
  SimpleShowLayout,
  DateField,
  BooleanField,
  UrlField,
} from "react-admin";
import { ContentPageSX, SimpleShowLayoutSX } from "../../../styled/custom";

const MapLayerShow = (props) => (
  <Show title="Camada do Mapa" sx={ContentPageSX}>
    <SimpleShowLayout sx={SimpleShowLayoutSX}>
      <TextField source="id" />
      <TextField source="name" label="Nome" />
      <UrlField source="url" label="Endereço" />
      <BooleanField source="active" label="Ativo" />
      <DateField
        source="created_at"
        locales="pt-BR"
        label="Criado em"
        sortable={false}
        showTime
      />
    </SimpleShowLayout>
  </Show>
);

export default MapLayerShow;

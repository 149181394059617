import { Layout } from "react-admin";
import { LayoutRedSX } from "../../styled/custom";
import GeoAppBar from "./geo-app-bar";

const NoSidebar = () => null;

const AppBar = () => {
  return <GeoAppBar variant="red" />;
};

const GeoAppLayout = (props) => (
  <Layout {...props} appBar={AppBar} sx={LayoutRedSX} sidebar={NoSidebar} />
);

export default GeoAppLayout;

import React, { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import geoApi from "../geo/geo-api";
import { useDispatch, useSelector } from "react-redux";
import { setShowIntroduction } from "./store";

const TAB_NUMBERS = 0;
const TAB_TESTIMONIALS = 1;

const usePublicGetOne = (path) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchResource = async () => {
      setIsLoading(true);
      try {
        const res = await geoApi.get(path);
        setData(res.data.result);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchResource();
  }, [path]);

  return {
    data,
    isLoading,
  };
};

const usePublicGetList = (path) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchResource = async () => {
      try {
        setIsLoading(true);
        const res = await geoApi.get(path);
        setData(res.data.result);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchResource();
  }, [path]);

  return {
    data,
    isLoading,
  };
};

const BigNumberCard = ({ value, title }) => {
  return (
    <div>
      <Typography variant={"h1"}>
        <b>{value}</b>
      </Typography>
      <Typography variant={"h6"}>{title}</Typography>
    </div>
  );
};

const ShadowCard = ({ children, style }) => {
  return (
    <div
      style={{
        ...style,
        borderRadius: "12px",
        background: "rgba(0, 0, 0, 0.1)",
      }}
    >
      {children}
    </div>
  );
};

const BigNumberShadowCard = ({ value, title }) => {
  return (
    <ShadowCard>
      <Typography variant={"h2"}>
        <b>{value}</b>
      </Typography>
      <Typography variant={"h6"}>{title}</Typography>
    </ShadowCard>
  );
};

const Item = styled(Grid)(({ theme }) => ({
  textAlign: "center",
}));

const IntroductionGrid = ({ children, title }) => {
  return (
    <Grid container spacing={2} style={{ flex: 1 }}>
      <Item
        item
        sm={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant={"h5"}>{title}</Typography>
      </Item>
      {children}
    </Grid>
  );
};

const BigNumbers = () => {
  const { data, isLoading } = usePublicGetOne("presentation/numbers");
  return (
    <IntroductionGrid title={"Acompanhe nosso crescimento"}>
      <Item item sm={4}>
        <BigNumberCard
          value={isLoading ? "..." : data.counters.branchs}
          title={"Filiais"}
        />
      </Item>
      <Item item sm={4}>
        <BigNumberCard
          value={isLoading ? "..." : data.counters.affiliates}
          title={"Afiliadas"}
        />
      </Item>
      <Item item sm={4}>
        <BigNumberCard
          value={isLoading ? "..." : data.counters.stations}
          title={"Emissoras"}
        />
      </Item>

      <Item item sm={12}>
        <BigNumberShadowCard
          value={isLoading ? "..." : data.population}
          title="População alcançada"
        />
      </Item>
    </IntroductionGrid>
  );
};

const Testimonials = (props) => {
  const { data, isLoading } = usePublicGetList("presentation/testimonials");
  return (
    <IntroductionGrid title="Confira o que andam falando da gente!">
      <Item item sm={1}>
        <Typography variant="h1" style={{ fontSize: 300 }}>
          “
        </Typography>
      </Item>
      <Item
        item
        sm={10}
        style={{ display: "flex", flexDirection: "row", overflow: "hidden" }}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              overflow: "auto",
              justifyContent: "space-evenly",
            }}
          >
            {!isLoading &&
              data.map((t, index) => (
                <ShadowCard key={index} style={{ margin: "0.5rem" }}>
                  <div
                    style={{
                      padding: "1rem",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Avatar sx={{ width: 128, height: 128 }} src={t.url} />
                    <div>
                      <Typography>{t.name}</Typography>
                      <Typography variant="body2">
                        {t.position} - {t.radio}
                      </Typography>
                      <Typography variant="body2">{t.place}</Typography>
                      <Typography style={{ marginTop: "0.5rem" }}>
                        "{t.message}"
                      </Typography>
                    </div>
                  </div>
                </ShadowCard>
              ))}
          </div>
        </div>
      </Item>
      <Item item sm={1}>
        <Typography variant="h1" style={{ fontSize: 300 }}>
          „
        </Typography>
      </Item>
    </IntroductionGrid>
  );
};

export const Introduction = ({ open, onClose }) => {
  const [tab, setTab] = useState(TAB_NUMBERS);
  const showIntroduction = useSelector((state) => state.showIntroduction);
  const dispatch = useDispatch();

  const showNumbers = tab === TAB_NUMBERS;
  const showTestimonials = tab === TAB_TESTIMONIALS;
  return (
    <Dialog
      open={showIntroduction}
      onClose={() => dispatch(setShowIntroduction(false))}
      fullWidth
      maxWidth="xl"
      sx={{
        "& .MuiDialog-paper": {
          background: "#db1d3f",
          height: "70vh",
          color: "white",
          borderRadius: 3,
        },
      }}
    >
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant={showNumbers ? "white-rounded-filled" : "red-rounded"}
            onClick={() => setTab(TAB_NUMBERS)}
            style={{ marginRight: "1rem" }}
          >
            Em Números
          </Button>
          <Button
            variant={showTestimonials ? "white-rounded-filled" : "red-rounded"}
            onClick={() => setTab(TAB_TESTIMONIALS)}
          >
            Depoimentos
          </Button>
        </div>

        {showNumbers && <BigNumbers />}
        {showTestimonials && <Testimonials />}
      </DialogContent>
    </Dialog>
  );
};

import React, { useState, useEffect } from "react";
import {
  useRecordContext,
  useDataProvider,
  ShowBase,
} from "react-admin";
import CircularProgress from "@mui/material/CircularProgress";

const EmbedUrlViewer = ({ name, url }) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        position: "relative",
      }}
    >
      <iframe
        style={{
          border: "none",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        title={name}
        src={url}
      />
    </div>
  );
};

const DashboardContainer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState(null);
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  useEffect(() => {
    if (record && dataProvider) {
      const fecthUrl = async () => {
        setIsLoading(true);
        try {
          const result = await dataProvider.getOne("dashboard-embed-url", {
            id: record.id,
          });
          console.log(result);
          setUrl(result.data.EmbedUrl);
        } catch (e) {}
        setIsLoading(false);
      };
      if (!isLoading) fecthUrl();
    }
  }, [record, dataProvider]);
  if (isLoading || !record || !url) {
    return <CircularProgress />;
  } else {
    return <EmbedUrlViewer name={record.name} url={url} />;
  }
};

const DashboardShow = (props) => {
  return (
    <ShowBase resource="dashboard">
      <DashboardContainer />
    </ShowBase>
  );
};

export default DashboardShow;

import { TextField } from "@mui/material";
import { useInput } from "react-admin";

const CustomTextField = (props) => {
  const { onChange, onBlur, ...rest } = props;
  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
    isRequired,
  } = useInput({ onChange, onBlur, ...props });

  return (
    <TextField
      {...field}
      error={(isTouched || isSubmitted) && invalid}
      helperText={(isTouched || isSubmitted) && invalid ? error.message : ""}
      required={isRequired}
      {...rest}
    />
  );
};

export default CustomTextField;

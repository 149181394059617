import React from "react";
import {
  Show,
  TextField,
  SimpleShowLayout,
  DateField,
  TopToolbar,
  EditButton,
  DeleteWithConfirmButton,
} from "react-admin";
import { ContentPageSX, SimpleShowLayoutSX } from "../../../styled/custom";

const CustomerStaffMemberShowActions = () => (
  <TopToolbar>
    <EditButton />
    <DeleteWithConfirmButton />
  </TopToolbar>
);

const CustomerStaffMemberShow = (props) => (
  <Show
    title="Membro do staff"
    sx={ContentPageSX}
    actions={<CustomerStaffMemberShowActions />}
  >
    <SimpleShowLayout sx={SimpleShowLayoutSX}>
      <TextField source="id" />
      <TextField source="customer.name" label="Afiliada" sortable={false} />
      <TextField source="name" label="Nome" sortable={false} />
      <TextField source="position" label="Cargo" sortable={false} />
      <TextField source="email" label="E-mail" sortable={false} />
      <TextField source="phone" label="Telefone" sortable={false} />

      <DateField
        source="created_at"
        locales="pt-BR"
        label="Criado em"
        sortable={false}
        showTime
      />
    </SimpleShowLayout>
  </Show>
);

export default CustomerStaffMemberShow;

import React from 'react';
import { Edit, SimpleForm, BooleanInput, useGetList } from 'react-admin';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import EditTitle from '../../../ui/edit-title';
import CustomEditToolbar from '../../../ui/custom-edit-toolbar';
import CustomTextField from '../../../ui/custom-text-field';
import CustomSelect from '../../../ui/custom-select';
import { fullname, leastOneOption } from '../../../utils/schemas';
import { ContentPageSX } from '../../../styled/custom';

const Schema = Yup.object(
{
  name: fullname(),
  roles_id: leastOneOption()
});

const AdminEdit = () =>
{
  const { data, isLoading } = useGetList("setup/roles");
  return (
    <Edit
      title={ <EditTitle subtitle="Editar Usuário Admin: " source="name" /> }
      redirect="list"
      mutationMode="pessimistic"
      sx={ContentPageSX}>
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomEditToolbar /> }>
        <CustomTextField source="id" label="Id" disabled />
        <CustomTextField name="email" label="E-mail" fullWidth />
        <CustomTextField name="name" label="Nome" fullWidth />
        <CustomTextField name="password" type='password' fullWidth label="Senha" />
        {!isLoading && <CustomSelect data={data} name="roles_id" label="Permissão(ões)" fullWidth isRequired />}
        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Edit>
  )
}

export default AdminEdit;

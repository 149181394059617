import React from "react";
import {
  Edit,
  SimpleForm,
  FileInput,
  FileField,
  BooleanInput,
  useGetList,
} from "react-admin";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import EditTitle from "../../../ui/edit-title";
import CustomEditToolbar from "../../../ui/custom-edit-toolbar";
import CustomTextField from "../../../ui/custom-text-field";
import CustomSelect from "../../../ui/custom-select";
import { hasValue, minLength } from "../../../utils/schemas";
import { ContentPageSX } from "../../../styled/custom";
import { MEDIA_MAXIMUM_FILES_SIZE } from "./create";

const Schema = Yup.object({
  category_media_id: hasValue("Selecione uma categoria"),
  name: minLength(3, "Digite o nome"),
});

const MediaEdit = (props) => {
  const { data, isLoading } = useGetList("category-media", {
    pagination: { page: 1, perPage: 1000000 },
  });

  console.log(data);
  return (
    <Edit
      title={<EditTitle subtitle="Editar Produto da Cesta: " source="name" />}
      redirect="list"
      mutationMode="pessimistic"
      sx={ContentPageSX}
    >
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomEditToolbar />}
      >
        <CustomTextField source="id" label="Id" disabled />
        {!isLoading && (
          <CustomSelect
            data={data}
            name="category_media_id"
            label="Categoria"
            multiple={false}
            fullWidth
            isRequired
          />
        )}
        <CustomTextField name="name" label="Nome" fullWidth />
        <FileInput
          source="file"
          label="Tamanho Máximo do Arquivo: 600MB"
          maxSize={MEDIA_MAXIMUM_FILES_SIZE}
          accept="application/*,video/*,audio/*,image/*"
        >
          <FileField src="src" title="title" />
        </FileInput>
        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Edit>
  );
};

export default MediaEdit;

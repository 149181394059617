import React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { DatagridSX, ListSX } from '../../../styled/custom';
import CustomFilterSpotDownload from '../../../filter/custom-filter-spot-download';
import CustomListFilterExportToolbar from '../../../ui/custom-list-filter-export-toolbar';
import SpotDownloadExporter from '../../../exporter/spot-download-exporter';

const SpotDownloadList = () => (
  <List
    title="Downloads de Spot"
    sort={{ field: 'id', order: 'desc' }}
    filters={ CustomFilterSpotDownload }
    exporter={ SpotDownloadExporter }
    actions={ <CustomListFilterExportToolbar /> }
    sx={ListSX}>
    <Datagrid
      bulkActionButtons={false}
      sx={DatagridSX}>
      <TextField source="id" sortable={false} />
      <TextField source="spot.name" label="Spot" sortable={false} />
      <TextField source="customer.profile.name" label="Afiliado" sortable={false} />
      <TextField source="city" label="Cidade" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Data" sortable={false} showTime />
    </Datagrid>
  </List>
);

export default SpotDownloadList;

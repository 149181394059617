import { Create, SimpleForm, useGetList } from 'react-admin';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomTextField from '../../../ui/custom-text-field';
import CustomSelect from '../../../ui/custom-select';
import { ContentPageSX } from '../../../styled/custom';
import { email, fullname, leastOneOption, minLength } from '../../../utils/schemas';

const Schema = Yup.object(
{
  name: fullname(),
  email: email(),
  password: minLength(6, 'Senha inválida'),
  roles: leastOneOption()
});

const AdminCreate = () =>
{
  const { data, isLoading } = useGetList("setup/roles");
  return (
    <Create
      title="Novo Usuário Admin"
      redirect="list"
      sx={ContentPageSX}>
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}>
        <CustomTextField name="name" label="Nome" fullWidth isRequired />
        <CustomTextField name="email" label="E-mail" type="email" fullWidth isRequired  />
        <CustomTextField name="password" label="Senha" type="password" fullWidth isRequired  />
        { !isLoading && <CustomSelect data={data} name="roles" label="Permissão(ões)" fullWidth isRequired /> }
      </SimpleForm>
    </Create>
  )
}

export default AdminCreate;

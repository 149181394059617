import { Marker } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useGetList } from "react-admin";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerId, toggleCustomerProfile } from "../store";

const CustomerLayer = () => {
  const { data, isLoading } = useGetList("customer", {
    pagination: { page: 1, perPage: 1000000 },
    sort: { field: "id", order: "ASC" },
    filter: {
      activated: true,
    },
  });

  const [customers, setCustomers] = useState([]);

  const dispatch = useDispatch();
  const affiliatesLayerFilter = useSelector(
    (state) => state.affiliatesLayerFilter
  );
  const regionsFilter = useSelector(
    (state) => state.regionsFilter
  );

  useEffect(() => {
    if (data) {
      // console.log(data);
      setCustomers(data);
    }
  }, [data]);

  if (isLoading) return null;

  return customers
    .filter((c) => c.latitude && c.longitude)
    .filter((c) => affiliatesLayerFilter.indexOf(c.contract_model) >= 0)
    .filter((c) => {
      if (c.profile.region) {
        return (regionsFilter.indexOf(c.profile.region.id) >= 0);
      }
      return false;
    })
    .map((item) => (
      <Marker
        key={item.id}
        title={item.name}
        icon={
          item.contract_model === "FILIAL"
            ? "/assets/marker-red.png"
            : item.contract_model === "PROSPECÇÃO"
            ? "/assets/marker-purple.png"
            : item.contract_model === "SEDE"
            ? "/assets/marker-ita.png"
            : "/assets/marker-orange.png"
        }
        position={{ lat: item.latitude, lng: item.longitude }}
        zIndex={1}
        onClick={() => {
          dispatch(setCustomerId(item.id));
          dispatch(toggleCustomerProfile(true));
        }}
      />
    ));
};

export default CustomerLayer;

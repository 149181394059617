import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { SimpleForm, Toolbar, SaveButton, useNotify } from "react-admin";
import { Create, useGetList } from "react-admin";
import CustomAutocomplete from "../../ui/custom-autocomplete";
import Slide from "@mui/material/Slide";
import { hasValue, minLength, email, fullname } from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomTextField from "../../ui/custom-text-field";
import CustomSelect from "../../ui/custom-select";
import { useDispatch, useSelector } from "react-redux";
import { setShowAffiliateRequest } from "../geo/store";

const Schema = Yup.object({
  name: fullname(),
  email: email(),
  phone: minLength(2, "Digite o telefone"),
  city_id: hasValue(),
  radioclass: minLength(3, "Digite a classe da radio"),
  message: minLength(20, "Digite uma mensagem com pelo menos 20 caractéres"),
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export const AffiliationRequest = () => {
  const showAffiliationRequest = useSelector(
    (state) => state.showAffiliationRequest
  );
  const dispatch = useDispatch();
  const { data: dataCities, isLoading: isLoadingCities } =
    useGetList("setup/cities");
  const notify = useNotify();

  const handleClose = () => dispatch(setShowAffiliateRequest(false));
  const handleSucess = (data) => {
    dispatch(setShowAffiliateRequest(false));
    notify(
      "Sua solicitação foi enviada, entraremos em contato o mais breve possível!"
    );
  };

  return (
    <Dialog
      open={showAffiliationRequest}
      onClose={handleClose}
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: 3,
        },
      }}
      // TransitionComponent={Transition}
    >
      <Create resource="affiliation_request" disableAuthentication>
        <SimpleForm
          noValidate
          resolver={yupResolver(Schema)}
          toolbar={
            <Toolbar>
              <div style={{ width: "100%", textAlign: "right" }}>
                <Button variant="white-rounded" onClick={handleClose}>
                  Cancelar
                </Button>
                <SaveButton
                  type="button"
                  mutationOptions={{ onSuccess: handleSucess }}
                  variant="red-rounded"
                />
              </div>
            </Toolbar>
          }
        >
          <DialogTitle sx={{ textAlign: "center" }}>
            Quero ser afiliado
          </DialogTitle>
          <DialogContent style={{ display: "flex", flexDirection: "column" }}>
            <Grid container spacing={2} rowSpacing={1}>
              <Grid item xs={12}>
                <CustomTextField
                  name="name"
                  label="Nome"
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  name="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  name="phone"
                  label="Telefone"
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                {!isLoadingCities && (
                  <CustomAutocomplete
                    data={dataCities}
                    name="city_id"
                    label="Cidade"
                    multiple={false}
                    isrequired="true"
                    variant="outlined"
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  name="radioclass"
                  label="Classe da radio"
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <CustomTextField
                  name="message"
                  label="Mensagem"
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
        </SimpleForm>
      </Create>
    </Dialog>
  );
};

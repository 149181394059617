import { HeatmapLayer } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useNotify } from 'react-admin';
import axios from "../geo-api";


const fetchHeatMapLayer = async () => axios.get("/geo/layers/population");

const PopulationHeatmapLayer = () => {
  const notify = useNotify();
  const [points, setPoints] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await fetchHeatMapLayer();
        const data = response.data.result.map((i) => ({
          location: new window.google.maps.LatLng(i.latitude, i.longitude),
          weight: i.popresid,
        }));
        setPoints(data);
        setIsLoaded(true);
      } catch (e) {
        notify("Não foi possível carregar a camada mapa de calor!", { type: "error" });
        console.log(e);
      }
    };
    if (!isLoaded) loadData();
  }, []);

  if (isLoaded)
    return (
      <HeatmapLayer
        data={points}
        options={{
          radius: 10,
          dissipating: true,
        }}
      />
    );
  return null;
};

export default PopulationHeatmapLayer;
import { ReferenceInput, SelectInput } from "react-admin";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import CustomTextField from "../ui/custom-text-field";

const CustomFilterCustomerMedia = [
  <CustomTextField
    label="Buscar"
    source="q"
    alwaysOn
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput
    label="Categoria"
    alwaysOn
    source="category_media_id"
    reference="customer-category-media"
  >
    <SelectInput
      label="Categoria"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}
    />
  </ReferenceInput>
];

export default CustomFilterCustomerMedia;

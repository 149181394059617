import React from 'react';
import { Show, TextField, SimpleShowLayout, ArrayField, SingleFieldList, ChipField, DateField } from 'react-admin';
import { ContentPageSX, SimpleShowLayoutSX } from '../../../styled/custom';

const DaysGroupShow = () => (
  <Show
    title="Grupo de Dias"
    sx={ContentPageSX}>
    <SimpleShowLayout
      sx={SimpleShowLayoutSX}>
      <TextField source="id" />
      <TextField source="name" label="Nome" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
      <ArrayField label="Dias" source="days">
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export default DaysGroupShow;

import React from "react";
import { Create, FileField, FileInput, SimpleForm } from "react-admin";
import { parse } from "query-string";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomTextField from "../../../ui/custom-text-field";
import { ContentPageSX } from "../../../styled/custom";
import { email, hasValue, minLength } from "../../../utils/schemas";
import { useGetList } from "ra-core";
import CustomSelect from "../../../ui/custom-select";
import { useLocation } from "react-router";

const Schema = Yup.object({
  customer_id: hasValue(),
  title: minLength(3, "Digite o título"),
});

const CustomerNoteCreate = (props) => {
  const location = useLocation();
  const { customer_id } = parse(location.search);

  const redirect = customer_id ? `/customer/${customer_id}/show/notes` : "show";

  const { data: customer, isLoading: isLoadingCustomer } = useGetList(
    "customer",
    { pagination: { page: 1, perPage: 1000000 } }
  );

  console.log("Redirect", redirect);

  return (
    <Create title="Nova Nota do Afiliado" sx={ContentPageSX} redirect={redirect}>
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        defaultValues={{ customer_id }}
      >
        {!isLoadingCustomer && (
          <CustomSelect
            data={customer}
            name="customer_id"
            label="Afiliado"
            multiple={false}
            fullWidth
            isRequired
          />
        )}
        <CustomTextField name="title" label="Título" fullWidth isRequired />
        <CustomTextField name="message" label="Mensagem" fullWidth isRequired multiline />
        <FileInput source="files" label="Tamanho Máximo dos Arquivos Somados: 200 MB" multiple={true} maxSize={200000000}>
          <FileField src="src" title="title" source="src" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

export default CustomerNoteCreate;

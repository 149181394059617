import { ListBase, ListToolbar, Title, useListContext } from "react-admin";
import Maps from "../../../ui/maps";
import CustomListFilterToolbar from '../../../ui/custom-list-filter-toolbar';
import CustomFilterMediaDownloadMap from '../../../filter/custom-filter-media-download-map';

const WrapperMap = () =>
{
  const { data, error, isLoading } = useListContext();
  if (isLoading) return null;
  if (error) return <span>Error: {error}</span>

  return (
    <Maps
      markers={data.map((item) =>
      {
        return {
          id: item.id,
          item_name: item.media.name,
          customer_name: item.customer.profile.name,
          date: item.created_at_format,
          lat: item.lat,
          lng: item.lng,
          city: item.city
        };
      })}
    />
  );
};

const MediaDownloadMapList = () => {
  return (
    <ListBase>
      <ListToolbar
        filters={CustomFilterMediaDownloadMap}
        actions={<CustomListFilterToolbar />} />
      <Title
        title="Mapa de Download de Mídia" />
      <WrapperMap />
    </ListBase>
  )
}

export default MediaDownloadMapList;

import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateInput } from '../../../ui/custom-date-time-pickers';
import CustomTextField from '../../../ui/custom-text-field';
import { minLength, hasValue, nowOrFutureDate } from '../../../utils/schemas';
import { ContentPageSX } from '../../../styled/custom';

const Schema = Yup.object(
{
  name: minLength(3, 'Digite o nome'),
  number_inserts: hasValue('Digite a quantidade de inserções diárias'),
  contract_validity: nowOrFutureDate('Selecione a validade do contrato')
});

const ClientCreate = () => (
  <Create
    title="Novo Cliente"
    redirect="list"
    sx={ContentPageSX}>
    <SimpleForm
      noValidate
      resolver={yupResolver(Schema)}>
      <CustomTextField name="name" label="Nome" fullWidth isRequired />
      <CustomTextField type="number" name="number_inserts" label="Inserções Diárias" fullWidth isRequired/>
      <DateInput source="contract_validity" label="Validade do Contrato" fullWidth isRequired />
    </SimpleForm>
  </Create>
);

export default ClientCreate;

import { styled } from "@mui/material";

const ContentPageSX = {
  "& .RaShow-main": {
    margin: 0,
    padding: 0,
  },
  "& .RaCreate-main": {
    margin: 0,
  },
  "& .MuiPaper-root": {
    borderRadius: 0,
    boxShadow: "none",
    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  },
};

const LayoutSX = {
  backgroundColor: "white",
  "& .RaLayout-content": {
    backgroundColor: "#F7FAFC",
    margin: 0,
    padding: 0,
    borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
    "& .MuiToolbar-root": {
      minHeight: 40,
      height: 0,
      margin: "10px",
      padding: 0,
      alignItems: "center",
      "&> form": {
        minHeight: "100%",
        height: 0,
        margin: 0,
        alignItems: "center",
        "& .MuiFormControl-root": {
          margin: 0,
        },
      },
      "&> .MuiToolbar-root": {
        minHeight: "100%",
        height: 0,
        margin: 0,
        padding: 0,
      },
    },
    "& .MuiTablePagination-root": {
      height: 0,
      minHeight: 48,
      "& .MuiToolbar-root": {
        margin: 0,
      },
    },
    "& .MuiCardContent-root": {
      padding: "16px",
    },
  },
};

const LayoutRedSX = {
  backgroundColor: "white",
  "& .RaLayout-appFrame": {
    marginTop: "60px"
  },
  "& .RaLayout-content": {
    backgroundColor: "#F7FAFC",
    margin: 0,
    padding: 0,
    borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
    "& .MuiToolbar-root": {
      minHeight: 40,
      height: 0,
      margin: "10px",
      padding: 0,
      alignItems: "center",
      "&> form": {
        minHeight: "100%",
        height: 0,
        margin: 0,
        alignItems: "center",
        "& .MuiFormControl-root": {
          margin: 0,
        },
      },
      "&> .MuiToolbar-root": {
        minHeight: "100%",
        height: 0,
        margin: 0,
        padding: 0,
      },
    },
    "& .MuiTablePagination-root": {
      height: 0,
      minHeight: 48,
      "& .MuiToolbar-root": {
        margin: 0,
      },
    },
    "& .MuiCardContent-root": {
      padding: "16px",
    },
  },
};

const AppBarSX = {
  backgroundColor: "white",
  color: "black",
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
  "& .RaAppBar-toolbar": {
    paddingRight: "24px",
    paddingLeft: "24px",
  },
  "& .RaAppBar-menuButton": {
    display: "none",
  },
};

const AppBarRedSX = {
  backgroundColor: "#db1d3f",
  color: "whire",
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
  "& .RaAppBar-toolbar": {
    paddingRight: "24px",
    paddingLeft: "24px",
  },
  "& .RaAppBar-menuButton": {
    display: "none",
  },
  // zIndex: (theme) => theme.zIndex.drawer + 1
};

const TypographySX = {
  textAlign: "center",
  flexGrow: 1,
  fontSize: "1.5rem",
  fontWeight: "bold",
};

const ListSX = {
  backgroundColor: "#F7FAFC",
  "& .RaList-content": {
    boxShadow: "none",
    borderRadius: 0,
  },
};

const DatagridSX = {
  borderTop: "1px solid rgba(0, 0, 0, 0.1)",
  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  "& .MuiTableCell-head > span": {
    fontSize: "1.125rem",
    fontWeight: "600",
    minHeight: 60,
  },
  "& .MuiTableCell-body > span": {
    fontSize: "1rem",
  },
};

const SimpleShowLayoutSX = {
  "& .RaSimpleShowLayout-stack": {
    gap: 2,
  },
  "& .RaSimpleShowLayout-row": {
    margin: 0,
  },
  "& .RaSimpleShowLayout-row .RaLabeled-label": {
    fontSize: "1.125rem",
    fontWeight: 600,
    color: "#2f2f2f",
    margin: 0,
  },
  "& .RaSimpleShowLayout-row .MuiTypography-body2": {
    fontSize: "1rem",
    margin: 0,
  },
  "& .RaSimpleShowLayout-row .css-1poiti1-RaSingleFieldList-root": {
    marginTop: "2px",
    gap: 1,
  },
  "& .RaSimpleShowLayout-row .MuiChip-root": {
    margin: 0,
  },
};

const GroupStyled = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 4,
});

const CapStyled = styled("strong")({
  fontWeight: 600,
});

export {
  ContentPageSX,
  LayoutSX,
  LayoutRedSX,
  AppBarSX,
  AppBarRedSX,
  TypographySX,
  ListSX,
  DatagridSX,
  SimpleShowLayoutSX,
  GroupStyled,
  CapStyled,
};

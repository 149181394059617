import React from 'react';
import { Create, SimpleForm, useGetList } from 'react-admin';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomSelect from '../../../ui/custom-select';
import CustomTextField from '../../../ui/custom-text-field';
import { ContentPageSX } from '../../../styled/custom';
import { leastOneOption, minLength } from '../../../utils/schemas';

const Schema = Yup.object(
{
  name: minLength(3, "Digite o nome"),
  days: leastOneOption("Selecione pelo menos um dia")
});

const DaysGroupCreate = () =>
{
  const { data, isLoading } = useGetList("setup/days");
  return (
    <Create
      title="Nova Grupo de Dias"
      redirect="list"
      sx={ContentPageSX}>
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}>
        <CustomTextField name="name" label="Nome" fullWidth isRequired/>
        { !isLoading && <CustomSelect data={data} name="days" label="Dia(s)" fullWidth isRequired /> }
      </SimpleForm>
    </Create>
  );
}

export default DaysGroupCreate;

import React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { DatagridSX, ListSX } from '../../../styled/custom';
import CustomFilterSpotReceipt from '../../../filter/custom-filter-spot-receipt';
import CustomListFilterExportToolbar from '../../../ui/custom-list-filter-export-toolbar';
import SpotReceiptExporter from '../../../exporter/spot-receipt-exporter';
import LinkField from '../../../ui/link-field';

const SpotReceiptList = () => (
  <List
    title="Comprovantes de Veiculação"
    sort={{ field: 'id', order: 'desc' }}
    filters={ CustomFilterSpotReceipt }
    exporter={ SpotReceiptExporter }
    actions={ <CustomListFilterExportToolbar /> }
    sx={ListSX}>
    <Datagrid
      bulkActionButtons={false}
      sx={DatagridSX}>
      <TextField source="id" sortable={false} />
      <TextField source="spot.name" label="Spot" sortable={false} />
      <TextField source="customer.profile.name" label="Afiliado" sortable={false} />
      <LinkField source="url" label="Url" text="Ver" sortable={false} />
      <TextField source="type" label="Tipo" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Data" sortable={false} showTime />
    </Datagrid>
  </List>
);

export default SpotReceiptList;

import React, { useEffect, useState } from "react";

const DelayedRender = ({ delay, children }) => {
  const [mount, setMount] = useState(false);
  useEffect(() => {
    const t = setTimeout(() => {
      setMount(true);
    }, delay);
    return () => clearTimeout(t);
  }, []);
  if (mount) return children;
  return null;
};

export default DelayedRender;

import React from 'react';
import { Show, TextField, SimpleShowLayout, DateField } from 'react-admin';
import { ContentPageSX, SimpleShowLayoutSX } from '../../../styled/custom';

const ClientShow = () => (
  <Show
    title="Cliente"
    sx={ContentPageSX}>
    <SimpleShowLayout
      sx={SimpleShowLayoutSX}>
      <TextField source="id" />
      <TextField source="name" label="Nome" sortable={false} />
      <TextField source="contract_validity_format" label="Validade do Contrato" sortable={false} />
      <TextField source="number_inserts" label="Inserções Diárias" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
    </SimpleShowLayout>
  </Show>
);

export default ClientShow;

import { ReferenceInput, SelectInput } from "react-admin";

const CustomFilterSpotFile = [
  <ReferenceInput label="Spot" source="spot_id" reference="spot" alwaysOn>
    <SelectInput
      label="Spot"
      optionText="name"
      optionValue="id"
      emptyValue={null}
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}
    />
  </ReferenceInput>
];

export default CustomFilterSpotFile;

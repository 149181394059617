var env = process.env.REACT_APP_NODE_ENV || "production";

var config = {
  development: {
    api: {
      api_key: "3af31445388dc6233e3f0e9cd7faea47",
      url: "https://api-dev.itasat.com.br"
    },
    local_storage: {
      user: "user_admin_ita_sat",
      auth: "auth_admin_ita_sat",
    },
    jwt: {
      key: "daad555d4213482ba5bee8a1914af66c",
    },
    roles: {
      admin_users_admin: "d13c859e12843a57b3af252d1467ff48",
      admin_regions: "ae8ae57ff8d20bb7643f3458fb9d77bd",
      admin_days_groups: "56016b491838990ddd7fe0ccc48ad0a0",
      admin_types_spot: "6ebfec8fe3fafb82ce8050d552d137de",
      admin_types_scheme: "5e597163972d476d4aaa01b24dc6dbf5",
      admin_types_plan: "ac4543ad5600b7709cbda05600b3d854",
      admin_programs: "7808644ae6f36f44bcc247a4ad68fa36",
      admin_categories_media: "c2b48e0a035b7438da72890ee3da15ee",
      admin_medias: "079824cfee13b20fcc21d944aa0ae8e8",
      admin_clients: "a8abb5414adaba502a79d9250387cedc",
      admin_customers: "f460f2d44ca88b5ed561910e5019578e",
      admin_spots: "39af766b78ed6b17529426c9cb58bc51",
      admin_notifications: "ab90350080e62058ebf85eaa777c7889",
      admin_map_layers: "2025f08112ef01917c72d348cd3462ba",
      admin_gis: "0e17a188687a296bb99a99489d15693c",
      admin_dashboards: "698df10d05725dc59398b573d4792e6b",
    },
    files_formats_accept: [
      "application/octet-stream",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/gzip",
      "application/pdf",
      "application/rtf",
      "application/atom+xml, but application/xml",
      "application/zip",
      "video/mp4",
      "video/mpeg",
      "audio/mpeg",
      "audio/3gpp",
      "image/jpg",
      "image/jpeg",
      "image/png",
    ],
  },
  production: {
    api: {
      api_key: "3af31445388dc6233e3f0e9cd7faea47",
      url: "https://api.itasat.com.br",
    },
    local_storage: {
      user: "user_admin_ita_sat",
      auth: "auth_admin_ita_sat",
    },
    jwt: {
      key: "daad555d4213482ba5bee8a1914af66c",
    },
    roles: {
      admin_users_admin: "d13c859e12843a57b3af252d1467ff48",
      admin_regions: "ae8ae57ff8d20bb7643f3458fb9d77bd",
      admin_days_groups: "56016b491838990ddd7fe0ccc48ad0a0",
      admin_types_spot: "6ebfec8fe3fafb82ce8050d552d137de",
      admin_types_scheme: "5e597163972d476d4aaa01b24dc6dbf5",
      admin_types_plan: "ac4543ad5600b7709cbda05600b3d854",
      admin_programs: "7808644ae6f36f44bcc247a4ad68fa36",
      admin_categories_media: "c2b48e0a035b7438da72890ee3da15ee",
      admin_medias: "079824cfee13b20fcc21d944aa0ae8e8",
      admin_clients: "a8abb5414adaba502a79d9250387cedc",
      admin_customers: "f460f2d44ca88b5ed561910e5019578e",
      admin_spots: "39af766b78ed6b17529426c9cb58bc51",
      admin_notifications: "ab90350080e62058ebf85eaa777c7889",
      admin_map_layers: "2025f08112ef01917c72d348cd3462ba",
      admin_gis: "0e17a188687a296bb99a99489d15693c",
      admin_dashboards: "698df10d05725dc59398b573d4792e6b",
    },
    files_formats_accept: [
      "application/octet-stream",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/gzip",
      "application/pdf",
      "application/rtf",
      "application/atom+xml, but application/xml",
      "application/zip",
      "video/mp4",
      "video/mpeg",
      "audio/mpeg",
      "audio/3gpp",
      "image/jpg",
      "image/jpeg",
      "image/png",
    ],
  },
};

module.exports = config[env];

import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = medias =>
{
  const data = medias.map(media => (
  {
    id: media.id,
    categoria: media.category_media.name,
    nome: media.name,
    descricao: media.description,
    url: media.url,
    tipo: media.type,
    criado_em: media.created_at_format
  }));
  const csv = convertToCSV(
  {
    data,
    fields: ['id', 'categoria', 'nome', 'descricao', 'url', 'type', 'criado_em']
  });
  downloadCSV(csv, 'medias');
}

export default exporter;

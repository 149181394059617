import { Box, Stack, Typography } from "@mui/material";
import logo from "../assets/logo-red.png";

function CenterSimpleContent({ title, children, footer }) {
  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Stack spacing={2} justifyContent="center" alignItems="center">
        <Stack spacing={2} justifyContent="center" alignItems="center">
          <img src={logo} width="25%" alt="Itatiaia" />
          {title && <Typography variant="subtitle1">{title}</Typography>}
        </Stack>
        {children}
        <Box sx={{ display: "flex", alignSelf: "center" }}>{footer}</Box>
      </Stack>
    </Box>
  );
}

export default CenterSimpleContent;

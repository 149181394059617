import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = programs =>
{
  const data = programs.map(program => (
  {
    id: program.id,
    nome: program.name,
    criado_em: program.created_at_format
  }));
  const csv = convertToCSV(
  {
    data,
    fields: ['id', 'nome', 'criado_em']
  });
  downloadCSV(csv, 'programs');
}

export default exporter;

import { useState, useEffect, useRef } from "react";
import { GoogleMap, Marker, Circle, InfoWindow } from "@react-google-maps/api";

const radiusScales = [1000,2000,3000,4000,5000];
const AnimatedCircle = ({ id, lat, lng }) => {
  const [radius, setRadius] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      console.log("Animando");
      setRadius(r => (r + 1) % radiusScales.length);
    }, 500);
    return () => {
      console.log("Limpando animação");
      clearInterval(timer);
    }
  }, []);
  return (
    <>
    <Circle key="anim" center={{ lat: lat, lng: lng }} radius={radiusScales[radius]} options={{
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: "#FF0000",
      fillOpacity: 0.35,}}/>
    {/*<Circle key="outer" center={{ lat: lat, lng: lng }} radius={radiusScales[4]} />*/}
    </>
  )
}

const MyMarker = ({ id, item_name, customer_name, date, lat, lng, city }) =>
{
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Marker
      title={id.toString()}
      label={id.toString()}
      position={{ lat: lat, lng: lng }}
      onClick={() => setIsOpen(true)}
      zIndex={id}>
      {
        isOpen && (
          <InfoWindow
            position={{ lat: lat, lng: lng }}
            onCloseClick={() => setIsOpen(false)}>
            <div>
              <b>Id:</b> {id} <br/>
              <b>Nome:</b> {item_name} <br/>
              <b>Afiliado:</b> {customer_name} <br/>
              <b>Data:</b> {date} <br/>
              <b>Latitude:</b> {lat} <br/>
              <b>Logitude:</b> {lng} <br/>
              <b>Cidade:</b> {city}
            </div>
          </InfoWindow>
        )
      }
    </Marker>
  );
};

const Maps = ({ markers, circles }) =>
{
  const [data, setData] = useState([]);

  useEffect(() =>
  {
    setData(markers);
    return () =>
    {
      setData([]);
    };
  }, [markers]);

  return (
    <GoogleMap
      mapContainerStyle=
      {
        {
          width: "100%",
          height: "100%"
        }
      }
      center={{ lat: -19.9109697, lng: -43.9466398 }}
      zoom={12}>
      {
        data.map((item, index) => (
          <MyMarker key={index} {...item} />
        ))
      }
      {/*{circles ? circles.map(f => <AnimatedCircle key={f.id} lat={f.lat} lng={f.lng} id={f.id} />) : null}*/}
    </GoogleMap>
  );
};

export default Maps;

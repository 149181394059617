import React from "react";
import IconButton from "@mui/material/IconButton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { GeoDrawer, GeoDrawerHeader } from "./geo-side-menu";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { toggleFeatureInformation } from "./store";
import CloseIcon from "@mui/icons-material/Close";
import ValueWithLabel from "../../ui/value-with-label";

const getMetadataForProperty = (metadata, property) => {
  if (metadata && metadata.hasOwnProperty(property)) return metadata[property];
  return null;
};

const joinStringList = (list) => {
  if (Array.isArray(list)) {
    return list.join(", ");
  }
  return list;
};

const InfoValue = ({ property, value, metadata }) => {
  let label = property;
  if (metadata) {
    label = metadata.label || property;
    if (metadata.type === "boolean") {
      return (
        <ValueWithLabel label={label} value={value ? "Sim" : "Não"} divider />
      );
    } else if (metadata.type === "string-list") {
      return (
        <ValueWithLabel label={label} value={joinStringList(value)} divider />
      );
    } else if (metadata.type === "color") {
      return (
        <ValueWithLabel
          label={label}
          value={
            <Box
              marginBottom={1}
              sx={{
                backgroundColor: value,
                width: "15px",
                height: "15px",
                display: "block",
              }}
            ></Box>
          }
          divider
        />
      );
    }
  }
  return <ValueWithLabel label={label} value={value} divider />;
};

const GeoInfoPanel = () => {
  const dispatch = useDispatch();
  const { showFeatureInformation, featureInformation } = useSelector(
    (state) => ({
      showFeatureInformation: state.showFeatureInformation,
      featureInformation: state.featureInformation,
    }),
    shallowEqual
  );

  return (
    <GeoDrawer open={showFeatureInformation}>
      <GeoDrawerHeader>
        <Typography sx={{ color: "white" }}>
          {featureInformation.name}
        </Typography>
        <Box textAlign="right" sx={{ color: "white" }}>
          <IconButton
            onClick={() => dispatch(toggleFeatureInformation(false))}
            color="inherit"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </GeoDrawerHeader>
      <Box paddingX={1} overflow="auto">
        {Object.keys(featureInformation.data).map((k) => (
          <InfoValue
            key={k}
            property={k}
            value={featureInformation.data[k]}
            metadata={getMetadataForProperty(featureInformation.metadata, k)}
          />
        ))}
      </Box>
    </GeoDrawer>
  );
};

export default GeoInfoPanel;

import React from "react";
import ReactDOM from "react-dom/client";
import { CssBaseline } from "@mui/material";
import reportWebVitals from "./reportWebVitals";
import App from './App';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <>
    <CssBaseline />
    <App />
  </>
  // </React.StrictMode>
);

reportWebVitals();

import {
  ReferenceInput,
  SelectInput,
  AutocompleteInput
} from "react-admin";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import CustomTextField from "../ui/custom-text-field";
import QuickFilter from "../ui/quick-filter";

const CustomFilterSpot = [
  <CustomTextField
    label="Buscar"
    source="q"
    alwaysOn
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput label="Tipo de Plano" source="type_plan_id" reference="type-plan">
    <SelectInput
      label="Tipo de Plano"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}
    />
  </ReferenceInput>,
  <ReferenceInput label="Grupo de Dias" source="days_group_id" reference="days-group">
    <SelectInput
      label="Grupo de Dias"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}
    />
  </ReferenceInput>,
  <ReferenceInput label="Tipo de Spot" source="type_spot_id" reference="type-spot">
    <SelectInput
      label="Tipo de Spot"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}
    />
  </ReferenceInput>,
  <ReferenceInput label="Tipo de Veiculação" source="type_scheme_id" reference="type-scheme">
    <SelectInput
      label="Tipo de Veiculação"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}
    />
  </ReferenceInput>,
  <ReferenceInput label="Cidade" source="city_id" reference="setup/cities">
    <AutocompleteInput
      label="Cidade"
      optionText="name"
      optionValue="id"
      limitChoicesToValue={true}
      shouldRenderSuggestions={(val) => {
        return val ? val.trim().length > 2 : false;
      }}
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}
    />
  </ReferenceInput>,
  <ReferenceInput label="Região" source="region_id" reference="region">
    <SelectInput
      label="Região"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}
    />
  </ReferenceInput>,
  <ReferenceInput label="Afiliado" source="customer_id" reference="customer">
    <SelectInput
      label="Afiliado"
      optionText="profile.name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}
    />
  </ReferenceInput>,
  <QuickFilter source="activated" label="Ativos" defaultValue={true} />,
  <QuickFilter source="disabled" label="Desativados" defaultValue={true} />
];

export default CustomFilterSpot;

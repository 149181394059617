import React from "react";
import Drawer from "@mui/material/Drawer";
import { RadioNetwork } from "./radio-network";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

export const GeoDrawer = ({ open, onClose, children, anchor }) => {
  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      variant="persistent"
      sx={{
        flexShrink: 0,
        height: "100%",
        "& .MuiDrawer-paper": {
          width: "30%",
          paddingTop: "64px",
          boxSizing: "border-box",
          position: "absolute",
          bottom: 0,
          overflow: "hidden",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          overflow: "hidden",
          height: "100%",
          width: "100%",
        }}
      >
        <div style={{ overflow: "auto", width: "100%" }}>{children}</div>
      </div>
    </Drawer>
  );
};

GeoDrawer.defaultProps = {
  anchor: "left"
}

export const GeoDrawerHeader = ({ children }) => {
  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.secondary.main,
        color: (theme) => theme.palette.gray[50],
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: (theme) => theme.spacing(1),
      }}
    >
      {children}
    </Box>
  );
};

export const GeoSideMenu = () => {
  const showItasatNetwork = useSelector((state) => state.showItasatNetwork);
  return (
    <Drawer
      anchor={"left"}
      open={showItasatNetwork}
      onClose={() => {}}
      variant="persistent"
      sx={{
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: "30%",
          marginTop: "64px",
          boxSizing: "border-box",
        },
      }}
    >
      <RadioNetwork />
    </Drawer>
  );
};

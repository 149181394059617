import { Button, styled } from "@mui/material";

const SubmitButton = styled(Button)(({ theme, full }) => ({
  display: "flex",
  // alignSelf: "flex-end",
  color: "black",
  fontWeight: 500,
  width: full ? "100%" : "auto",
  minHeight: "40px",
  fontSize: "0.9rem",
  padding: "3px 15px",
  textTransform: "initial",
  backgroundColor: "transparent", //theme.palette.grey[200],
  "&:hover": {
    backgroundColor: "transparent", //theme.palette.grey[300],
  },
}));

export default SubmitButton;

import React from "react";
import { Edit, FileField, FileInput, SimpleForm } from "react-admin";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomTextField from "../../../ui/custom-text-field";
import { ContentPageSX } from "../../../styled/custom";
import { email, hasValue, minLength } from "../../../utils/schemas";
import EditTitle from "../../../ui/edit-title";
import CustomEditToolbar from "../../../ui/custom-edit-toolbar";
import { useGetList } from "ra-core";
import CustomSelect from "../../../ui/custom-select";

const Schema = Yup.object({
  customer_id: hasValue(),
  title: minLength(3, "Digite o nome"),
});

const CustomerNoteEdit = () => {
  const { data: customer, isLoading: isLoadingCustomer } = useGetList(
    "customer",
    { pagination: { page: 1, perPage: 1000000 } }
  );

  return (
    <Edit
      title={<EditTitle subtitle="Editar Nota do Afiliado " source="title" />}
      redirect="list"
      mutationMode="pessimistic"
      sx={ContentPageSX}
    >
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomEditToolbar />}
      >
        {!isLoadingCustomer && (
          <CustomSelect
            data={customer}
            name="customer_id"
            label="Afiliado"
            multiple={false}
            fullWidth
            isRequired
          />
        )}

        <CustomTextField name="title" label="Título" fullWidth isRequired />
        <CustomTextField name="message" label="Mensagem" fullWidth isRequired multiline />
        <FileInput source="files" label="Tamanho Máximo dos Arquivos Somados: 200 MB" multiple={true} maxSize={200000000}>
          <FileField src="src" title="title" source="src" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};

export default CustomerNoteEdit;

import React from "react";
import { Edit, SimpleForm } from "react-admin";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomTextField from "../../../ui/custom-text-field";
import { ContentPageSX } from "../../../styled/custom";
import { email, hasValue, minLength } from "../../../utils/schemas";
import EditTitle from "../../../ui/edit-title";
import CustomEditToolbar from "../../../ui/custom-edit-toolbar";

const Schema = Yup.object({
});

const SettingsEdit = (props) => {

  return (
    <Edit
      resource="settings"
      title={<EditTitle subtitle="Configurações" source="name" />}
      mutationMode="pessimistic"
      sx={ContentPageSX}
      id={0}
    >
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomEditToolbar />}
      >
        <CustomTextField name="population" label="População alcançada" fullWidth isRequired />
        <CustomTextField name="coverage" label="Área de cobertura" fullWidth isRequired />
        <CustomTextField name="transmission_hours" label="Horas mensais de transmissão" fullWidth isRequired />
        
      </SimpleForm>
    </Edit>
  );
};

export default SettingsEdit;

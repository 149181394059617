import React from 'react';
import { List, Datagrid, TextField, DateField, EditButton, BooleanField } from 'react-admin';
import { DatagridSX, ListSX } from '../../../styled/custom';
import CustomFilterDefault from '../../../filter/custom-filter-default';
import CustomListDefaultToolbar from '../../../ui/custom-list-default-toolbar';
import TypeSpotExporter from '../../../exporter/type-spot-exporter';

const TypeSpotList = (props) => (
  <List
    title="Tempos de Spot"
    sort={{ field: 'id', order: 'desc' }}
    filters={ CustomFilterDefault }
    exporter={ TypeSpotExporter }
    actions={ <CustomListDefaultToolbar /> }
    sx={ListSX}>
    <Datagrid
      rowClick="show"
      bulkActionButtons={false}
      sx={DatagridSX}>
      <TextField source="id" sortable={false} />
      <TextField source="name" label="Nome" sortable={false} />
      <TextField source="time" label="Tempo" sortable={false} />
      <BooleanField source="active" label="Ativo" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
      <EditButton />
    </Datagrid>
  </List>
);

export default TypeSpotList;

import axios from "axios";
import { headers as getHeaders } from "../../provider/data-provider";
import config_ from "../../config/config";

const axiosApi = axios.create();

axiosApi.interceptors.request.use((config) => {
  config.baseURL = config_.api.url;
  const headers = getHeaders();
  Object.keys(headers).forEach((k) => {
    config.headers[k] = headers[k];
  });
  return config;
});

export const computeCoverageStatsWithinRadius = async (lat, lng, radius) => {
  return axiosApi.get(
    `/geo/calc/radius?lat=${encodeURIComponent(lat)}&lng=${encodeURIComponent(
      lng
    )}&radius=${radius}`
  );
};

export const computeCoverageStatsInsideArea = async (wkt) => {
  return axiosApi.get(`/geo/calc/polygon?wkt=${encodeURIComponent(wkt)}`);
};

export const computeCoverageByCustomerId = async (id) => {
  return axiosApi.get(`/geo/calc/customer?id=${encodeURIComponent(id)}`);
};

export const getRadioRankingHistory = async (id) => {
  return axiosApi.get(`/geo/layers/radios_ranking/${id}`);
};

export default axiosApi;

import { HeatmapLayer } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useNotify } from 'react-admin';
import axios from "../geo-api";


const fetchHeatMapLayer = async () => axios.get("/geo/layers/pib");

const PibHeatmapLayer = () => {
  const notify = useNotify();
  const [points, setPoints] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await fetchHeatMapLayer();
        const data = response.data.result.map((i) => ({
          location: new window.google.maps.LatLng(i.latitude, i.longitude),
          weight: i.pib,
        }));
        setPoints(data);
        setIsLoaded(true);
      } catch (e) {
        notify("Não foi possível carregar a camada mapa de calor!", { type: "error" });
        console.log(e);
      }
    };
    if (!isLoaded) loadData();
  }, []);

  if (isLoaded)
    return (
      <HeatmapLayer
        data={points}
        options={{
          radius: 20,
          dissipating: true,
          gradient: [
            "rgba(0, 0, 255, 0)",
            "rgba(0, 0, 255, 1)",
            "rgba(74, 0, 255, 1)",
            "rgba(128, 0, 255, 1)",
            "rgba(183, 0, 255, 1)",
            "rgba(227, 52, 255, 1)",
            "rgba(255, 100, 228, 1)",
            "rgba(255, 149, 183, 1)",
            "rgba(255, 197, 138, 1)",
            "rgba(255, 245, 92, 1)",
            "rgba(255, 0, 0, 1)"
        ]
        }}
      />
    );
  return null;
};

export default PibHeatmapLayer;
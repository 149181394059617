import React from "react";
import { Create, SimpleForm } from "react-admin";
import { parse } from "query-string";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomTextField from "../../../ui/custom-text-field";
import { ContentPageSX } from "../../../styled/custom";
import { email, hasValue, minLength } from "../../../utils/schemas";
import { useGetList } from "ra-core";
import CustomSelect from "../../../ui/custom-select";
import { useLocation } from "react-router";

const Schema = Yup.object({
  customer_id: hasValue(),
  name: minLength(3, "Digite o nome"),
  position: minLength(2, "Digite o cargo"),
  email: email("Digite o e-mail"),
});

const CustomerStaffMemberCreate = (props) => {
  const location = useLocation();
  const { customer_id } = parse(location.search);

  const redirect = customer_id ? `/customer/${customer_id}/show/staff` : "show";

  const { data: customer, isLoading: isLoadingCustomer } = useGetList(
    "customer",
    { pagination: { page: 1, perPage: 1000000 } }
  );

  console.log("Redirect", redirect);

  return (
    <Create title="Novo Membro do Staff" sx={ContentPageSX} redirect={redirect}>
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        defaultValues={{ customer_id }}
      >
        {!isLoadingCustomer && (
          <CustomSelect
            data={customer}
            name="customer_id"
            label="Afiliado"
            multiple={false}
            fullWidth
            isRequired
          />
        )}
        <CustomTextField name="name" label="Nome" fullWidth isRequired />
        <CustomTextField name="position" label="Cargo" fullWidth isRequired />
        <CustomTextField name="email" label="Email" fullWidth isRequired />
        <CustomTextField name="phone" label="Telefone" fullWidth isRequired />
      </SimpleForm>
    </Create>
  );
};

export default CustomerStaffMemberCreate;

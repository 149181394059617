import React from 'react';
import { List, Datagrid, TextField, DateField, EditButton, BooleanField } from 'react-admin';
import { DatagridSX, ListSX } from '../../../styled/custom';
import CustomFilterDefault from '../../../filter/custom-filter-default';
import CustomListDefaultToolbar from '../../../ui/custom-list-default-toolbar';
import TypePlanExporter from '../../../exporter/type-plan-exporter';

const TypePlanList = (props) => (
  <List
    title="Tipos de Plano"
    sort={{ field: 'id', order: 'desc' }}
    filters={ CustomFilterDefault }
    exporter={ TypePlanExporter }
    actions={ <CustomListDefaultToolbar /> }
    sx={ListSX}>
    <Datagrid
      rowClick="show"
      bulkActionButtons={false}
      sx={DatagridSX}>
      <TextField source="id" sortable={false} />
      <TextField source="name" label="Nome" sortable={false} />
      <BooleanField source="active" label="Ativo" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
      <EditButton />
    </Datagrid>
  </List>
);

export default TypePlanList;

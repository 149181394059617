import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  DeleteWithConfirmButton,
  BooleanField,
} from "react-admin";
import { DatagridSX, ListSX } from "../../../styled/custom";
import CustomListDefaultToolbar from "../../../ui/custom-list-default-toolbar";

const DashboardList = (props) => (
  <List
    title="Dashboards"
    sort={{ field: "id", order: "desc" }}
    filters={[]}
    exporter={null}
    actions={<CustomListDefaultToolbar />}
    sx={ListSX}
  >
    <Datagrid rowClick="show" bulkActionButtons={false} sx={DatagridSX}>
      <TextField source="id" sortable={true} />
      <TextField source="name" label="Nome" sortable={true} />
      <TextField source="quicksight_dashboard_id" label="Id do Quicksight" sortable={true} />
      <BooleanField source="active" label="Ativo" />
      <DateField
        source="created_at"
        locales="pt-BR"
        label="Criado em"
        sortable={false}
        showTime
      />
      <EditButton />
      <DeleteWithConfirmButton />
    </Datagrid>
  </List>
);

export default DashboardList;

import React from "react";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { GeoDrawer, GeoDrawerHeader } from "./geo-side-menu";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import {
  clearRegionQueries,
  removeRegion,
  setQueryColor,
  setQueryShape,
  toggleQueryTool,
  toggleRegionVisibility,
} from "./store";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ValueWithLabel from "../../ui/value-with-label";

const colorTable = [
  // ["#ba00ff", "#7308a5", "#ff7f00", "#007900", "#cc00af"],
  // ["#0000ff", "#00aeae", "#ff4600", "#ff0000", "#ffff00"],
  [
    "#ba00ff",
    "#7308a5",
    "#ff7f00",
    "#007900",
    "#cc00af",
    "#0000ff",
    "#00aeae",
    "#ff4600",
    "#ff0000",
    "#ffff00",
  ],
];

function localeFormatNumber(number) {
  if (isNaN(number) || !number) {
    return "0";
  }
  return parseInt(number).toLocaleString("pt-BR");
}

const Highlight = ({ children }) => {
  return <span style={{ color: "#2196f3" }}>{children}</span>;
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(1),
    "&.Mui-disabled": {},
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const CoverageCities = ({ total, cities }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <ValueWithLabel
        label="Cidades alcançadas"
        value={
          <Typography>
            <Box display="flex" flexDirection="row" alignItems="center">
              {open ? (
                <IconButton
                  onClick={() => setOpen(false)}
                  variant="outlined"
                  color="primary"
                >
                  <ExpandLessIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => setOpen(true)}
                  variant="outlined"
                  color="primary"
                >
                  <ExpandCircleDownIcon />
                </IconButton>
              )}
              <Highlight>{localeFormatNumber(total)}</Highlight>
            </Box>
          </Typography>
        }
        divider
        vertical
      />

      {open && (
        <Paper
          style={{
            pading: 0,
            maxHeight: "30vh",
            maxWidth: "100%",
            overflow: "auto",
          }}
        >
          <List dense sx={{ padding: 0 }}>
            {(cities || []).map((e) => (
              <ListItem key={e} divider>
                <ListItemText>{e}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </>
  );
};

export const GeoCoverageStats = ({ stats }) => {
  return (
    <>
      <CoverageCities cities={stats.cities_names} total={stats.cities} />

      <ValueWithLabel
        label="Habitantes alcançados"
        value={localeFormatNumber(stats.population)}
        divider
      />
      <ValueWithLabel
        label="Área (Km²)"
        value={localeFormatNumber(stats.area)}
        divider
      />
      <ValueWithLabel
        label="Hab. alcançados (proporcional a seleção)"
        value={localeFormatNumber(stats.partial_population)}
        divider
      />
      <ValueWithLabel
        label="Área (Km²) (proporcional a seleção)"
        value={localeFormatNumber(stats.partial_area)}
        divider
      />
    </>
  );
};

const GeoQueryToolPanel = () => {
  const dispatch = useDispatch();
  const { regionQueryList, showQueryTool, queryShape, queryColor } =
    useSelector(
      (state) => ({
        regionQueryList: state.regionQueryList,
        showQueryTool: state.showQueryTool,
        queryShape: state.queryShape,
        queryColor: state.queryColor,
      }),
      shallowEqual
    );

  return (
    <GeoDrawer open={showQueryTool}>
      <GeoDrawerHeader>
        <Box textAlign="right" sx={{ color: "white" }}>
          <IconButton
            onClick={() => dispatch(toggleQueryTool(false))}
            color="inherit"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant="h6">Consultar alcance</Typography>
        <Box
          sx={{ paddingY: 1 }}
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <ToggleButtonGroup
            color="secondary"
            value={queryShape}
            exclusive
            onChange={(e, value) => dispatch(setQueryShape(value))}
            aria-label="Platform"
            size="small"
            sx={{
              background: (theme) => theme.palette.grey[50],
              height: "auto",
            }}
          >
            <ToggleButton value="circle">RAIO</ToggleButton>
            <ToggleButton value="polygon">POLÍGONO</ToggleButton>
            <ToggleButton value="none">NORMAL</ToggleButton>
          </ToggleButtonGroup>

          <Box display="flex" flexDirection="column" alignItems="center">
            {colorTable.map((group, idx) => (
              <StyledToggleButtonGroup
                key={group}
                value={queryColor}
                exclusive
                onChange={(e, value) => dispatch(setQueryColor(value))}
                aria-label="Platform"
                variant=""
                sx={{ background: (theme) => theme.palette.divider }}
              >
                {group.map((color) => (
                  <ToggleButton
                    key={color}
                    value={color}
                    sx={{
                      background: color,
                      boxSizing: "border-box",
                      "&.Mui-selected": {
                        background: color,
                        border:
                          color === queryColor ? "2px solid white" : "none",
                      },
                    }}
                  />
                ))}
              </StyledToggleButtonGroup>
            ))}
          </Box>
        </Box>

        <Button
          onClick={() => dispatch(clearRegionQueries())}
          variant="white-rounded"
        >
          Limpar
        </Button>
      </GeoDrawerHeader>

      <List>
        {regionQueryList.map((e, idx) => (
          <ListItem
            sx={{
              borderLeft: `4px solid ${e.color}`,
            }}
            divider
          >
            <ListItemIcon>
              <Checkbox
                color="secondary"
                checked={e.visible}
                onChange={(e, checked) =>
                  dispatch(
                    toggleRegionVisibility({ index: idx, value: checked })
                  )
                }
              />
            </ListItemIcon>
            <ListItemText>
              <GeoCoverageStats stats={e.stats} />
            </ListItemText>
            <ListItemSecondaryAction>
              <IconButton onClick={() => dispatch(removeRegion(idx))}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </GeoDrawer>
  );
};

export default GeoQueryToolPanel;

import { Layout } from "react-admin";
import CustomMenu from "./custom-menu";
import CustomAppBar from "./custom-app-bar";
import { LayoutSX } from "../styled/custom";

const CustomLayout = (props) => (
  <Layout {...props} menu={CustomMenu} appBar={CustomAppBar} sx={LayoutSX} />
);

export default CustomLayout;

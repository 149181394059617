import React, { useEffect } from "react";
import { Introduction } from "./geo/introduction";
import Box from "@mui/material/Box";
import GeoAppLayout from "./geo/geo-app-layout";
import { AffiliationRequest } from "./geo/affiliation-request";
import { GeoSideMenu } from "./geo/geo-side-menu";
import GeoMaps from "./geo/geo-maps";
import GeoLayers from "./geo/geo-layers";
import DelayedRender from "../ui/delayed-render";
import {
  Authenticated,
  useAuthState,
  useDataProvider,
  usePermissions,
} from "react-admin";
import PublicRadiosLayer from "./geo/layers/public-radios";
import GeoTools from "./geo/geo-tools";
import geoApi from "./geo/geo-api";
import { Provider, useDispatch, useSelector } from "react-redux";
import {
  getAppStore,
  setRegionsFilter,
  setIsAdmin,
  setMapLayers,
  setPublicRadios,
  setRegionsList,
} from "./geo/store";
import GeoQueryToolPanel from "./geo/geo-query-tool-panel";
import GeoCustomerProfile from "./geo/geo-customer-profile";
import GeoRadioProfile from "./geo/geo-radio-profile";
import GeoInfoPanel from "./geo/geo-info-panel";
import { RadioSelectorPopup } from "./geo/layers/radios";

const GeoMapsItems = () => {
  return (
    <DelayedRender delay={100}>
      <GeoLayers key="geo-layers" />
      <GeoTools />
    </DelayedRender>
  );
};

const GeoApp = () => {
  const { authenticated } = useAuthState();
  const { permissions } = usePermissions();
  const dataProvider = useDataProvider();
  const isAdmin = useSelector((state) => state.isAdmin);
  const dispatch = useDispatch();
  const loadingRegions = React.useRef(false);
  const loadingMapLayers = React.useRef(false);

  useEffect(() => {
    const condition =
      authenticated && permissions && permissions !== "admin-customer";
    dispatch(setIsAdmin(condition));
  }, [authenticated, permissions]);

  useEffect(() => {
    const fetchRadios = async () => {
      try {
        const result = await geoApi.get("/geo/public/radios");
        dispatch(setPublicRadios(result.data.result));
      } catch (err) {
        console.log(err);
      }
    };
    fetchRadios();
  }, []);

  useEffect(() => {
    if (!isAdmin) return;

    const fetchMapLayers = async () => {
      if (dataProvider) {
        loadingMapLayers.current = true;
        try {
          const result = await dataProvider.getList("map_layer", {
            pagination: { page: 1, perPage: 1000000 },
            sort: { field: "id", order: "ASC" },
            filter: { activated: true },
          });
          const layers = result.data.map((item) => ({
            ...item,
            visible: false,
            isLoading: false,
          }));

          dispatch(setMapLayers(layers));
        } catch (e) {}

        loadingMapLayers.current = false;
      }
    };
    if (!loadingMapLayers.current) fetchMapLayers();
  }, [dataProvider, isAdmin]);

  useEffect(() => {
    if (!isAdmin) return;

    const fetchRegionsList = async () => {
      if (dataProvider) {
        loadingRegions.current = true;
        try {
          const result = await dataProvider.getList("region", {
            pagination: { page: 1, perPage: 1000000 },
            sort: { field: "id", order: "ASC" },
            filter: { activated: true },
          });
          const regions = result.data.map((e) => ({
            id: e.id,
            name: e.name,
          }));
          const filter = regions.map((e) => e.id);

          dispatch(setRegionsList(regions));
          dispatch(setRegionsFilter(filter));
        } catch (e) {}
        loadingRegions.current = false;
      }
    };
    if (!loadingRegions.current) fetchRegionsList();
  }, [dataProvider, isAdmin]);

  return (
    <>
      <GeoAppLayout>
        <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
          <GeoSideMenu />
          {isAdmin && <GeoQueryToolPanel />}
          {isAdmin && <GeoCustomerProfile />}
          {isAdmin && <GeoRadioProfile />}
          {isAdmin && <GeoInfoPanel />}
          <Box sx={{ flexGrow: 1 }}>
            <GeoMaps>
              {isAdmin && <GeoMapsItems />}
              {!isAdmin && <PublicRadiosLayer />}
            </GeoMaps>
            <RadioSelectorPopup />
          </Box>
        </Box>
      </GeoAppLayout>
      <Introduction />
      <AffiliationRequest />
    </>
  );
};

export const GeoMainPage = (props) => {
  return (
    <Authenticated requireAuth>
      <Provider store={getAppStore()}>
        <GeoApp />
      </Provider>
    </Authenticated>
  );
};

export default GeoMainPage;

import { Labeled } from "react-admin";
import {
  Stack,
  TableCell,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";

const VStack = ({ children }) => {
  return (
    <Stack>
      <Labeled>{children}</Labeled>
    </Stack>
  );
};

const Line = styled("div")({
  display: "flex",
  gap: 8,
  flexGrow: 1,
  margin: "4px 0",
  alignItems: "center",
});

const Group = styled("div")({
  display: "flex",
  flexDirection: "column",
  flexBasis: "100%",
  gap: 8,
});

const StyledTableCell = styled(TableCell)(
  ({
    theme,
    backgroundColor,
    color,
    fontSize,
    textTransform,
    textAlign,
    padding,
    border,
  }) => ({
    [`&.${tableCellClasses.head}, &.${tableCellClasses.body}`]: {
      backgroundColor: backgroundColor || theme.palette.secondary.main,
      color: color || theme.palette.common.white,
      fontSize: fontSize || "1rem",
      textTransform: textTransform || "uppercase",
      textAlign: textAlign || "center",
      padding: padding || 0,
      border: border || "0.1px solid white",
    },
  })
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&.MuiTableRow-root": {
    backgroundColor: "transparent",
    borderBottom: "1px solid black",
  },
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {},
}));

export { VStack, Line, Group, StyledTableCell, StyledTableRow };

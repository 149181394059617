import React from 'react';
import { Create, SimpleForm, FileInput, FileField, useGetList } from 'react-admin';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomSelect from '../../../ui/custom-select';
import { ContentPageSX } from '../../../styled/custom';
import { file, hasValue } from '../../../utils/schemas';
import config from "../../../config/config";

const Schema = Yup.object(
{
  spot_id: hasValue("Selecione um spot"),
  file: file(20000000, "Arquivo muito grande", config.files_formats_accept)
});

const CustomerSpotReceiptCreate = () =>
{
  const { data, isLoading } = useGetList("customer-spot-receipt/spots");
  return (
    <Create
      title="Novo Comprovante de Veiculação"
      redirect="list"
      sx={ContentPageSX}>
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}>
        { !isLoading && <CustomSelect data={data} name="spot_id" label="Spot" multiple={false} fullWidth isRequired /> }
        <FileInput source="file" label="Tamanho Máximo do Arquivo: 20MB" maxSize={20000000} accept="application/*, video/*, audio/*, image/*">
          <FileField src="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
}

export default CustomerSpotReceiptCreate;

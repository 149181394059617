import {
  Menu,
  MenuItemLink,
  useResourceDefinitions,
  useSidebarState,
  usePermissions,
} from "react-admin";
import { Badge } from "@mui/material";
import useNotification from "../hook/useNotification";
import SettingsIcon from "@mui/icons-material/Settings";
import GeoIcon from "@mui/icons-material/TravelExplore";
import config from "../config/config";

const CustomMenu = (props) => {
  const resources = useResourceDefinitions();
  const [open] = useSidebarState();
  const [total] = useNotification();
  const { isLoading, permissions } = usePermissions();

  const hasGisPermissions =
    permissions &&
    permissions !== "admin-customer" &&
    permissions.some((e) => e.key === config.roles.admin_gis);

  return !isLoading ? (
    <Menu {...props}>
      {hasGisPermissions ? (
        <MenuItemLink
          key="geo"
          to={{ pathname: "/maps" }}
          primaryText="Mapa Itasat"
          leftIcon={<GeoIcon />}
          onClick={props.onMenuClick}
          sidebarIsOpen={open}
        />
      ) : null}

      {Object.keys(resources).map((name) => {
        const resource = resources[name];
        if (
          permissions === "admin-customer" ||
          permissions.some((e) => e.key === resource.options.role)
        ) {
          const LeftIcon = resource.icon;
          if (resource.name === "customer_notification") {
            return (
              <MenuItemLink
                key={name}
                to={`/${name}`}
                primaryText={
                  <Badge badgeContent={total} color="error" variant="dot">
                    Notificações
                  </Badge>
                }
                leftIcon={<LeftIcon />}
                onClick={props.onMenuClick}
                sidebarIsOpen={open}
              />
            );
          } else {
            return (
              <MenuItemLink
                key={name}
                to={`/${name}`}
                primaryText={
                  (resource.options && resource.options.label) || name
                }
                leftIcon={<LeftIcon />}
                onClick={props.onMenuClick}
                sidebarIsOpen={open}
              />
            );
          }
        } else return null;
      })}

      {hasGisPermissions ? (
        <MenuItemLink
          key="settings"
          to={{ pathname: "/settings" }}
          primaryText="Configurações"
          leftIcon={<SettingsIcon />}
          onClick={props.onMenuClick}
          sidebarIsOpen={open}
        />
      ) : null}
    </Menu>
  ) : null;
};

export default CustomMenu;

import React from 'react';
import { Show, SimpleShowLayout, TextField, ArrayField, SingleFieldList, ChipField, DateField } from 'react-admin';
import { ContentPageSX, SimpleShowLayoutSX } from '../../../styled/custom';

const AdminShow = () => (
  <Show
    title="Usuário Admin"
    sx={ContentPageSX}>
    <SimpleShowLayout
      sx={SimpleShowLayoutSX}>
      <TextField source="id"/>
      <TextField source="name" label="Nome" />
      <TextField source="email" label="E-mail" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
      <ArrayField source="roles" label="Permissões" fieldKey="uuid">
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export default AdminShow;

import React, { useEffect } from "react";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MapIcon from "@mui/icons-material/Map";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import SwitchRed from "../../styled/switch-red";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  setMapLayerVisible,
  setShowAffiliatesLayer,
  setShowCoverageLayer,
  setShowCoverageContourLayer,
  setShowCoverageSpotLayer,
  setShowPibLayer,
  setShowPopulationLayer,
  setShowRegionsLayer,
  toggleQueryTool,
  toggleAffiliatesLayerFilter,
  toggleRegionFilter,
  setShowRadiosnetLayer,
  setShowRadiosnetHeatmapLayer,
} from "./store";
import CircularProgress from "@mui/material/CircularProgress";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SouthAmericaIcon from "@mui/icons-material/SouthAmerica";

const RegionsFilter = () => {
  const [openRegionsList, setOpenRegionsList] = React.useState(false);
  const { showRegionsLayer, regionsList, regionsFilter } = useSelector(
    (state) => ({
      showRegionsLayer: state.showRegionsLayer,
      regionsList: state.regionsList,
      regionsFilter: state.regionsFilter,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const isChecked = (e) => regionsFilter.indexOf(e) >= 0;

  return (
    <>
      <ListItem>
        <ListItemIcon>
          <SwitchRed
            checked={showRegionsLayer}
            onChange={(e, checked) => dispatch(setShowRegionsLayer(checked))}
          />
        </ListItemIcon>
        <ListItemText>Regiões</ListItemText>
        <ListItemSecondaryAction>
          <IconButton onClick={() => setOpenRegionsList(!openRegionsList)}>
            {openRegionsList ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={openRegionsList} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {regionsList.map((e) => (
            <ListItem key={e.id} sx={{ pl: 5 }}>
              <ListItemIcon>
                <SouthAmericaIcon />
              </ListItemIcon>
              <ListItemText sx={{ textTransform: "capitalize" }}>
                {e.name.toLowerCase()}
              </ListItemText>
              <ListItemSecondaryAction>
                <Checkbox
                  checked={isChecked(e.id)}
                  onChange={(event) => dispatch(toggleRegionFilter(e.id))}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

const MapLayersView = () => {
  const mapLayers = useSelector((state) => state.mapLayers);
  const dispatch = useDispatch();
  return (
    <List dense>
      <ListItem divider>
        <ListItemText>Camadas personalizadas</ListItemText>
      </ListItem>

      {mapLayers.map((layer) => (
        <ListItem key={layer.id}>
          <ListItemIcon>
            <SwitchRed
              checked={layer.visible}
              onChange={(e, checked) => {
                dispatch(
                  setMapLayerVisible({ id: layer.id, visible: checked })
                );
              }}
            />
          </ListItemIcon>
          <ListItemText>{layer.name}</ListItemText>
          <ListItemSecondaryAction style={{ display: "flex" }}>
            {layer.isLoading && (
              <CircularProgress color="secondary" size={20} />
            )}
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};

const MapViews = () => {
  const {
    showAffiliatesLayer,
    showCoverageLayer,
    showPopulationLayer,
    showPibLayer,
    showQueryTool,
    showCoverageContourLayer,
    showCoverageSpotLayer,
    affiliatesLayerFilter,
    showRadiosnetLayer,
    showRadiosnetHeatmapLayer
  } = useSelector(
    (state) => ({
      showAffiliatesLayer: state.showAffiliatesLayer,
      showCoverageLayer: state.showCoverageLayer,
      showPopulationLayer: state.showPopulationLayer,
      showPibLayer: state.showPibLayer,
      showQueryTool: state.showQueryTool,
      showCoverageContourLayer: state.showCoverageContourLayer,
      showCoverageSpotLayer: state.showCoverageSpotLayer,
      affiliatesLayerFilter: state.affiliatesLayerFilter,
      showRadiosnetLayer: state.showRadiosnetLayer,
      showRadiosnetHeatmapLayer: state.showRadiosnetHeatmapLayer,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [openAffiliatesFilter, setOpenAffiliatesFilter] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "layers" : undefined;

  return (
    <>
      <Button
        startIcon={<MapIcon />}
        endIcon={<ExpandMoreIcon />}
        onClick={handleClick}
      >
        Visualizar por
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <List dense>
          <ListItem divider>
            <ListItemText>Camadas padrões</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SwitchRed
                checked={showPopulationLayer}
                onChange={(e, checked) =>
                  dispatch(setShowPopulationLayer(checked))
                }
              />
            </ListItemIcon>
            <ListItemText>Habitantes 2010 (Mapa de Calor)</ListItemText>
            <ListItemSecondaryAction />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SwitchRed
                checked={showPibLayer}
                onChange={(e, checked) => dispatch(setShowPibLayer(checked))}
              />
            </ListItemIcon>
            <ListItemText>PIB 2020 (Mapa de Calor)</ListItemText>
            <ListItemSecondaryAction />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SwitchRed
                checked={showRadiosnetLayer}
                onChange={(e, checked) => dispatch(setShowRadiosnetLayer(checked))}
              />
            </ListItemIcon>
            <ListItemText>Radios</ListItemText>
            <ListItemSecondaryAction />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SwitchRed
                checked={showRadiosnetHeatmapLayer}
                onChange={(e, checked) => dispatch(setShowRadiosnetHeatmapLayer(checked))}
              />
            </ListItemIcon>
            <ListItemText>Radios Audiência (Mapa de calor)</ListItemText>
            <ListItemSecondaryAction />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SwitchRed
                checked={showAffiliatesLayer}
                onChange={(e, checked) =>
                  dispatch(setShowAffiliatesLayer(checked))
                }
              />
            </ListItemIcon>
            <ListItemText>Afiliadas</ListItemText>
            <ListItemSecondaryAction>
              <IconButton
                onClick={() => setOpenAffiliatesFilter(!openAffiliatesFilter)}
              >
                {openAffiliatesFilter ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Collapse in={openAffiliatesFilter} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 5 }}>
                <ListItemIcon>
                  <img src="/assets/marker-ita.png" alt="" height="28" />
                </ListItemIcon>
                <ListItemText>Sede</ListItemText>
                <ListItemSecondaryAction>
                  <Checkbox
                    checked={affiliatesLayerFilter.indexOf("SEDE") >= 0}
                    onChange={(e) =>
                      dispatch(toggleAffiliatesLayerFilter("SEDE"))
                    }
                  />
                </ListItemSecondaryAction>
              </ListItemButton>
              <ListItemButton sx={{ pl: 5 }}>
                <ListItemIcon>
                  <img src="/assets/marker-red.png" alt="" height="28" />
                </ListItemIcon>
                <ListItemText>Filiais</ListItemText>
                <ListItemSecondaryAction>
                  <Checkbox
                    checked={affiliatesLayerFilter.indexOf("FILIAL") >= 0}
                    onChange={(e) =>
                      dispatch(toggleAffiliatesLayerFilter("FILIAL"))
                    }
                  />
                </ListItemSecondaryAction>
              </ListItemButton>
              <ListItemButton sx={{ pl: 5 }}>
                <ListItemIcon>
                  <img src="/assets/marker-orange.png" alt="" height="28" />
                </ListItemIcon>
                <ListItemText>Afiliadas</ListItemText>
                <ListItemSecondaryAction>
                  <Checkbox
                    checked={affiliatesLayerFilter.indexOf("AFILIADA") >= 0}
                    onChange={(e) =>
                      dispatch(toggleAffiliatesLayerFilter("AFILIADA"))
                    }
                  />
                </ListItemSecondaryAction>
              </ListItemButton>
              <ListItemButton sx={{ pl: 5 }}>
                <ListItemIcon>
                  <img src="/assets/marker-purple.png" alt="" height="28" />
                </ListItemIcon>
                <ListItemText>Em prospecção</ListItemText>
                <ListItemSecondaryAction>
                  <Checkbox
                    checked={affiliatesLayerFilter.indexOf("PROSPECÇÃO") >= 0}
                    onChange={(e) =>
                      dispatch(toggleAffiliatesLayerFilter("PROSPECÇÃO"))
                    }
                  />
                </ListItemSecondaryAction>
              </ListItemButton>
            </List>
          </Collapse>
          <RegionsFilter />
          <ListItem divider>
            <ListItemText>Cobertura</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SwitchRed
                checked={showCoverageLayer}
                onChange={(e, checked) =>
                  dispatch(setShowCoverageLayer(checked))
                }
              />
            </ListItemIcon>
            <ListItemText>Alcance (Potência)</ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <SwitchRed
                checked={showCoverageSpotLayer}
                onChange={(e, checked) =>
                  dispatch(setShowCoverageSpotLayer(checked))
                }
              />
            </ListItemIcon>
            <ListItemText>Mancha</ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <SwitchRed
                checked={showCoverageContourLayer}
                onChange={(e, checked) =>
                  dispatch(setShowCoverageContourLayer(checked))
                }
              />
            </ListItemIcon>
            <ListItemText>Contorno</ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <SwitchRed
                checked={showQueryTool}
                onChange={(e, checked) => dispatch(toggleQueryTool(checked))}
              />
            </ListItemIcon>
            <ListItemText>Ferramenta de alcance</ListItemText>
            <ListItemSecondaryAction />
          </ListItem>
        </List>
        <MapLayersView />
      </Popover>
    </>
  );
};

export default React.memo(MapViews);

import React from "react";
import { useRefresh } from "react-admin";
import { useRecordContext } from "react-admin";
import { saveAs } from "file-saver";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import getLocation from "../utils/location";
import { fetchURLFile } from "../provider/data-provider";
import SubmitButton from "../styled/submit-button";

const downloadFile = async (e, source, record, callback) => {
  e.stopPropagation();

  getLocation(async (position, error) => {
    if (position) {
      const data = await fetchURLFile(record.id, source, {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      const { result } = data;

      if (result) {
        if (Array.isArray(result))
          result.forEach((item, i) => {
            saveAs(item.url, item.url.substr(item.url.lastIndexOf("/") + 1));
          });
        else
          saveAs(
            result.url,
            result.url.substr(result.url.lastIndexOf("/") + 1)
          );
      } else alert("Não foi possível fazer download do arquivo.");

      callback(true);
    }

    if (error && error.code) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          alert("Permissão não concedida");
          break;
        case error.POSITION_UNAVAILABLE:
          alert("Localização não é permitida");
          break;
        case error.TIMEOUT:
          alert("Timeout");
          break;
        default:
          alert("Algum erro inesperado");
          break;
      }
    }
  });
};

const ButtonDownloadField = ({ source }) => {
  const record = useRecordContext();
  const refresh = useRefresh();

  if (record)
    return (
      <SubmitButton
        full={false}
        onClick={(e) => {
          downloadFile(e, source, record, () => {
            refresh();
          });
        }}
      >
        <DownloadRoundedIcon />
      </SubmitButton>
    );
  else return null;
};

export default ButtonDownloadField;

import { useCallback, useState } from "react";
import { useInput } from "react-admin";
import { Autocomplete, TextField, Chip } from "@mui/material";

function CustomAutocomplete({
  data,
  multiple = true,
  optionText = "name",
  variant = "filled",
  ...props
}) {
  const [values, setValues] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loadInitialValue, setLoadInitialValue] = useState(false);

  const { onDelete, onChange, onBlur, ...rest } = props;
  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
    isRequired,
  } = useInput({ onChange, onBlur, ...props });

  if (!loadInitialValue && field.value) {
    const selected = data.filter((item) =>
      multiple ? field.value.indexOf(item.id) > -1 : item.id === field.value
    );
    const content = selected.length === 1 && !multiple ? selected[0] : selected;
    setValues(content);
    setLoadInitialValue(true);
  }

  const handleChange = useCallback(
    (_, newValue) => {
      if (!newValue) {
        setValues(null);
        field.onChange(null);
        return;
      }

      setValues(newValue);
      const content = multiple ? newValue.map((item) => item.id) : newValue.id;
      field.onChange(content);
    },
    [multiple, field]
  );

  const handleBlur = () => {
    field.onBlur(values);
  };

  return (
    <Autocomplete
      {...field}
      options={data}
      multiple={multiple}
      value={values}
      filterSelectedOptions
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onBlur={handleBlur}
      getOptionLabel={(option) => {
        if (option && option.name) {
          return option.name;
        }
        return "";
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            label={option[optionText.split(".")]}
            sx={{
              "& .MuiChip-deleteIcon": {
                display: "none",
              },
            }}
          />
        ))
      }
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant={variant}
            error={(isTouched || isSubmitted) && invalid}
            helperText={
              (isTouched || isSubmitted) && invalid ? error.message : ""
            }
            label={props.label}
            required={isRequired}
          />
        );
      }}
      {...rest}
    />
  );
}

export default CustomAutocomplete;

import React from "react";
import {
  Show,
  TextField,
  SimpleShowLayout,
  DateField,
  TopToolbar,
  EditButton,
  DeleteWithConfirmButton,
  FileField,
} from "react-admin";
import { ContentPageSX, SimpleShowLayoutSX } from "../../../styled/custom";

const CustomerNoteShowActions = () => (
  <TopToolbar>
    <EditButton />
    <DeleteWithConfirmButton />
  </TopToolbar>
);

const CustomerNoteShow = (props) => (
  <Show
    title="Nota do Afiliado"
    sx={ContentPageSX}
    actions={<CustomerNoteShowActions />}
  >
    <SimpleShowLayout sx={SimpleShowLayoutSX}>
      <TextField source="id" />
      <TextField source="customer.name" label="Afiliada" sortable={false} />
      <TextField source="title" label="Título" sortable={false} />
      <TextField source="message" label="Mensagem" sortable={false} />
      <DateField
        source="created_at"
        locales="pt-BR"
        label="Criado em"
        sortable={false}
        showTime
      />
      <FileField source="files" src="src" title="title" label="Anexos" />
    </SimpleShowLayout>
  </Show>
);

export default CustomerNoteShow;

import {
  Marker,
  HeatmapLayer,
  GoogleMarkerClusterer,
} from "@react-google-maps/api";
import React, { useCallback, useEffect, useState } from "react";
import { useNotify } from "react-admin";
import axios from "../geo-api";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Popover from "@mui/material/Popover";
import {
  setRadiosSelectList,
  setRadiosSelectOpen,
  setRadiosSelectPosition,
  setRadiosSelected,
  setShowRadiosSelectedProfile,
} from "../store";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";

const fetchHeatRadiosLayer = async () => axios.get("/geo/layers/radios");

const RadiosLayer = () => {
  const notify = useNotify();
  const [radios, setRadios] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await fetchHeatRadiosLayer();
        setRadios(response.data.result);
        setIsLoaded(true);
      } catch (e) {
        notify("Não foi possível carregar a camada mapa de calor!", {
          type: "error",
        });
        console.log(e);
      }
    };
    if (!isLoaded) loadData();
  }, []);

  const handleRadiosClick = useCallback(
    (e: google.maps.MapMouseEvent) => {
      console.log(e);
      // Função para obter marcadores dentro de uma determinada área
      const radiosInArea = (center, radius) => {
        const radiosFound = [];

        for (var i = 0; i < radios.length; i++) {
          const radio = radios[i];
          const radioPosition = new window.google.maps.LatLng(
            radio.latitude,
            radio.longitude
          );
          const distance =
            window.google.maps.geometry.spherical.computeDistanceBetween(
              radioPosition,
              center
            );

          if (distance <= radius) {
            radiosFound.push(radio);
          }
        }

        return radiosFound;
      };

      const _radios = radiosInArea(e.latLng, 5);
      if (_radios.length > 1) {
        dispatch(setRadiosSelectList(_radios));
        dispatch(
          setRadiosSelectPosition({
            x: e.domEvent.clientX,
            y: e.domEvent.clientY,
          })
        );
        dispatch(setRadiosSelectOpen(true));
      } else if (_radios.length === 1) {
        dispatch(setRadiosSelected(_radios[0]));
        dispatch(setRadiosSelectOpen(false));
        dispatch(setShowRadiosSelectedProfile(true));
      }
    },
    [radios]
  );

  if (!isLoaded) return null;

  return (
    <GoogleMarkerClusterer>
      {(clusterer) => {
        return radios.map((item) => (
          <Marker
            key={item.id}
            icon="/assets/marker-radio.png"
            position={{ lat: item.latitude, lng: item.longitude }}
            clusterer={clusterer}
            onClick={handleRadiosClick}
          />
        ));
      }}
    </GoogleMarkerClusterer>
  );
};

export default RadiosLayer;

const fetchRadioRankingLayer = async () =>
  axios.get("/geo/layers/radios_ranking");

export const RadiosRankingLayer = () => {
  const notify = useNotify();
  const [points, setPoints] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await fetchRadioRankingLayer();
        const data = response.data.result.map((i) => ({
          location: new window.google.maps.LatLng(i.latitude, i.longitude),
          weight: i.numero_visitas,
        }));
        setPoints(data);
        setIsLoaded(true);
      } catch (e) {
        notify("Não foi possível carregar a camada mapa de calor do ranking!", {
          type: "error",
        });
        console.log(e);
      }
    };
    if (!isLoaded) loadData();
  }, []);

  if (isLoaded)
    return (
      <HeatmapLayer
        data={points}
        options={{
          radius: 20,
          dissipating: true,
          gradient: [
            'rgba(29, 33, 68, 0)',
            'rgba(29, 33, 68, 1)',
            'rgba(29, 33, 68, 1)',
            'rgba(32, 38, 93, 1)',
            'rgba(32, 38, 93, 1)',
            'rgba(56, 77, 130, 1)',
            'rgba(56, 77, 130, 1)',
            'rgba(77, 153, 148, 1)',
            'rgba(77, 153, 148, 1)',
            'rgba(138, 192, 63, 1)',
            'rgba(138, 192, 63, 1)',
            'rgba(205, 213, 105, 1)',
            'rgba(205, 213, 105, 1)'
          ]
        }}
      />
    );
  return null;
};

export const RadioSelectorPopup = () => {
  const { open, position, items } = useSelector(
    (state) => ({
      open: state.radiosSelectOpen,
      position: state.radiosSelectPosition,
      items: state.radiosSelectList,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  return (
    <Popover
      id={"radio-selector"}
      open={open}
      anchorEl={{
        nodeType: 1,
        getBoundingClientRect: () =>
          new DOMRect(position.x, position.y, 100, 1),
      }}
      onClose={() => {
        dispatch(setRadiosSelectOpen(false));
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <List>
        {items.map((radio) => (
          <ListItemButton
            key={radio.id}
            onClick={() => {
              dispatch(setRadiosSelected(radio));
              dispatch(setRadiosSelectOpen(false));
              dispatch(setShowRadiosSelectedProfile(true));
            }}
          >
            {radio.nome}
          </ListItemButton>
        ))}
      </List>
    </Popover>
  );
};

import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = typesSpot =>
{
  const data = typesSpot.map(typeSpot => (
  {
    id: typeSpot.id,
    nome: typeSpot.name,
    tempo: typeSpot.time,
    criado_em: typeSpot.created_at_format
  }));
  const csv = convertToCSV(
  {
    data,
    fields: ['id', 'nome', 'tempo', 'criado_em']
  });
  downloadCSV(csv, 'types-spot');
}

export default exporter;

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { GeoDrawer, GeoDrawerHeader } from "./geo-side-menu";
import Chip from "@mui/material/Chip";
import {
  Datagrid,
  Link,
  useCreatePath,
  useGetList,
  useGetOne,
} from "react-admin";
import {
  Container,
  Typography,
  Box,
  Paper,
  LinearProgress,
} from "@mui/material";
import { computeCoverageByCustomerId } from "../geo/geo-api";
import { GeoCoverageStats } from "./geo-query-tool-panel";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {
  setRadioName,
  setRadioPlay,
  setRadioStreamUrl,
  toggleCustomerProfile,
} from "./store";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import LanguageIcon from "@mui/icons-material/Language";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ValueWithLabel from "../../ui/value-with-label";

import axios from "./geo-api";
import { formatNumber } from "./geo-utils";

export const getRealAudioStream = async (url) => {
  try {
    const response = await axios.get(
      `/geo/stream?url=${encodeURIComponent(url)}`
    );
    return response.data[0];
  } catch (err) {}
  return url;
};

export const PaperWrapper = ({ title, children, padding }) => {
  return (
    <Paper elevation={3} sx={{ p: padding, mt: 1 }}>
      {title && (
        <Typography variant="body1" gutterBottom>
          {title}
        </Typography>
      )}
      {children}
    </Paper>
  );
};

PaperWrapper.defaultProps = {
  padding: 2,
};

const CustomerCoverage = ({ id }) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        setIsLoading(true);
        const res = await computeCoverageByCustomerId(id);
        setData(res.data);
      } catch (err) {
        setData(null);
      } finally {
        setIsLoading(false);
      }
    };
    if (id) fetchData(id);
  }, [id]);
  if (isLoading) {
    return (
      <PaperWrapper title="Cobertura">
        <LinearProgress color="secondary" />
      </PaperWrapper>
    );
  }

  return (
    <PaperWrapper>
      <Typography variant="body1" gutterBottom>
        <b>COBERTURA</b>
      </Typography>
      {data ? (
        <GeoCoverageStats stats={data} />
      ) : (
        <Typography>Erro ao obter informações de cobertura!</Typography>
      )}
    </PaperWrapper>
  );
};

const CustomerBasic = ({ loading, customer }) => {
  const createPath = useCreatePath();
  const dispatch = useDispatch();

  const playRadio = async (customer) => {
    dispatch(setRadioName(customer.profile.name));
    const url = await getRealAudioStream(customer.profile.stream);
    dispatch(setRadioStreamUrl(url));
    dispatch(setRadioPlay(true));
  };

  if (loading) {
    return (
      <PaperWrapper title="Carregando informações da radio...">
        <LinearProgress color="secondary" />
      </PaperWrapper>
    );
  }
  if (customer) {
    return (
      <>
        <Paper elevation={3} sx={{ p: 2, mt: 0 }}>
          <Typography>
            <Link
              to={createPath({
                resource: "customer",
                type: "show",
                id: customer.id,
              })}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ display: "flex" }}
            >
              <b>{customer.profile.name}</b>
              <OpenInNewIcon />
            </Link>
          </Typography>
          <Button
            onClick={() => {
              playRadio(customer);
            }}
            variant="contained"
            startIcon={<PlayCircleOutlineIcon />}
          >
            Ouvir radio
          </Button>

          <ValueWithLabel
            label="Modelo contratual:"
            value={customer.profile.contract_model}
            divider
            horizontal
          />

          <ValueWithLabel
            label="Email:"
            value={<a href={`mailto:${customer.email}`}>{customer.email}</a>}
            divider
            horizontal
          />
          <ValueWithLabel
            label="Situação:"
            value={customer.active ? "Ativo" : "Inativo"}
            divider
            horizontal
          />
          <ValueWithLabel
            label="Plano:"
            value={customer.profile.type_plan.name}
            divider
            horizontal
          />
          <ValueWithLabel
            label="Região:"
            value={customer.profile.region.name}
            divider
            horizontal
          />
          <ValueWithLabel
            label="Número de Registro:"
            value={customer.profile.register_number}
            divider
            horizontal
          />
          <ValueWithLabel
            label="Cidade:"
            value={customer.address.city.name}
            divider
            horizontal
          />
          <ValueWithLabel
            label="Estado:"
            value={customer.address.city.state.name}
            divider
            horizontal
          />
          <ValueWithLabel
            label="CEP:"
            value={customer.address.zip_code}
            divider
            horizontal
          />
          <ValueWithLabel
            label="Rua:"
            value={customer.address.street}
            divider
            horizontal
          />
          <ValueWithLabel
            label="Número:"
            value={customer.address.number}
            divider
            horizontal
          />
          <ValueWithLabel
            label="Complemento:"
            value={customer.address.complement || "Nenhum"}
            divider
            horizontal
          />
          <ValueWithLabel
            label="Bairro:"
            value={customer.address.neighborhood}
            divider
            horizontal
          />
          <ValueWithLabel
            label="Estilos:"
            value={customer.styles.map((s) => (
              <Chip key={s} label={s.name} sx={{ marginRight: 0.5 }} />
            ))}
          />
        </Paper>

        <Paper elevation={3} sx={{ p: 2, mt: 1 }}>
          <Typography>
            <b>REDES SOCIAIS</b>
          </Typography>
          <ValueWithLabel
            label={<LanguageIcon />}
            value={<a href={customer.profile.site}>{customer.profile.site}</a>}
            horizontal
            divider
          />
          <ValueWithLabel
            label={<TwitterIcon />}
            value={customer.profile.twitter}
            horizontal
            divider
          />
          <ValueWithLabel
            label={<FacebookIcon />}
            value={customer.profile.facebook}
            horizontal
            divider
          />
          <ValueWithLabel
            label={<InstagramIcon />}
            value={customer.profile.instagram}
            horizontal
            divider
          />
          <ValueWithLabel
            label={<LinkedInIcon />}
            value={customer.profile.linkedin}
            horizontal
            divider
          />
        </Paper>
      </>
    );
  }
  return (
    <PaperWrapper>
      <Typography variant="body1">Sem informações para exibir</Typography>
    </PaperWrapper>
  );
};

const CustomerTransmitters = ({ loading, transmitters }) => {
  const createPath = useCreatePath();
  if (loading) {
    return (
      <PaperWrapper title="Carregando informações dos transmissores...">
        <LinearProgress color="secondary" />
      </PaperWrapper>
    );
  }
  if (transmitters) {
    return (
      <PaperWrapper>
        <Typography variant="body1" gutterBottom>
          <b>TRANSMISSORES</b>
        </Typography>
        <List dense>
          {transmitters.map((t) => {
            return (
              <ListItemButton key={t.id} divider>
                <ListItemText>
                  <ValueWithLabel label="Nome" value={t.name} horizontal />
                  <ValueWithLabel label="Tipo" value={t.type} horizontal />
                  <ValueWithLabel label="Alcance (m)" value={formatNumber(t.radius)} horizontal />
                  <ValueWithLabel label="Potência ERP (kW)" value={formatNumber(t.power)} horizontal />
                </ListItemText>
                <ListItemSecondaryAction>
                  <Link
                    to={createPath({
                      resource: "customer_transmitter",
                      type: "edit",
                      id: t.id,
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ display: "flex" }}
                  >
                    <OpenInNewIcon />
                  </Link>
                </ListItemSecondaryAction>
              </ListItemButton>
            );
          })}
        </List>
      </PaperWrapper>
    );
  }
  return (
    <PaperWrapper>
      <Typography variant="body1">Nenhum transmissor encontrado</Typography>
    </PaperWrapper>
  );
};

const CustomerNotes = ({ loading, notes }) => {
  const createPath = useCreatePath();
  if (loading) {
    return (
      <PaperWrapper title="Carregando informações das notas...">
        <LinearProgress color="secondary" />
      </PaperWrapper>
    );
  }
  if (notes) {
    return (
      <PaperWrapper>
        <Typography variant="body1" gutterBottom>
          <b>ÚLTIMAS NOTAS</b>
        </Typography>
        <List dense>
          {notes.map((t) => {
            return (
              <ListItemButton key={t.id} divider>
                <ListItemText>
                  <ValueWithLabel label="Título" value={t.title} horizontal />
                </ListItemText>
                <ListItemSecondaryAction>
                  <Link
                    to={createPath({
                      resource: "customer_note",
                      type: "edit",
                      id: t.id,
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ display: "flex" }}
                  >
                    <OpenInNewIcon />
                  </Link>
                </ListItemSecondaryAction>
              </ListItemButton>
            );
          })}
        </List>
      </PaperWrapper>
    );
  }
  return (
    <PaperWrapper>
      <Typography variant="body1">Nenhuma nota encontrada.</Typography>
    </PaperWrapper>
  );
};

const CustomerStaff = ({ loading, staff }) => {
  if (loading) {
    return (
      <PaperWrapper title="Carregando informações dos staff...">
        <LinearProgress color="secondary" />
      </PaperWrapper>
    );
  }
  if (staff) {
    return (
      <PaperWrapper>
        <Typography variant="body1" gutterBottom>
          <b>QUEM FAZ A RADIO</b>
        </Typography>
        <List dense>
          {staff.map((t) => {
            return (
              <ListItemButton key={t.id} divider>
                <ListItemText>
                  <ValueWithLabel label="Cargo" value={t.position} horizontal />
                  <ValueWithLabel label="Nome" value={t.name} horizontal />
                  <ValueWithLabel label="Telefone" value={t.phone} horizontal />
                  <ValueWithLabel label="Email" value={t.email} horizontal />
                </ListItemText>
              </ListItemButton>
            );
          })}
        </List>
      </PaperWrapper>
    );
  }
  return (
    <PaperWrapper>
      <Typography variant="body1">Nenhum membro do staff encontrado</Typography>
    </PaperWrapper>
  );
};

const GeoCustomerProfile = () => {
  const dispatch = useDispatch();
  const { showCustomerProfile, customerId } = useSelector(
    (state) => ({
      showCustomerProfile: state.showCustomerProfile,
      customerId: state.customerId,
    }),
    shallowEqual
  );

  const { data: customerData, isLoading: isLoadingCustomerData } = useGetOne(
    "customer",
    { id: customerId }
  );

  const { data: staffData, isLoading: isLoadingStaffData } = useGetList(
    "customer_staff_member",
    {
      pagination: { page: 1, perPage: 1000000 },
      sort: { field: "id", order: "ASC" },
      filter: { customer_id: customerId },
    }
  );

  const { data: transmitterData, isLoading: isLoadingTransmitterData } =
    useGetList("customer_transmitter", {
      pagination: { page: 1, perPage: 1000000 },
      sort: { field: "id", order: "ASC" },
      filter: { customer_id: customerId },
    });

  const { data: notesData, isLoading: isLoadingNotesData } = useGetList(
    "customer_note",
    {
      pagination: { page: 1, perPage: 3 },
      sort: { field: "created_at", order: "DESC" },
      filter: { customer_id: customerId },
    }
  );

  return (
    <GeoDrawer open={showCustomerProfile}>
      <GeoDrawerHeader>
        <Box textAlign="right" sx={{ color: "white" }}>
          <IconButton
            onClick={() => dispatch(toggleCustomerProfile(false))}
            color="inherit"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant="h6">Perfil do cliente</Typography>

        <CustomerBasic
          customer={customerData}
          loading={isLoadingCustomerData}
        />
        <CustomerCoverage id={customerId} />
        <CustomerTransmitters
          transmitters={transmitterData}
          loading={isLoadingTransmitterData}
        />
        <CustomerNotes notes={notesData} loading={isLoadingNotesData} />
        <CustomerStaff staff={staffData} loading={isLoadingStaffData} />
      </GeoDrawerHeader>
    </GeoDrawer>
  );
};

const GeoCustomerProfileWrapper = () => {
  const show = useSelector((state) => state.showCustomerProfile);
  if (show) return <GeoCustomerProfile />;
  return null;
};

export default GeoCustomerProfileWrapper;

import React from "react";
import { TopToolbar, CreateButton, ExportButton } from "react-admin";

const CustomListExportCreateToolbar = () => (
  <TopToolbar>
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export default CustomListExportCreateToolbar;

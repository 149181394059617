import React from "react";
import Switch from "@mui/material/Switch";
import { styled, alpha } from "@mui/material";

const SwitchRed = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#db1d3f",
    "&:hover": {
      backgroundColor: alpha("#db1d3f", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#db1d3f",
  },
}));

export default SwitchRed;

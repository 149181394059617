import { Circle, InfoWindow, KmlLayer } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { Link, useCreatePath, useGetList } from "react-admin";
import Typography from "@mui/material/Typography";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ValueWithLabel from "../../../ui/value-with-label";
import { shallowEqual, useSelector } from "react-redux";
import { formatNumber } from "../geo-utils";

const TransmitterMarker = ({ transmitter }) => {
  const createPath = useCreatePath();
  const [isOpen, setIsOpen] = useState(false);

  if (transmitter.latitude && transmitter.longitude) {
    return (
      <>
        <Circle
          title={transmitter.name}
          center={{ lat: transmitter.latitude, lng: transmitter.longitude }}
          radius={transmitter.radius}
          onClick={() => {
            setIsOpen(true);
          }}
          options={{
            strokeWeight: 1,
          }}
          zIndex={1}
        />
        {isOpen && (
          <InfoWindow
            position={{ lat: transmitter.latitude, lng: transmitter.longitude }}
            onCloseClick={() => setIsOpen(false)}
          >
            <div>
              <ValueWithLabel
                value={transmitter.name}
                label="Nome: "
                divider
                horizontal
              />
              <ValueWithLabel
                value={transmitter.type}
                label="Tipo: "
                divider
                horizontal
              />
              <ValueWithLabel
                value={formatNumber(transmitter.radius)}
                label="Alcance (m): "
                divider
                horizontal
              />
              <ValueWithLabel
                value={formatNumber(transmitter.power)}
                label="Potência ERP (kW): "
                divider
                horizontal
              />
              <Link
                to={createPath({
                  resource: "customer_transmitter",
                  type: "edit",
                  id: transmitter.id,
                })}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ display: "flex" }}
              >
                <OpenInNewIcon />
              </Link>
            </div>
          </InfoWindow>
        )}
      </>
    );
  }
  return null;
};

const CoverageLayer = () => {
  const { data, isLoading } = useGetList("customer_transmitter", {
    pagination: { page: 1, perPage: 1000000 },
    sort: { field: "id", order: "ASC" },
    filter: {
      activated: true,
    },
  });
  const [transmitters, setTransmitters] = useState([]);
  const { showContour, showSpot, showCoverage, regionsFilter } = useSelector(
    (state) => ({
      showContour: state.showCoverageContourLayer,
      showSpot: state.showCoverageSpotLayer,
      showCoverage: state.showCoverageLayer,
      regionsFilter: state.regionsFilter,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (data) {
      setTransmitters(data);
    }
  }, [data]);

  if (isLoading) return null;

  return (
    <>
      {transmitters
        .filter((c) => c.latitude && c.longitude)
        .filter((c) => regionsFilter.indexOf(c.customer.region_id) >= 0)
        .map((item) => (
          <React.Fragment key={item.id}>
            {showContour && item.contour_url && (
              <KmlLayer url={item.contour_url} />
            )}
            {showSpot && item.coverage_url && (
              <KmlLayer url={item.coverage_url} />
            )}
            {showCoverage && (
              <TransmitterMarker key={item.id} transmitter={item} />
            )}
          </React.Fragment>
        ))}
    </>
  );
};

export default CoverageLayer;

import React from 'react';
import { useRecordContext } from "react-admin";

const CustomerMediaShowTitle = () =>
{
  const record = useRecordContext();
  return record ? (
    <span>Cesta de Produtos: { record.name }</span>
  ) : null
}

export default CustomerMediaShowTitle;

import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = customers =>
{
  const data = customers.map(customer => (
  {
    id: customer.id,
    nome: customer.profile.name,
    cnpj: customer.profile.register_number,
    plano: customer.profile.type_plan.name,
    regiao: customer.profile.region.name,
    cidade: customer.address.city.name,
    cep: customer.address.zip_code,
    rua: customer.address.street,
    numero: customer.address.number,
    complemento: customer.address.complement,
    bairro: customer.address.neighborhood,
    programas: customer.programs_name,
    categorias: customer.categories_media_name,
    criado_em: customer.created_at_format
  }));
  const csv = convertToCSV(
  {
    data,
    fields: ['id', 'nome', 'cnpj', 'plano', 'regiao', 'cidade', 'cep', 'rua',
             'numero', 'complemento', 'bairro', 'programas', 'categorias', 'criado_em']
  });
  downloadCSV(csv, 'customers');
}

export default exporter;

import React from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import MapViews from "./map-views";
import { useAuthState } from "react-admin";
import { useState } from "react";
import { applyLikeFilter } from "../../utils/text";
import { useDispatch, useSelector } from "react-redux";
import {
  setPublicRadioFilter,
  setSelectedRadio,
  toggleItasatNetwork,
} from "./store";

const SearchBox = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: 50,
  display: "inline-flex",
  flexDirection: "row",
  position: "relative",
  width: "40vw",
  padding: theme.spacing(0, 1, 0, 2),
}));

const SearchIconBox = styled("div")(({ theme }) => ({
  color: theme.palette.primary.main,
  // position: "absolute",
  // right: theme.spacing(1),
  display: "flex",
  height: "100%",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  flex: 1,
  "& .MuiInputBase-input": {
    padding: theme.spacing(0, 1, 0, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(1)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    // [theme.breakpoints.up('md')]: {
    //   width: '20ch',
    // },
  },
}));

export const GeoSearchBox = () => {
  const dispatch = useDispatch();
  const { publicRadios, isAdmin } = useSelector((state) => ({
    publicRadios: state.publicRadios,
    isAdmin: state.isAdmin,
  }));
  const [search, setSearch] = useState("");

  const handleSearch = () => {
    if (!isAdmin) {
      // Busca apenas na radio
      const items = publicRadios.filter((item) =>
        applyLikeFilter(item.name, search)
      );
      if (items.length === 1) {
        dispatch(setSelectedRadio(items[0].id));
      }
      dispatch(setPublicRadioFilter(search));
      dispatch(toggleItasatNetwork(true));
    }
  };

  return (
    <SearchBox>
      {isAdmin && <MapViews key="mapview" />}
      <StyledInputBase
        placeholder="Busque no mapa…"
        inputProps={{ "aria-label": "search" }}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <SearchIconBox onClick={handleSearch}>
        <SearchIcon />
      </SearchIconBox>
    </SearchBox>
  );
};

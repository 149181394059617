const getLocation = (callback) => {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        callback(position, null);
      },
      (error) => {
        callback(null, error);
      }
    );
  } else {
    callback(null, "Localização não suportada por este browser");
  }
};

export default getLocation;

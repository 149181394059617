import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = typesScheme =>
{
  const data = typesScheme.map(typeScheme => (
  {
    id: typeScheme.id,
    nome: typeScheme.name,
    criado_em: typeScheme.created_at_format
  }));
  const csv = convertToCSV(
  {
    data,
    fields: ['id', 'nome', 'criado_em']
  });
  downloadCSV(csv, 'types-scheme');
}

export default exporter;

import { Circle, InfoWindow, Marker } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedRadio } from "../store";

const radiusScales = [0, 1000, 2500, 5000, 10000, 20000];

const AnimatedCircle = ({ id, lat, lng }) => {
  const [radius, setRadius] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setRadius((r) => (r + 1) % radiusScales.length);
    }, 400);
    return () => {
      clearInterval(timer);
    };
  }, []);

  let circles = [];
  for (let i = 0; i < radius; i++) {
    circles.push(
      <Circle
        key={`${id}-${i}`}
        center={{ lat: lat, lng: lng }}
        radius={radiusScales[i]}
        options={{
          strokeColor: "#FF0000",
          strokeOpacity: (radiusScales.length - i) / radiusScales.length - 0.4,
          strokeWeight: 1,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
        }}
      />
    );
  }
  return circles;
};

const RadioMarker = ({ radio }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  if (radio.latitude && radio.longitude) {
    return (
      <>
        <Marker
          title={radio.name}
          position={{ lat: radio.latitude, lng: radio.longitude }}
          onClick={() => {
            setIsOpen(true);
            dispatch(setSelectedRadio(radio.id));
          }}
          zIndex={1}
        >
          {isOpen && (
            <InfoWindow
              position={{ lat: radio.latitude, lng: radio.longitude }}
              onCloseClick={() => setIsOpen(false)}
            >
              <div>
                <Typography>{radio.name}</Typography>
                <Typography>{radio.population} hab.</Typography>
              </div>
            </InfoWindow>
          )}
        </Marker>
        {/* <AnimatedCircle
          id={radio.id}
          lat={radio.latitude}
          lng={radio.longitude}
        /> */}
      </>
    );
  }
  return null;
};

const PublicRadiosLayer = () => {
  const { publicRadios, selectedRadio } = useSelector((state) => ({
    publicRadios: state.publicRadios,
    selectedRadio: state.selectedRadio,
  }));

  if (publicRadios.length > 0) {
    return publicRadios
      .filter((radio) => {
        if (!selectedRadio) return true;
        if (selectedRadio === radio.id) return true;
        return false;
      })
      .map((radio) => <RadioMarker key={radio.id} radio={radio} />);
  }
  return null;
};

export default PublicRadiosLayer;

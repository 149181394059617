import React from "react";
import {
  Show,
  TextField,
  SimpleShowLayout,
  DateField,
  TopToolbar,
  EditButton,
  DeleteWithConfirmButton,
  BooleanInput,
  BooleanField,
} from "react-admin";
import { ContentPageSX, SimpleShowLayoutSX } from "../../../styled/custom";

const CustomerTransmitterShowActions = () => (
  <TopToolbar>
    <EditButton />
    <DeleteWithConfirmButton />
  </TopToolbar>
);

const CustomerTransmitterShow = (props) => (
  <Show
    title="Transmissor"
    sx={ContentPageSX}
    actions={<CustomerTransmitterShowActions />}
  >
    <SimpleShowLayout sx={SimpleShowLayoutSX}>
      <TextField source="id" />
      <TextField source="customer.name" label="Afiliada" sortable={false} />
      <TextField source="name" label="Nome" sortable={false} />
      <TextField source="type" label="Tipo" sortable={false} />
      <TextField source="latitude" label="Latitude" sortable={false} />
      <TextField source="longitude" label="Longitude" sortable={false} />
      <TextField source="radius" label="Raio de alcance (metros)" sortable={false} />
      <TextField source="power" label="Potência (kW)" sortable={false} />
      <BooleanField source="active" label="Ativo" />

      <DateField
        source="created_at"
        locales="pt-BR"
        label="Criado em"
        sortable={false}
        showTime
      />
    </SimpleShowLayout>
  </Show>
);

export default CustomerTransmitterShow;

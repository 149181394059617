import React from 'react';
import { List, Datagrid, TextField, DateField, EditButton, BooleanField } from 'react-admin';
import { DatagridSX, ListSX } from '../../../styled/custom';
import CustomFilterClient from '../../../filter/custom-filter-client';
import CustomListDefaultToolbar from '../../../ui/custom-list-default-toolbar';
import ClientExporter from '../../../exporter/client-exporter';

const ClientList = (props) => (
  <List
    title="Clientes"
    sort={{ field: 'id', order: 'desc' }}
    filters={ CustomFilterClient }
    exporter={ ClientExporter }
    actions={ <CustomListDefaultToolbar /> }
    sx={ListSX}>
    <Datagrid
      rowClick="show"
      bulkActionButtons={false}
      sx={DatagridSX}>
      <TextField source="id" sortable={false} />
      <TextField source="name" label="Nome" sortable={false} />
      <TextField source="contract_validity_format" label="Validade do Contrato" sortable={false} />
      <TextField source="number_inserts" label="Inserções Diárias" sortable={false} />
      <BooleanField source="active" label="Ativo" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
      <EditButton />
    </Datagrid>
  </List>
);

export default ClientList;

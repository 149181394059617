import blue from '@mui/material/colors/blue';

const rowCustomerMediaStyle = () => (record, index, defaultStyle = {}) =>
{
  let style = defaultStyle;
  if (record.has_download === false)
    return {
      ...style,
      backgroundColor: blue[50]
    };

  return style;
};

export default rowCustomerMediaStyle;

import { AppBar, UserMenu, Logout } from "react-admin";
import { Typography, Badge } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import { AppBarSX, AppBarRedSX, TypographySX } from "../styled/custom";
import logo from "../assets/logo-red.png";
import logoWhite from "../assets/logo-white.png";
import useNotification from "../hook/useNotification";
import { useEffect } from "react";

const CustomUserMenu = () => {
  const [total, actions] = useNotification();

  useEffect(() => {
    actions.fetch();
  }, []);

  return (
    <UserMenu icon={
      <Badge badgeContent={total} color="error" variant="dot">
        <PersonIcon />
      </Badge>}>
      <Logout />
    </UserMenu>
  )
}

const CustomAppBar = (props) =>
{
  const { variant, ...otherProps } = props;
  return (
    <AppBar {...otherProps} sx={(variant === "white") ? AppBarSX : AppBarRedSX} userMenu={<CustomUserMenu />}>
      <img src={(variant === "white") ? logo : logoWhite} alt="Itatiaia" width={100} />
      <Typography id="react-admin-title" sx={TypographySX} />
    </AppBar>
  );
};

CustomAppBar.defaultProps = {
  variant: "white",
}
export default CustomAppBar;

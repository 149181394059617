import { Data } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useNotify } from "react-admin";
import { useDispatch } from "react-redux";
import { createFeatureInformation, setFeatureInformation, setMapLayerLoading, toggleFeatureInformation } from "../store";

const GeojsonLayer = ({ layer }) => {
  const [data, setData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();
  const notify = useNotify();

  useEffect(() => {
    console.log(isLoaded, data, layer);
    if (isLoaded || !data || layer.isLoading) return;

    const loadGeojson = async () => {
      console.log("Carregando features...");
      try {
        dispatch(setMapLayerLoading({ id: layer.id, loading: true }));
        data.loadGeoJson(layer.url, null, (feats) => {
          dispatch(setMapLayerLoading({ id: layer.id, loading: false }));
          setIsLoaded(true);
        });

        data.setStyle((feature) => {
          return {
            fillColor: layer.fill_color || "#000000",
            fillOpacity: layer.fill_opacity || 1,
            strokeColor: layer.stroke_color || "#000000",
            strokeWeight: layer.stroke_width || 1,
            strokeOpacity: layer.stroke_opacity || 1,
          };
        });

      } catch (e) {
        notify("Não foi possível carregar a camada mapa de geojson!", {
          type: "error",
        });
        console.log(e);
      }
    };
    loadGeojson();
  }, [data]);

  return (
    <Data
      onLoad={(data) => {
        setData(data);
      }}
      onClick={(event) => {
        const data = {};
        event.feature.forEachProperty((value, property) => {
          data[property] = value;
        });
        dispatch(
          setFeatureInformation(
            createFeatureInformation(
              `Item ${layer.name}`,
              '',
              data
            )
          )
        );
        dispatch(toggleFeatureInformation(true));
      }}
     
    />
  );
};

export default GeojsonLayer;

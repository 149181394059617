import React from 'react';
import { List, Datagrid, TextField, DateField, EditButton, BooleanField } from 'react-admin';
import { DatagridSX, ListSX } from '../../../styled/custom';
import CustomFilterMedia from '../../../filter/custom-filter-media';
import CustomListDefaultToolbar from '../../../ui/custom-list-default-toolbar';
import MediaExporter from '../../../exporter/media-exporter';
import ControlAudio from '../../../ui/control-audio';
import LinkField from '../../../ui/link-field';

const MediaList = (props) => (
  <List
    title="Cesta de Produtos"
    sort={{ field: 'id', order: 'desc' }}
    filters={ CustomFilterMedia }
    exporter={ MediaExporter }
    actions={ <CustomListDefaultToolbar /> }
    sx={ListSX}>
    <Datagrid
      rowClick="show"
      bulkActionButtons={false}
      sx={DatagridSX}>
      <TextField source="id" sortable={false} />
      <TextField source="number_downloads" label="Downloads" sortable={false} />
      <TextField source="category_media.name" label="Categoria" sortable={false} />
      <TextField source="name" label="Nome" sortable={false} />
      <ControlAudio label="Player" />
      <LinkField source="url" label="Url" text="Ver" sortable={false} />
      <TextField source="type" label="Tipo" sortable={false} />
      <BooleanField source="active" label="Ativo" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
      <EditButton />
    </Datagrid>
  </List>
);

export default MediaList;

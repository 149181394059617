import React from 'react';
import { List, Datagrid, TextField, DateField, BooleanField } from 'react-admin';
import { DatagridSX, ListSX } from '../../../styled/custom';
import CustomFilterNotification from '../../../filter/custom-filter-notification';
import CustomListDefaultToolbar from '../../../ui/custom-list-default-toolbar';
import NotificationExporter from '../../../exporter/notification-exporter';

const NotificationList = (props) => (
  <List
    title="Notificações"
    sort={{ field: 'id', order: 'desc' }}
    filters={ CustomFilterNotification }
    exporter={ NotificationExporter }
    actions={ <CustomListDefaultToolbar /> }
    sx={ListSX}>
    <Datagrid
      rowClick="show"
      bulkActionButtons={false}
      sx={DatagridSX}>
      <TextField source="id" sortable={false} />
      <TextField source="customer_name" label="Afiliado" sortable={false} />
      <TextField source="title" label="Título" sortable={false} />
      <BooleanField source="read" label="Lida" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
    </Datagrid>
  </List>
);

export default NotificationList;

import React from "react";
import {
  BooleanInput,
  Create,
  ImageField,
  ImageInput,
  SimpleForm,
} from "react-admin";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomTextField from "../../../ui/custom-text-field";
import { ContentPageSX } from "../../../styled/custom";
import { filesSize, minLength } from "../../../utils/schemas";

const Schema = Yup.object({
  name: minLength(2, "Digite o nome"),
  position: minLength(2, "Digite o cargo"),
  radio: minLength(2, "Digite o nome da radio"),
  place: minLength(2, "Digite o local da radio"),
  message: minLength(2, "Digite o depoimento"),
  files: filesSize(5000000, "Selecione uma imagem com tamanho total de 5MB"),
});

const TestimonialCreate = (props) => {
  return (
    <Create title="Novo depoimento" sx={ContentPageSX}>
      <SimpleForm noValidate resolver={yupResolver(Schema)}>
        <CustomTextField name="name" label="Nome" fullWidth isRequired />
        <CustomTextField name="position" label="Cargo" fullWidth isRequired />
        <CustomTextField name="radio" label="Radio" fullWidth isRequired />
        <CustomTextField name="place" label="Local" fullWidth isRequired />
        <CustomTextField name="message" label="Mensagem" fullWidth isRequired />

        <ImageInput
          source="files"
          label="Foto"
          multiple={false}
          accept="image/jpeg"
        >
          <ImageField source="src" title="title" />
        </ImageInput>

        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Create>
  );
};

export default TestimonialCreate;

import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import PopulationHeatmapLayer from "./layers/population-heatmap-layer";
import CoverageLayer from "./layers/coverage-layer";
import CustomersLayer from "./layers/customers-layer";
import GeojsonLayer from "./layers/geojson-layer";
import RegionsLayer from "./layers/regions-layer";
import PibHeatmapLayer from "./layers/pib-heatmap-layer";
import RadiosLayer, { RadiosRankingLayer } from "./layers/radios";

const GeoLayers = () => {
  const {
    showPopulationLayer,
    showAffiliatesLayer,
    showCoverageLayer,
    showCoverageContourLayer,
    showCoverageSpotLayer,
    showRegionsLayer,
    showPibLayer,
    showRadiosnetLayer,
    showRadiosnetHeatmapLayer,
    mapLayers,
  } = useSelector(
    (state) => ({
      showPopulationLayer: state.showPopulationLayer,
      showAffiliatesLayer: state.showAffiliatesLayer,
      showCoverageLayer: state.showCoverageLayer,
      showCoverageContourLayer: state.showCoverageContourLayer,
      showCoverageSpotLayer: state.showCoverageSpotLayer,
      showRegionsLayer: state.showRegionsLayer,
      showPibLayer: state.showPibLayer,
      mapLayers: state.mapLayers,
      showRadiosnetLayer: state.showRadiosnetLayer,
      showRadiosnetHeatmapLayer: state.showRadiosnetHeatmapLayer
    }),
    shallowEqual
  );

  const showCoverage =
    showCoverageLayer || showCoverageContourLayer || showCoverageSpotLayer;

  return [
    showPopulationLayer ? (
      <PopulationHeatmapLayer key="cities-heatmap" />
    ) : null,
    showPibLayer ? <PibHeatmapLayer key="pib-heatmap" /> : null,
    showAffiliatesLayer ? <CustomersLayer key="customers" /> : null,
    showCoverage ? <CoverageLayer key="coverage" /> : null,
    showRegionsLayer ? <RegionsLayer key="regions" /> : null,
    showRadiosnetLayer ? <RadiosLayer key="radios" /> : null,
    showRadiosnetHeatmapLayer ? <RadiosRankingLayer key="radiosranking"/> : null,
    ...mapLayers
      .filter((layer) => layer.visible)
      .map((layer) => (
        <GeojsonLayer layer={layer} key={`$maplayer${layer.id}`} />
      )),
  ];
};

export default GeoLayers;

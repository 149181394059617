import React from "react";
import { Button, useNotify } from "react-admin";
import { useFormContext } from "react-hook-form";

const Geocoder = ({ street, number, neighborhood, city }) => {
  const form = useFormContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const notify = useNotify();
  const request = async () => {
    const address = `${street || ""} ${number || ""}, ${neighborhood || ""}, ${
      city || ""
    }, Minas Gerais`;
    console.log("Geocodificando:", address);
    const cleanAddress = address.replace(/\s+/g, "+");
    setIsLoading(true);
    try {
      const res = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=` +
          cleanAddress +
          "&key=AIzaSyC0nY-EpN0opsu3Rp6kSTeYoigycOe66VQ"
      );
      const json = await res.json();
      console.log(json);

      if (json.status === "OK") {
        const { results } = json;
        if (results.length > 0) {
          const location = results[0].geometry.location;
          const { lat, lng } = location;

          form.setValue("latitude", lat, { shouldDirty: true });
          form.setValue("longitude", lng, { shouldDirty: true });
        }
      } else {
        notify(
          "Erro ao obter informações de coordenadas para o endereço " + address
        );
      }
    } catch (err) {
      notify(
        "Erro ao obter informações de coordenadas para o endereço " + address
      );
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Button
      onClick={request}
      label="Localizar"
      disabled={isLoading}
      variant="contained"
    />
  );
};

export default Geocoder;

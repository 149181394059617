import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { GeoDrawer, GeoDrawerHeader } from "./geo-side-menu";
import Chip from "@mui/material/Chip";
import {
  Typography,
  Box,
  Paper,
  LinearProgress,
} from "@mui/material";
import {
  getRadioRankingHistory,
} from "../geo/geo-api";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {
  setRadioName,
  setRadioPlay,
  setRadioStreamUrl,
  setShowRadiosSelectedProfile,
} from "./store";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ValueWithLabel from "../../ui/value-with-label";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { formatNumber } from "./geo-utils";
import { PaperWrapper, getRealAudioStream } from "./geo-customer-profile";

const RadioBasic = ({ radio }) => {
  const dispatch = useDispatch();

  const playRadio = async (radio) => {
    dispatch(setRadioName(radio.nome));
    const url = await getRealAudioStream(radio.url_steam);
    dispatch(setRadioStreamUrl(url));
    dispatch(setRadioPlay(true));
  };

  if (radio) {
    return (
      <>
        <Paper elevation={3} sx={{ p: 2, mt: 0 }}>
          <Typography>{radio.nome}</Typography>
          <Button
            onClick={() => {
              playRadio(radio);
            }}
            variant="contained"
            startIcon={<PlayCircleOutlineIcon />}
          >
            Ouvir radio
          </Button>

          <ValueWithLabel
            label="Segmentos:"
            value={radio.segmentos.split(",").map((s) => (
              <Chip key={s} label={s} sx={{ marginRight: 0.5 }} />
            ))}
            divider
            horizontal
          />

          <ValueWithLabel
            label="Site:"
            value={<a href={radio.site}>{radio.site}</a>}
            divider
            horizontal
          />

          <ValueWithLabel
            label="Região:"
            value={radio.regiao}
            divider
            horizontal
          />
          <ValueWithLabel
            label="Cidade:"
            value={radio.cidade}
            divider
            horizontal
          />
          <ValueWithLabel
            label="Estado:"
            value={radio.estado}
            divider
            horizontal
          />
        </Paper>
      </>
    );
  }
  return (
    <PaperWrapper>
      <Typography variant="body1">Sem informações para exibir</Typography>
    </PaperWrapper>
  );
};

const RadioRankingHistory = ({ id }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        setIsLoading(true);
        const res = await getRadioRankingHistory(id);
        setData(res.data.result.map(e => ({
            ...e,
            data: new Date(e.data_referencia)
        })));
      } catch (err) {
        setData([]);
      } finally {
        setIsLoading(false);
      }
    };
    if (id) fetchData(id);
  }, [id]);
  if (isLoading) {
    return (
      <PaperWrapper title="Histórico Ranking">
        <LinearProgress color="secondary" />
      </PaperWrapper>
    );
  }

  return (
    <PaperWrapper>
      <Typography variant="body1" gutterBottom>
        <b>Histórico Ranking</b>
      </Typography>
      <Table padding="0">
        <TableHead>
          <TableCell>Data</TableCell>
          <TableCell>Posição</TableCell>
          <TableCell>Visitantes</TableCell>
        </TableHead>
        <TableBody>
          {data.map((e) => (
            <TableRow>
              <TableCell>{e.data.toLocaleDateString()}</TableCell>
              <TableCell>{e.posicvao}</TableCell>
              <TableCell>{formatNumber(e.numero_visitas)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </PaperWrapper>
  );
};

const GeoRadioProfile = () => {
  const dispatch = useDispatch();
  const { showRadiosSelectedProfile, radiosSelected } = useSelector(
    (state) => ({
      showRadiosSelectedProfile: state.showRadiosSelectedProfile,
      radiosSelected: state.radiosSelected,
    }),
    shallowEqual
  );

  return (
    <GeoDrawer open={showRadiosSelectedProfile}>
      <GeoDrawerHeader>
        <Box textAlign="right" sx={{ color: "white" }}>
          <IconButton
            onClick={() => dispatch(setShowRadiosSelectedProfile(false))}
            color="inherit"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <RadioBasic radio={radiosSelected} />
        <RadioRankingHistory id={radiosSelected ? radiosSelected.id : null} />
      </GeoDrawerHeader>
    </GeoDrawer>
  );
};

const GeoRadioProfileWrapper = () => {
  const show = useSelector((state) => state.showRadiosSelectedProfile);
  if (show) return <GeoRadioProfile />;
  return null;
};

export default GeoRadioProfileWrapper;

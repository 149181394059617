import { AuthProvider } from "react-admin";
import jwt from "jwt-simple";
import config from "../config/config";
import { resetAppStore } from "../view/geo/store";

const apiUrl = config.api.url;
const apikey = config.api.api_key;
const jwtKey = config.jwt.key;
const localStorageUser = config.local_storage.user;
const localStorageAuth = config.local_storage.auth;

const authProvider: AuthProvider = {
  login: async ({ email, password, isAdmin }) => {
    resetAppStore();
    const generateLoginRequest = (res) =>
      new Request(apiUrl + `/${res}/sign`, {
        method: "POST",
        body: JSON.stringify({ email: email, password: password }),
        headers: new Headers({
          "Content-Type": "application/json",
          "x-api-key": apikey,
        }),
      });

    try {
      const results = await Promise.allSettled([
        fetch(generateLoginRequest("customer")),
        fetch(generateLoginRequest("admin")),
      ]);

      for (let i = 1; i >= 0; i--) {
        if (results[i].status === "fulfilled") {
          // Se foi negada a solicitação de login, então pula para o próximo...
          if (results[i].value.status === 400) continue;

          const data = await results[i].value.json();
          localStorage.setItem(
            jwt.encode(localStorageUser, jwtKey),
            jwt.encode(JSON.stringify(data.result.user), jwtKey)
          );
          localStorage.setItem(
            jwt.encode(localStorageAuth, jwtKey),
            jwt.encode(JSON.stringify(data.result.auth), jwtKey)
          );

          return Promise.resolve();
        }
      }
    } catch (e) {}

    return Promise.reject();
  },
  logout: () => {
    resetAppStore();
    localStorage.removeItem(jwt.encode(localStorageUser, jwtKey));
    localStorage.removeItem(jwt.encode(localStorageAuth, jwtKey));
    return Promise.resolve();
  },
  checkError: (error) => {
    if (error.status === 401 || error.status === 403) {
      localStorage.removeItem(jwt.encode(localStorageUser, jwtKey));
      localStorage.removeItem(jwt.encode(localStorageAuth, jwtKey));

      return Promise.reject();
    }

    return Promise.resolve();
  },
  checkAuth: () => {
    var item = localStorage.getItem(jwt.encode(localStorageUser, jwtKey));
    if (item !== null)
      return jwt.decode(item, jwtKey) ? Promise.resolve() : Promise.reject();
    else return Promise.reject();
  },
  getPermissions: () => {
    const userkey = localStorage.getItem(jwt.encode(localStorageUser, jwtKey));
    if (userkey !== null && userkey !== undefined) {
      const { active, roles } = JSON.parse(jwt.decode(userkey, jwtKey));
      return roles
        ? Promise.resolve(roles)
        : active
        ? Promise.resolve("admin-customer")
        : Promise.reject();
    } else return Promise.reject();
  },
};

export default authProvider;

import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  DeleteWithConfirmButton,
  BooleanField,
} from "react-admin";
import { DatagridSX, ListSX } from "../../../styled/custom";
import CustomListDefaultToolbar from "../../../ui/custom-list-default-toolbar";
import CustomerExporter from "../../../exporter/customer-exporter";

const CustomerTransmitterList = (props) => (
  <List
    title="Transmissores"
    sort={{ field: "id", order: "desc" }}
    filters={[]}
    exporter={CustomerExporter}
    actions={<CustomListDefaultToolbar />}
    sx={ListSX}
  >
    <Datagrid rowClick="show" bulkActionButtons={false} sx={DatagridSX}>
      <TextField source="id" sortable={true} />
      <TextField source="name" label="Nome" sortable={true} />
      <TextField source="type" label="Tipo" sortable={true} />
      <TextField source="latitude" label="Lat" sortable={false} />
      <TextField source="longitude" label="Lon" sortable={true} />
      <TextField source="radius" label="Raio (metros)" sortable={false} />
      <TextField source="power" label="Potência kW" sortable={false} />
      <TextField source="longitude" label="Nome" sortable={true} />
      <TextField source="customer.name" label="Afiliada" sortable={true} />
      <BooleanField source="active" label="Ativo" />
      <DateField
        source="created_at"
        locales="pt-BR"
        label="Criado em"
        sortable={false}
        showTime
      />
      <EditButton />
      <DeleteWithConfirmButton />
    </Datagrid>
  </List>
);

export default CustomerTransmitterList;

import React from 'react';
import { List, Datagrid, TextField, DateField, EditButton, BooleanField } from 'react-admin';
import { DatagridSX, ListSX } from '../../../styled/custom';
import CustomFilterSpot from '../../../filter/custom-filter-spot';
import CustomListDefaultToolbar from '../../../ui/custom-list-default-toolbar';
import SpotExporter from '../../../exporter/spot-exporter';
import ControlAudio from '../../../ui/control-audio';
import LinkField from '../../../ui/link-field';

const SpotList = (props) => (
  <List
    title="Mapas de Programação"
    sort={{ field: 'id', order: 'desc' }}
    filters={ CustomFilterSpot }
    exporter={ SpotExporter }
    actions={ <CustomListDefaultToolbar /> }
    sx={ListSX}>
    <Datagrid
      rowClick="show"
      bulkActionButtons={false}
      sx={DatagridSX}>
      <TextField source="id" sortable={false} />
      <TextField source="type_plan.name" label="Plano" sortable={false} />
      <TextField source="client_name" label="Cliente" sortable={false} />
      <TextField source="days_group.name" label="Dias da Semana" sortable={false} />
      <TextField source="type_spot.name" label="Tipo de Spot" sortable={false} />
      <TextField source="type_scheme.name" label="Esquema de Veiculação" sortable={false} />
      <TextField source="name" label="Nome da Campanha" sortable={false} />
      <TextField source="date_broadcast" label="Período" sortable={false} />
      <TextField source="hour_broadcast" label="Horário" sortable={false} />
      <TextField source="number_downloads" label="Downloads" sortable={true} />
      <ControlAudio multiple={true} label="Player" />
      <LinkField source="url" label="Url" text="Ver" sortable={false} />
      <TextField source="type" label="Tipo" sortable={false} />
      <BooleanField source="active" label="Ativo" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
      <EditButton />
    </Datagrid>
  </List>
);

export default SpotList;

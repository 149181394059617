import React from 'react';
import { List, Datagrid, TextField, DateField, DeleteButton } from 'react-admin';
import { DatagridSX, ListSX } from '../../../styled/custom';
import CustomFilterSpotFile from '../../../filter/custom-filter-spot-file';
import CustomListExportCreateToolbar from '../../../ui/custom-list-export-create-toolbar';
import ControlAudio from '../../../ui/control-audio';
import SpotFileExporter from '../../../exporter/spot-file-exporter';

const SpotFileList = () => (
  <List
    title="Arquivos do Mapa de Programação"
    sort={{ field: 'id', order: 'desc' }}
    filters={ CustomFilterSpotFile }
    exporter={ SpotFileExporter }
    actions={ <CustomListExportCreateToolbar /> }
    sx={ListSX}>
    <Datagrid
      bulkActionButtons={false}
      sx={DatagridSX}>
      <TextField source="id" sortable={false} />
      <TextField source="spot_name" label="Spot" sortable={false} />
      <ControlAudio label="Player" />
      <DateField source="created_at" locales="pt-BR" label="Data" sortable={false} showTime />
      <DeleteButton />
    </Datagrid>
  </List>
);

export default SpotFileList;

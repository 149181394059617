export const removeAccents = (str) =>
  str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

export const applyLikeFilter = (text, filter) => {
  if (filter !== "") {
    const predicate = removeAccents(filter.toLowerCase());
    const queryString = removeAccents(text.toLowerCase());

    return queryString.indexOf(predicate) >= 0;
  }
  return true;
};

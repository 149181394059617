import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = categoriesMedia =>
{
  const data = categoriesMedia.map(categoryMedia => (
  {
    id: categoryMedia.id,
    nome: categoryMedia.name,
    criado_em: categoryMedia.created_at_format
  }));
  const csv = convertToCSV(
  {
    data,
    fields: ['id', 'nome', 'criado_em']
  });
  downloadCSV(csv, 'categories-media');
}

export default exporter;

import { ReferenceInput, SelectInput } from "react-admin";
import { DateTimeInput } from '../ui/custom-date-time-pickers';

const CustomFilterSpotDownload = [
  <DateTimeInput source="initial_date" label="Data Inicial" />,
  <DateTimeInput source="final_date" label="Data Final" />,
  <ReferenceInput label="Spot" source="spot_id" reference="spot">
    <SelectInput
      label="Spot"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}
    />
  </ReferenceInput>,
  <ReferenceInput label="Afiliado" source="customer_id" reference="customer">
    <SelectInput
      label="Afiliado"
      optionText="profile.name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}
    />
  </ReferenceInput>
];

export default CustomFilterSpotDownload;

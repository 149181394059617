import React from 'react';
import { List, Datagrid, TextField, DateField, EditButton, BooleanField, ArrayField, SingleFieldList, ChipField } from 'react-admin';
import { DatagridSX, ListSX } from '../../../styled/custom';
import CustomFilterDefault from '../../../filter/custom-filter-default';
import CustomListDefaultToolbar from '../../../ui/custom-list-default-toolbar';
import AdminExporter from '../../../exporter/admin-exporter';

const AdminList = (props) => (
  <List
    title="Usuários Admin"
    sort={{ field: 'id', order: 'desc' }}
    filters={ CustomFilterDefault }
    exporter={ AdminExporter }
    actions={ <CustomListDefaultToolbar /> }
    sx={ListSX}>
    <Datagrid
      rowClick="show"
      bulkActionButtons={false}
      sx={DatagridSX}>
      <TextField source="id" sortable={false} />
      <TextField source="name" label="Nome" sortable={false} />
      <TextField source="email" label="E-mail" sortable={false} />
      <ArrayField label="Permissões" source="roles" sortable={false} fieldKey="uuid">
        <SingleFieldList
          linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <BooleanField source="active" label="Ativo" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
      <EditButton />
    </Datagrid>
  </List>
);

export default AdminList;

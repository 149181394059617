import React from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";


const ValueWithLabel = ({ value, label, divider, horizontal }) => {
  return (
    <>
      <div
        style={
          horizontal
            ? {
                display: "flex",
                flexDirection: "row",
                alignItems: "baseline",
              }
            : {}
        }
      >
        <Typography variant="caption" color="secondary">
          {label}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginLeft: (theme) => (horizontal ? theme.spacing(1) : "auto"),
          }}
        >
          {value}
        </Typography>
      </div>
      {divider && <Divider />}
    </>
  );
};

export default ValueWithLabel;

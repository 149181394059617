import React, { useState } from "react";
import { DrawingManager } from "@react-google-maps/api";
import { polygonToWKT } from "./geo-utils";
import {
  computeCoverageStatsInsideArea,
  computeCoverageStatsWithinRadius,
} from "./geo-api";
import { useNotify } from "react-admin";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { addRegionQuery } from "./store";

const createRegionQuery = (stats, feature, color) => ({
  stats,
  feature,
  color,
  visible: true,
});

const GeoTools = () => {
  const { showQueryTool, queryShape, queryColor } = useSelector(
    (state) => ({
      showQueryTool: state.showQueryTool,
      queryShape: state.queryShape,
      queryColor: state.queryColor,
    }),
    shallowEqual
  );
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const notifyStats = (stat) => {
    const msg = `Cidades: ${stat.cities}, População: ${stat.population}`;
    notify(msg);
  };

  const handleGetPopulationWhitin = async (circle) => {
    try {
      setIsLoading(true);
      const center = circle.getCenter();
      const res = await computeCoverageStatsWithinRadius(
        center.lat(),
        center.lng(),
        circle.getRadius()
      );
      dispatch(addRegionQuery(createRegionQuery(res.data, circle, queryColor)));
      notifyStats(res.data);
    } catch (e) {
      circle.setMap(null);
    } finally {
      setIsLoading(false);
    }
  };
  const handleGetPopulationInside = async (polygon) => {
    try {
      setIsLoading(true);
      const res = await computeCoverageStatsInsideArea(polygonToWKT(polygon));
      dispatch(
        addRegionQuery(createRegionQuery(res.data, polygon, queryColor))
      );
      notifyStats(res.data);
    } catch (e) {
      polygon.setMap(null);
    } finally {
      setIsLoading(false);
    }
  };

  if (!showQueryTool) return null;

  return (
    <>
      <DrawingManager
        key="drawing-manager"
        onCircleComplete={(e) => handleGetPopulationWhitin(e)}
        onPolygonComplete={(e) => handleGetPopulationInside(e)}
        drawingMode={
          queryShape === "circle"
            ? window.google.maps.drawing.OverlayType.CIRCLE
            : queryShape === "polygon"
            ? window.google.maps.drawing.OverlayType.POLYGON
            : null
        }
        options={{
          circleOptions: {
            strokeColor: queryColor,
            fillColor: queryColor,
          },
          polygonOptions: {
            strokeColor: queryColor,
            fillColor: queryColor,
          },
        }}
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.appBar + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
        <Typography variant="h5">
          Calculando estatísticas (isso pode demorar um pouco)...
        </Typography>
      </Backdrop>
    </>
  );
};

export default GeoTools;

import React from 'react';
import { Show, TextField, SimpleShowLayout, DateField } from 'react-admin';
import { ContentPageSX, SimpleShowLayoutSX } from '../../../styled/custom';

const TypeSchemeShow = props => (
  <Show
    title="Tipo de Veiculação"
    sx={ContentPageSX}>
    <SimpleShowLayout
      sx={SimpleShowLayoutSX}>
      <TextField source="id" />
      <TextField source="name" label="Nome" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
    </SimpleShowLayout>
  </Show>
);

export default TypeSchemeShow;

import React from "react";
import { BooleanInput, Edit, SimpleForm } from "react-admin";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomTextField from "../../../ui/custom-text-field";
import { ContentPageSX } from "../../../styled/custom";
import { email, hasValue, minLength } from "../../../utils/schemas";
import EditTitle from "../../../ui/edit-title";
import CustomEditToolbar from "../../../ui/custom-edit-toolbar";
import { useGetList } from "ra-core";
import CustomSelect from "../../../ui/custom-select";
import GroupFields from "../../../styled/group-fields";
import CustomAutocomplete from "../../../ui/custom-autocomplete";

const Schema = Yup.object({
  name: minLength(3, "Digite o nome"),
  email: email("Digite o e-mail"),
  phone: minLength(3, "Digite o telefone"),
  radioclass: minLength(3, "Digite a classe da radio"),
  message: minLength(3, "Digite a mensagem"),
  city_id: hasValue("Selecione uma cidade"),
});

const AffiliationRequestEdit = () => {
  const { data: dataCities, isLoading: isLoadingCities } =
    useGetList("setup/cities");

  return (
    <Edit
      title={<EditTitle subtitle="Editar Requisição: " source="name" />}
      redirect="list"
      mutationMode="pessimistic"
      sx={ContentPageSX}
    >
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomEditToolbar />}
      >
        <CustomTextField name="name" label="Nome" fullWidth isRequired />
        <CustomTextField name="phone" label="Telefone" fullWidth isRequired />
        <CustomTextField name="email" label="Email" fullWidth isRequired />
        {!isLoadingCities && (
          <CustomAutocomplete
            data={dataCities}
            name="city_id"
            label="Cidade"
            multiple={false}
            fullWidth
            isrequired="true"
          />
        )}
        <CustomTextField
          name="radioclass"
          label="Classe"
          fullWidth
          isRequired
        />
        <CustomTextField name="message" label="Telefone" fullWidth isRequired />

        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Edit>
  );
};

export default AffiliationRequestEdit;

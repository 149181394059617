import React from 'react';
import { Edit, SimpleForm, BooleanInput } from 'react-admin';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import EditTitle from '../../../ui/edit-title';
import CustomEditToolbar from '../../../ui/custom-edit-toolbar';
import CustomTextField from '../../../ui/custom-text-field';
import { minLength } from '../../../utils/schemas';
import { ContentPageSX } from '../../../styled/custom';

const Schema = Yup.object(
{
  name: minLength(3, "Digite o nome")
});

const TypeSchemeEdit = (props) => (
  <Edit
    title={ <EditTitle subtitle="Editar Tipo de Veiculação: " source="name" /> }
    redirect="list"
    mutationMode="pessimistic"
    sx={ContentPageSX}>
    <SimpleForm
      noValidate
      resolver={yupResolver(Schema)}
      toolbar={ <CustomEditToolbar /> }>
      <CustomTextField source="id" label="Id" disabled />
      <CustomTextField name="name" label="Nome" fullWidth />
      <BooleanInput source="active" label="Ativo" />
    </SimpleForm>
  </Edit>
);

export default TypeSchemeEdit;

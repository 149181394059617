import React from "react";
import { TopToolbar, FilterButton, CreateButton, ExportButton } from "react-admin";

const CustomListDefaultToolbar = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export default CustomListDefaultToolbar;

import React from 'react';
import {
  TopToolbar,
  FilterButton,
  ExportButton
} from 'react-admin';

const CustomListFilterExportToolbar = () => (
  <TopToolbar>
    <FilterButton />
    <ExportButton />
  </TopToolbar>
);

export default CustomListFilterExportToolbar;

import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomTextField from '../../../ui/custom-text-field';
import { ContentPageSX } from '../../../styled/custom';
import { minLength } from '../../../utils/schemas';

const Schema = Yup.object(
{
  name: minLength(3, "Digite o nome")
});

const ProgramCreate = () => (
  <Create
    title="Novo Programa Retransmitido"
    redirect="list"
    sx={ContentPageSX}>
    <SimpleForm
      noValidate
      resolver={yupResolver(Schema)}>
      <CustomTextField name="name" label="Nome" fullWidth isRequired />
    </SimpleForm>
  </Create>
);

export default ProgramCreate;

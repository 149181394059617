import React from "react";
import { BooleanInput, Create, SimpleForm } from "react-admin";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomTextField from "../../../ui/custom-text-field";
import { ContentPageSX } from "../../../styled/custom";
import { minLength, hasValue } from "../../../utils/schemas";

const Schema = Yup.object({
  name: minLength(3, "Digite o nome"),
  quicksight_dashboard_id: hasValue(),
});

const DashboardCreate = () => (
  <Create title="Novo Dashboard" redirect="list" sx={ContentPageSX}>
    <SimpleForm noValidate resolver={yupResolver(Schema)}>
      <CustomTextField name="name" label="Nome" fullWidth isRequired />
      <CustomTextField
        name="quicksight_dashboard_id"
        label="Id do Quicksight"
        fullWidth
        isRequired
      />
      <BooleanInput source="active" label="Ativo" />
    </SimpleForm>
  </Create>
);

export default DashboardCreate;

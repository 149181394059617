import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = spotReceipts =>
{
  const data = spotReceipts.map(spotReceipt => (
  {
    id: spotReceipt.id,
    spot: spotReceipt.spot.name,
    afiliado: spotReceipt.customer.profile.name,
    url: spotReceipt.url,
    type: spotReceipt.type,
    data: spotReceipt.created_at_format
  }));
  const csv = convertToCSV(
  {
    data,
    fields: ['id', 'spot', 'afiliado', 'url', 'type', 'data']
  });
  downloadCSV(csv, 'spot-receipts');
}

export default exporter;

import React from 'react';
import { useEffect } from 'react';
import { Show, TextField, SimpleShowLayout, useShowContext } from 'react-admin';
import { ContentPageSX, SimpleShowLayoutSX } from '../../../styled/custom';
import CustomerMediaShowTitle from '../../../ui/customer-media-show-title';
import useNotification from "../../../hook/useNotification";

const Read = () => {
  const {record} = useShowContext();
  const [total, actions] = useNotification();

  useEffect(() => {
    actions.read(record.id);
  }, []);

  return null;
}

const CustomerNotificationShow = props => {
  return (
    <Show
      title={<CustomerMediaShowTitle />}
      sx={ContentPageSX}>
      <SimpleShowLayout
        sx={SimpleShowLayoutSX}>
        <TextField source="id" />
        <TextField source="title" label="Título" sortable={false} />
        <TextField source="message" label="Mensagem" sortable={false} />
      </SimpleShowLayout>
      <Read />
    </Show>
  )
};

export default CustomerNotificationShow;

import React from "react";
import {
  BooleanInput,
  Create,
  FileInput,
  FileField,
  SimpleForm,
  NumberInput,
} from "react-admin";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomTextField from "../../../ui/custom-text-field";
import { ContentPageSX } from "../../../styled/custom";
import { color, filesSize, inRange, minLength } from "../../../utils/schemas";
import { ColorInput } from "react-admin-color-picker";

const Schema = Yup.object({
  name: minLength(2, "Digite o nome"),
  files: filesSize(
    200000000,
    "Selecione um arquivo com tamanho total de até 200MB"
  ),
  fill_opacity: inRange(0.0, 1.0),
  stroke_opacity: inRange(0.0, 1.0),
  stroke_width: inRange(1, 40),
  stroke_color: color(),
  fill_color: color(),
});

const MapLayerCreate = (props) => {
  return (
    <Create title="Nova camada" sx={ContentPageSX}>
      <SimpleForm noValidate resolver={yupResolver(Schema)}>
        <CustomTextField name="name" label="Nome" fullWidth isRequired />
        <BooleanInput source="active" label="Ativo" />

        <ColorInput source="stroke_color" name="stroke_color" label="Cor do traçado" variant='filled' />
        <NumberInput source="stroke_width" name="stroke_width" label="Espessura do traçado" variant="filled" defaultValue={1} />
        <NumberInput source="stroke_opacity" name="stroke_opacity" label="Opacidade do traçado" variant="filled" defaultValue={1} />
        <ColorInput source="fill_color" name="fill_color" label="Cor do preenchimento" variant='filled' />
        <NumberInput source="fill_opacity" name="fill_opacity" label="Opacidade do preenchimento" variant="filled" defaultValue={1} />

        <FileInput
          source="files"
          label="Tamanho máximo do arquivo: 200 MB"
          multiple={false}
          maxSize={200000000}
          accept=".zip,.kml"
        >
          <FileField source="src" src="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

export default MapLayerCreate;

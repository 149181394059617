import { useStore, useRefresh, useNotify } from "react-admin";
import { unreadNotification, readNotification } from '../provider/data-provider';

const useNotification = () => {
    const [total, setNotification] = useStore('customer.notification', 0);
    const refresh = useRefresh();
    const notify = useNotify();

    const actions = {
      read: async (id) => {
        await readNotification(id);
        actions.fetch();
        refresh();
      },
      fetch: async () => {
        const { data } = await unreadNotification();
        const count = (data ? data.length : 0);

        setNotification(count);

        if (count > 0)
          notify('Você tem notificações não lidas!', { type: 'warning' });
      }
    }

    return [total, actions];
}

export default useNotification;

import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = clients =>
{
  const data = clients.map(client => (
  {
    id: client.id,
    dias: client.days_group.name,
    tipo_spot: client.type_spot.name,
    tipo_esquema: client.type_scheme.name,
    nome: client.name,
    validade_contrato: client.contract_validity_format,
    quantidade_insercoes: client.number_inserts,
    horario: client.broadcast,
    criado_em: client.created_at_format
  }));
  const csv = convertToCSV(
  {
    data,
    fields: ['id', 'dias', 'tipo_spot', 'tipo_esquema', 'nome', 'validade_contrato',
             'quantidade_insercoes', 'horario', 'criado_em']
  });
  downloadCSV(csv, 'clients');
}

export default exporter;

import React, { useEffect, useState } from "react";
import { useRecordContext } from "react-admin";
import SkipNextRoundedIcon from "@mui/icons-material/SkipNextRounded";

const ControlAudio = ({ multiple, data }) => {
  const [audio, setAudio] = useState();
  const [index, setIndex] = useState(0);
  const [autoPlay, setAutoPlay] = useState(false);
  const record = useRecordContext();
  const mData = multiple ? (record.files ? record.files : data) : record;

  const next = (e) => {
    setAutoPlay(true);

    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    var newIndex = index + 1;
    if (newIndex >= mData.length) newIndex = 0;

    setIndex(newIndex);
  };

  useEffect(() => {
    if (multiple && mData) {
      if (mData.length > 0 && index < mData.length) {
        setAudio(mData[index].url);
      }
    } else {
      setAudio(mData.url);
    }
  }, [index]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 2,
      }}
    >
      <audio
        controls
        autoPlay={autoPlay}
        src={audio}
        preload="none"
        controlsList="nodownload noplaybackrate nofullscreen noremoteplayback"
      />
      {multiple ? (
        <button
          style={{
            border: "none",
            backgroundColor: "transparent",
            cursor: "pointer",
          }}
          onClick={next}
        >
          <SkipNextRoundedIcon />
          <br />
          <span style={{ fontSize: "0.6rem", display: "block" }}>
            {index + 1} / {mData.length}
          </span>
        </button>
      ) : null}
    </div>
  );
};

export default ControlAudio;

import { React } from 'react';
import { Create, SimpleForm, FormDataConsumer, useGetList } from 'react-admin';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContentPageSX } from '../../../styled/custom';
import CustomTextField from '../../../ui/custom-text-field';
import CustomSelect from '../../../ui/custom-select';
import CustomAutocomplete from '../../../ui/custom-autocomplete';
import { minLength } from '../../../utils/schemas';

const Schema = Yup.object(
{
  title: minLength(3, "Digite o título"),
  message: minLength(3, "Digite a mensagem")
});

const NotificationCreate = () =>
{
  const { data: dataCustomer, isLoading: isLoadingCustomer } = useGetList("customer");
  const { data: dataCities, isLoading: isLoadingCities } = useGetList("setup/cities");
  const { data: dataRegion, isLoading: isLoadingRegion } = useGetList("region");

  return (
    <Create
      title="Nova Notificação"
      redirect="list"
      sx={ContentPageSX}>
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}>
        <CustomTextField name="title" label="Título" fullWidth isRequired />
        <CustomTextField name="message" label="Mensagem" fullWidth isRequired />
        <CustomSelect label="Filtrar" source="filter_id" multiple={false} fullWidth={true} data={[
            { id: 0, name: 'Nenhum' },
            { id: 1, name: 'Afiliado' },
            { id: 2, name: 'Cidade' },
            { id: 3, name: 'Região' }
        ]} />
        <FormDataConsumer>
        {
          ({ formData, className, ...rest}) => formData.filter_id === 1 && !isLoadingCustomer && (
              <CustomSelect data={dataCustomer} name="customers" label="Afiliado(s)" multiple={true} text="profile.name" fullWidth />
        )}
        </FormDataConsumer>
        <FormDataConsumer>
        {
          ({ formData, className, ...rest}) => formData.filter_id === 2 && !isLoadingCities && (
              <CustomAutocomplete data={dataCities} name="cities" label="Cidade(s)" multiple={true} fullWidth />
        )}
        </FormDataConsumer>
        <FormDataConsumer>
        {
          ({ formData, className, ...rest}) => formData.filter_id === 3 && !isLoadingRegion && (
              <CustomSelect data={dataRegion} name="regions" label="Região(ões)" multiple={true} fullWidth />
        )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
}

export default NotificationCreate;

import React from 'react';
import { Show, TextField, SimpleShowLayout, DateField } from 'react-admin';
import { ContentPageSX, SimpleShowLayoutSX } from '../../../styled/custom';
import LinkField from '../../../ui/link-field';
import ControlAudio from '../../../ui/control-audio';

const MediaShow = props => (
  <Show
    title="Produto da Cesta"
    sx={ContentPageSX}>
    <SimpleShowLayout
      sx={SimpleShowLayoutSX}>
      <TextField source="id" />
      <TextField source="category_media.name" label="Categoria" sortable={false} />
      <TextField source="name" label="Nome" sortable={false} />
      <TextField source="number_downloads" label="Downloads" sortable={false} />
      <ControlAudio label="Player" />
      <LinkField source="url" label="Url" text="Ver" sortable={false} />
      <TextField source="type" label="Tipo" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
    </SimpleShowLayout>
  </Show>
);

export default MediaShow;

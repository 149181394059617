import React from 'react';
import { List, Datagrid, TextField, useRecordContext } from "react-admin";
import { Badge, Button, styled } from "@mui/material";
import { DatagridSX, ListSX } from '../../../styled/custom';
import useNotification from "../../../hook/useNotification";

const CustomButton = styled(Button)(({ theme, full }) => ({
  display: "flex",
  minWidth: "40px",
  height: "40px",
  fontSize: "0.9rem",
  padding: "3px 15px",
  textTransform: "initial",
  backgroundColor: "transparent",
  borderRadius: "100%",
  "&:hover": {
    backgroundColor: theme.palette.grey[300],
  },
}));

const StatusMessage = (props) => {
  const record = useRecordContext();
  const [total, actions] = useNotification();

  if (record.read) return null;

  return (
    <CustomButton onClick={(e) => {
      e.stopPropagation();
      actions.read(record.id);
    }}>
      <Badge badgeContent={Number(!record.read)} color="error" variant="dot" />
    </CustomButton>
  )
}

const CustomerNotificationList = (props) => (
  <List
    title="Notificações"
    actions={null}
    sx={ListSX}>
    <Datagrid
      rowClick="show"
      bulkActionButtons={false}
      sx={DatagridSX}>
      <StatusMessage />
      <TextField source="id" sortable={false} />
      <TextField source="title" label="Título" sortable={false} />
      <TextField source="message" label="Mensagem" sortable={false} />
    </Datagrid>
  </List>
);

export default CustomerNotificationList;

import React from 'react';
import {
  Toolbar,
  SaveButton
} from 'react-admin';

const CustomEditToolbar = props => (
  <Toolbar>
    <SaveButton label="Salvar" />
  </Toolbar>
);

export default CustomEditToolbar;

import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteWithConfirmButton,
  BooleanField,
  NumberField,
} from "react-admin";
import { DatagridSX, ListSX } from "../../../styled/custom";
import CustomListDefaultToolbar from "../../../ui/custom-list-default-toolbar";
import { ColorField } from "react-admin-color-picker";

const MapLayerList = (props) => (
  <List
    title="Camadas do Mapa"
    sort={{ field: "id", order: "desc" }}
    actions={<CustomListDefaultToolbar />}
    filters={[]}
    sx={ListSX}
  >
    <Datagrid rowClick="show" bulkActionButtons={false} sx={DatagridSX}>
      <TextField source="id" sortable={true} />
      <TextField source="name" label="Nome" sortable={true} />
      <BooleanField source="active" label="Ativo" sortable={true} />

      <ColorField source="stroke_color" label="Cor do traçado" />
      <NumberField source="stroke_width" label="Espessura traçado" />
      <ColorField source="fill_color" label="Cor do preenchimento" />

      <DateField
        source="created_at"
        locales="pt-BR"
        label="Criado em"
        sortable={false}
        showTime
      />
      <EditButton />
      <DeleteWithConfirmButton />
    </Datagrid>
  </List>
);

export default MapLayerList;

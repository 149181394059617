import { Data } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useNotify } from "react-admin";
import { useDispatch, useSelector } from "react-redux";
import axios from "../geo-api";
import {
  createFeatureInformation,
  setFeatureInformation,
  toggleFeatureInformation,
} from "../store";

const fetchRegionsLayer = async () => axios.get("/geo/layers/regions");

const RegionPropertyMap = {
  id: {
    label: "Id",
  },
  active: {
    label: "Ativo",
    type: "boolean",
  },
  cities: {
    label: "Cidades",
    type: "string-list",
  },
  color: {
    label: "Cor",
    type: "color",
  },
  name: {
    label: "Nome",
  },
  created_at: {
    label: "Criado em",
  },
  updated_at: {
    label: "Modificado em",
  },
};

const RegionsLayer = () => {
  const [data, setData] = useState(null);
  const [hiddenFeatures, setHiddenFeatures] = useState([]);
  const notify = useNotify();
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();
  const regionsFilter = useSelector((state) => state.regionsFilter);

  useEffect(() => {
    if (isLoaded || !data) return;

    const loadData = async () => {
      try {
        const response = await fetchRegionsLayer();
        data.addGeoJson(response.data.result, { idPropertyName: "id" });
        data.setStyle((feature) => {
          let color = feature.getProperty("color") || "#ff0000";
          return {
            fillColor: color,
            strokeColor: color,
            strokeWeight: 1,
          };
        });
        setIsLoaded(true);
      } catch (e) {
        notify("Não foi possível carregar a camada de regiões!", {
          type: "error",
        });
        console.log(e);
      }
    };
    if (!isLoaded) loadData();
  }, [data]);

  // Update filters
  useEffect(() => {
    if (data) {
      let hidden = [];

      // Primeiramente examinamos as feições que estão foras do mapa e reincluimos se necessário
      hiddenFeatures.forEach((feature) => {
        if (regionsFilter.indexOf(feature.getId()) >= 0) {
          data.add(feature);
        } else {
          hidden.push(feature);
        }
      });

      data.forEach((feature) => {
        if (regionsFilter.indexOf(feature.getId()) < 0) {
          hidden.push(feature);
          data.remove(feature);
        }
      });
      setHiddenFeatures(hidden);
    }
  }, [regionsFilter]);

  return (
    <Data
      onLoad={(data) => {
        setData(data);
      }}
      onClick={(event) => {
        const name = event.feature.getProperty("name");
        const id = event.feature.getProperty("id");
        const data = {};
        event.feature.forEachProperty((value, property) => {
          data[property] = value;
        });
        dispatch(
          setFeatureInformation(
            createFeatureInformation(
              `Região: ${name}`,
              `/#/region/${id}/show`,
              data,
              RegionPropertyMap
            )
          )
        );
        dispatch(toggleFeatureInformation(true));
      }}
    />
  );
};

export default RegionsLayer;

import { useLogin, useNotify } from "react-admin";
import {
  Stack,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Radio,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import SubmitButton from "../styled/submit-button";
import CenterSimpleContent from "../layout/center-simple-content";
import { email, hasValue, minLength } from "../utils/schemas";

const Schema = Yup.object({
  // isAdmin: hasValue(),
  email: email(),
  password: minLength(6, "Senha inválida"),
}).required();

const LoginPage = (props) => {
  const login = useLogin();
  const notify = useNotify();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
  });

  const onSubmit = (data) => {
    login(data).catch(() => notify("E-mail ou senha inválido"));
  };

  return (
    <CenterSimpleContent title="Faça o login">
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1} width="300px">
          <FormControl error={Boolean(errors.isAdmin)}>
            {/* <RadioGroup
              defaultValue="customer"
              row
              sx={{ justifyContent: "space-around" }}
            >
              <FormControlLabel
                value="customer"
                control={<Radio />}
                label="Afiliado"
                {...register("isAdmin")}
              />
              <FormControlLabel
                value="admin"
                control={<Radio />}
                label="Administrador"
                {...register("isAdmin")}
              />
            </RadioGroup> */}
            <FormHelperText>{errors.isAdmin?.message}</FormHelperText>
          </FormControl>
          <TextField
            label="E-mail"
            type="email"
            placeholder="E-mail"
            size="small"
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
            {...register("email")}
          />
          <TextField
            label="Senha"
            type="password"
            placeholder="Senha"
            size="small"
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
            {...register("password")}
          />
          <SubmitButton
            type="submit"
            variant="contained"
            sx={{
              background: "black",
              color: "white",
              "&:hover": { background: "#555" },
            }}
          >
            Entrar
          </SubmitButton>
        </Stack>
      </form>
    </CenterSimpleContent>
  );
};

export default LoginPage;

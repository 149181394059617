import React from "react";
import {
  Create,
  SimpleForm,
  FileInput,
  FileField,
  useGetList,
} from "react-admin";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSelect from "../../../ui/custom-select";
import { ContentPageSX } from "../../../styled/custom";
import { hasValue, filesSize } from "../../../utils/schemas";

export const MEDIA_MAXIMUM_FILES_SIZE = 600_000_000;

const Schema = Yup.object({
  category_media_id: hasValue("Selecione uma categoria"),
  files: filesSize(
    MEDIA_MAXIMUM_FILES_SIZE,
    "Selecione 1 ou mais arquivos com tamanho máximo de 600 MB no total"
  ),
});

const MediaCreate = () => {
  const { data, isLoading } = useGetList("category-media", {
    pagination: { page: 1, perPage: 1000000 },
    filter: {
      activated: true,
    },
  });

  return (
    <Create title="Novo Produto da Cesta" redirect="list" sx={ContentPageSX}>
      <SimpleForm noValidate resolver={yupResolver(Schema)}>
        {!isLoading && (
          <CustomSelect
            data={data}
            name="category_media_id"
            label="Categoria"
            multiple={false}
            fullWidth
            isRequired
          />
        )}
        <FileInput
          source="files"
          label="Tamanho Máximo dos Arquivos Somados: 500 MB"
          multiple={true}
          maxSize={MEDIA_MAXIMUM_FILES_SIZE}
          accept="application/*, video/*, audio/*, image/*"
        >
          <FileField src="src" title="title" source="src" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

export default MediaCreate;

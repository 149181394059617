import React from "react";
import {
  Typography,
  Box,
  Divider,
  TextField,
  InputAdornment,
} from "@mui/material";
import Search from "@mui/icons-material/Search";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Checkbox from "@mui/material/Checkbox";
import RoomIcon from "@mui/icons-material/Room";
import { applyLikeFilter } from "../../utils/text";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getAppMap, setPublicRadioFilter, setSelectedRadio } from "./store";

const formatRadioLocation = (radio) => {
  const parts = [];
  if (radio.street) parts.push(radio.street);
  if (radio.number) parts.push(radio.number);
  if (radio.neighborhood) parts.push(radio.neighborhood);
  if (radio.city) parts.push(`${radio.city} / MG`);
  return parts.join(", ");
};

export const RadioNetwork = () => {
  const { publicRadios, selectedRadio, publicRadioFilter } = useSelector(
    (state) => ({
      publicRadios: state.publicRadios,
      selectedRadio: state.selectedRadio,
      publicRadioFilter: state.publicRadioFilter,
    }),
    shallowEqual
  );
  const map = getAppMap();
  const dispatch = useDispatch();
  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.secondary.main,
            color: (theme) => theme.palette.gray[50],
            display: "flex",
            flexDirection: "column",
            width: "100%",
            padding: (theme) => theme.spacing(1),
          }}
        >
          <Typography variant="h6">
            Rede Itasat - {publicRadios.length} afiliadas
          </Typography>
          <Typography variant="body1">Itatiaia - Belo Horizonte</Typography>
          <Divider />
          <TextField
            label="Buscar"
            sx={{ backgroundColor: (theme) => "#fff" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
            value={publicRadioFilter}
            onChange={(e) => dispatch(setPublicRadioFilter(e.target.value))}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Checkbox
              label="Exibir todas"
              variant="white"
              checked={!selectedRadio}
              onClick={(e, check) => {
                dispatch(setSelectedRadio(null));
              }}
            />
            Exibir tudo
          </div>
        </Box>
        <Box sx={{ flex: 1 }}>
          <List>
            {publicRadios
              .filter(
                (radio) =>
                  applyLikeFilter(radio.name, publicRadioFilter) ||
                  applyLikeFilter(radio.city, publicRadioFilter)
              )
              .map((radio, index) => (
                <ListItemButton
                  divider
                  dense
                  key={radio.name + index}
                  selected={selectedRadio && selectedRadio === radio.id}
                  onClick={() => {
                    dispatch(
                      setSelectedRadio(
                        radio.id === selectedRadio ? null : radio.id
                      )
                    );
                    if (map) {
                      if (radio.latitude && radio.longitude) {
                        map.panTo({
                          lat: radio.latitude,
                          lng: radio.longitude,
                        });
                      }
                    }
                  }}
                >
                  <ListItemIcon>
                    {selectedRadio && selectedRadio === radio.id && (
                      <RoomIcon
                        sx={{ color: (theme) => theme.palette.secondary.main }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="body1">
                      <b>{radio.name}</b>
                    </Typography>
                    <Typography variant="body1">
                      {formatRadioLocation(radio)}
                    </Typography>
                    <Typography variant="body1">
                      <a href={`mailto:${radio.email}`}>{radio.email || ""}</a>
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

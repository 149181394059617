import { stringify } from "query-string";
import jwt from "jwt-simple";
import axios from "axios";
import config from "../config/config";
import paramsToFormData from "../utils/params-to-form-data";
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  UPDATE,
  CREATE,
  DELETE,
  HttpError,
} from "react-admin";

const apiUrl = config.api.url;
const apiKey = config.api.api_key;
const jwtKey = config.jwt.key;
const localStorageAuth = config.local_storage.auth;
const localStorageUser = config.local_storage.user;

const authToken = () => {
  try {
    return JSON.parse(
      jwt.decode(
        localStorage.getItem(jwt.encode(localStorageAuth, jwtKey)),
        jwtKey
      )
    ).auth_token;
  } catch (err) {
    return "";
  }
};

const permissions = () => {
  const userkey = localStorage.getItem(jwt.encode(localStorageUser, jwtKey));
  if (userkey !== null && userkey !== undefined) {
    const { roles } = JSON.parse(jwt.decode(userkey, jwtKey));
    return roles ? roles : "admin-customer";
  }
};

export const headers = () => {
  const token = authToken();
  return {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    "x-api-key": apiKey,
    "x-token": "Bearer " + token,
  };
};

const provider = (type, resource, params) => {
  let url = "";
  //console.log(resource, params);

  if (resource === "days_group") resource = "days-group";
  else if (resource === "type_spot") resource = "type-spot";
  else if (resource === "type_scheme") resource = "type-scheme";
  else if (resource === "type_plan") resource = "type-plan";
  else if (resource === "category_media") resource = "category-media";
  else if (resource === "media_download") resource = "media-download";
  else if (resource === "media_download_map") resource = "media-download-map";
  else if (resource === "spot_day") resource = "spot-day";
  else if (resource === "spot_file") resource = "spot-file";
  else if (resource === "spot_download") resource = "spot-download";
  else if (resource === "spot_receipt") resource = "spot-receipt";
  else if (resource === "spot_download_map") resource = "spot-download-map";
  else if (resource === "customer_programming_map")
    resource = "customer-programming-map";
  else if (resource === "customer_media") resource = "customer-media";
  else if (resource === "customer_spot_receipt")
    resource = "customer-spot-receipt";
  else if (resource === "customer_notification")
    resource = "customer-notification";
  else if (resource === "customer_staff_member")
    resource = "customer-staff-member";
  else if (resource === "customer_transmitter")
    resource = "customer-transmitter";
  else if (resource === "customer_note")
    resource = "customer-note";
  else if (resource === "affiliation_request") resource = "affiliation-request";
  else if (resource === "map_layer") resource = "map-layer";

  // REFACTOR PARAM
  if (
    params != null &&
    params.data != null &&
    params.data.days_broadcast != null
  )
    params.data.days_broadcast = params.data.days_broadcast[0].split(",");

  const options = {
    headers: headers(),
    data: paramsToFormData(params.data),
  };

  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
      };

      if (resource === "customer-media")
        url = `${apiUrl}/customer-media/medias?${stringify(query)}`;
      else if (resource === "customer-category-media")
        url = `${apiUrl}/customer-media/categories?${stringify(query)}`;
      else if (resource === "customer-spot-receipt-spots")
        url = `${apiUrl}/customer-spot-receipt/spots?${stringify(query)}`;
      else url = `${apiUrl}/${resource}?${stringify(query)}`;

      options.url = url;

      break;
    }
    case GET_ONE: {
      if (resource === "dashboard-embed-url")  url = `${apiUrl}/dashboard/${params.id}/embed-url`;
      else if (resource === "settings") url = `${apiUrl}/settings`;
      else if (resource === "customer-media")
        url = `${apiUrl}/customer-media/${params.id}/media`;
      else url = `${apiUrl}/${resource}/${params.id}`;

      options.url = url;

      break;
    }
    case GET_MANY: {
      if (resource === "customer-media")
        url = `${apiUrl}/customer-media/medias`;
      else if (resource === "customer-category-media")
        url = `${apiUrl}/customer-media/categories`;
      else if (resource === "customer-spot-receipt-spots")
        url = `${apiUrl}/customer-spot-receipt/spots`;
      else url = `${apiUrl}/${resource}`;

      options.url = url;

      break;
    }
    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify({
          ...params.filter,
          [params.target]: params.id,
        }),
      };

      url = `${apiUrl}/${resource}?${stringify(query)}`;
      options.url = url;

      break;
    }
    case UPDATE: {
      url = `${apiUrl}/${resource}/${params.id}`;
      options.method = "PUT";
      options.url = url;

      break;
    }
    case CREATE: {
      url = `${apiUrl}/${resource}`;
      options.method = "POST";
      options.url = url;

      break;
    }
    case DELETE: {
      url = `${apiUrl}/${resource}/${params.id}`;
      options.method = "DELETE";
      options.url = url;

      break;
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }

  return axios(options)
    .then((res) => {
      return res.data;
    })
    .then((response) => {
      switch (type) {
        case GET_LIST:
        case GET_MANY:
          const data = response.data;
          const result = response.result;
          const meta = response.meta;

          return {
            data: data ? data : result,
            total: meta.total || 1,
            pagination: {
              page: meta.page || 1,
              perPage: meta.perpage || 10,
            },
          };
        case GET_MANY_REFERENCE:
          return {
            data: response.data,
            total: response.data.length,
          };
        case GET_ONE:
          return {
            data: response.result,
          };
        case UPDATE:
        case CREATE:
          return {
            data: response.result,
          };
        default:
          return { data: [] };
      }
    })
    .catch((error) => {
      console.log(error);
      if (error.response.status < 200 || error.response.status >= 300) {
        if (error.response.data && error.response.data.error) {
          return Promise.reject(
            new HttpError(error.response.data.error, error.response.status)
          );
        } else {
          return Promise.reject(
            new HttpError(error.response.statusText, error.response.status)
          );
        }
      }
    });
};

const fetchURLFile = (id, model, params) => {
  const options = {
    headers: headers(),
    url: `${apiUrl}/${model}/${id}/download`,
    data: paramsToFormData(params),
    method: "POST",
  };

  return axios(options).then((res) => {
    return res.data;
  });
};

const unreadNotification = () => {
  // CUSTOMER
  if (permissions() === "admin-customer") {
    const options = {
      headers: headers(),
      url: `${apiUrl}/customer-notification`,
      params: { filter: '{"unread": true}', range: "[0, 1000]" },
      method: "GET",
    };

    return axios(options).then((res) => {
      return res.data;
    });
  } else return [];
};

const readNotification = (id) => {
  const options = {
    headers: headers(),
    url: `${apiUrl}/customer-notification/${id}`,
    method: "PUT",
  };

  return axios(options).then((res) => {
    return res.data;
  });
};

export { fetchURLFile, unreadNotification, readNotification };
export default provider;

import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = mediaDownloads =>
{
  const data = mediaDownloads.map(mediaDownload => (
  {
    id: mediaDownload.id,
    midia: mediaDownload.media.name,
    afiliado: mediaDownload.customer.profile.name,
    latitude: mediaDownload.lat,
    longitude: mediaDownload.lng,
    data: mediaDownload.created_at_format
  }));
  const csv = convertToCSV(
  {
    data,
    fields: ['id', 'midia', 'afiliado', 'latitude', 'longitude', 'data']
  });
  downloadCSV(csv, 'media-downloads');
}

export default exporter;

import { ReferenceInput, SelectInput } from "react-admin";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import CustomTextField from "../ui/custom-text-field";
import QuickFilter from "../ui/quick-filter";

const CustomFilterMedia = [
  <CustomTextField
    label="Buscar"
    source="q"
    alwaysOn
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput
    label="Categoria"
    source="category_media_id"
    reference="category-media"
  >
    <SelectInput
      label="Categoria"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}
    />
  </ReferenceInput>,
  <QuickFilter source="no_download" label="Sem Download" defaultValue={true} />,
  <QuickFilter source="activated" label="Ativos" defaultValue={true} />,
  <QuickFilter source="disabled" label="Desativados" defaultValue={true} />
];

export default CustomFilterMedia;

import React from "react";
import {
  BooleanInput,
  Edit,
  FileField,
  FileInput,
  SimpleForm,
} from "react-admin";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomTextField from "../../../ui/custom-text-field";
import { ContentPageSX } from "../../../styled/custom";
import { hasValue, minLength } from "../../../utils/schemas";
import EditTitle from "../../../ui/edit-title";
import CustomEditToolbar from "../../../ui/custom-edit-toolbar";
import { useGetList } from "ra-core";
import CustomSelect from "../../../ui/custom-select";
import GroupFields from "../../../styled/group-fields";
import { useWatch, useFormContext } from "react-hook-form";

const Schema = Yup.object({
  customer_id: hasValue(),
  name: minLength(2, "Digite o nome"),
  type: hasValue(),
  latitude: hasValue(),
  longitude: hasValue(),
  radius: hasValue(),
  power: hasValue(),
});

export const TransmitterClasses = [
  "CLASSE E1",
  "CLASSE E2",
  "CLASSE E3",
  "CLASSE A1",
  "CLASSE A2",
  "CLASSE A3",
  "CLASSE A4",
  "CLASSE B1",
  "CLASSE B2",
  "CLASSE C",
];

const TransmitterClassesPower = {
  "CLASSE E1": 100,
  "CLASSE E2": 75,
  "CLASSE E3": 60,
  "CLASSE A1": 50,
  "CLASSE A2": 30,
  "CLASSE A3": 15,
  "CLASSE A4": 5,
  "CLASSE B1": 3,
  "CLASSE B2": 1,
  "CLASSE C": 0.3,
};

const TransmitterClassesRadius = {
  "CLASSE E1": 78000,
  "CLASSE E2": 66000,
  "CLASSE E3": 54000,
  "CLASSE A1": 40000,
  "CLASSE A2": 36000,
  "CLASSE A3": 31000,
  "CLASSE A4": 24000,
  "CLASSE B1": 16000,
  "CLASSE B2": 12000,
  "CLASSE C": 7000,
};

const getTransmitterClasseRadius = (c) => {
  if (TransmitterClassesRadius.hasOwnProperty(c))
    return TransmitterClassesRadius[c];
  return "";
};

const getTransmitterClassePower = (c) => {
  if (TransmitterClassesPower.hasOwnProperty(c))
    return TransmitterClassesPower[c];
  return "";
};

export const TransmitterRadiusUpdater = (props) => {
  const name = useWatch({ name: "name" });
  const form = useFormContext();

  React.useEffect(() => {
    form.setValue("radius", getTransmitterClasseRadius(name), {
      shouldDirty: true,
    });
    form.setValue("power", getTransmitterClassePower(name), {
      shouldDirty: true,
    });
  }, [name]);

  return <noscript />;
};

const CustomerTransmitterEdit = () => {
  const { data: customer, isLoading: isLoadingCustomer } = useGetList(
    "customer",
    { pagination: { page: 1, perPage: 1000000 } }
  );

  return (
    <Edit
      title={<EditTitle subtitle="Editar Transmissor: " source="name" />}
      redirect="list"
      mutationMode="pessimistic"
      sx={ContentPageSX}
    >
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomEditToolbar />}
      >
        {!isLoadingCustomer && (
          <CustomSelect
            data={customer}
            name="customer_id"
            label="Afiliado"
            multiple={false}
            fullWidth
            isRequired
          />
        )}

        <CustomSelect
          data={TransmitterClasses.map((e) => ({
            name: e,
            id: e,
          }))}
          name="name"
          label="Nome (Classe)"
          multiple={false}
          fullWidth
          isRequired
        />

        {/* <CustomTextField name="name" label="Nome" fullWidth isRequired /> */}
        <CustomTextField name="type" label="Tipo" fullWidth isRequired />
        <GroupFields>
          <CustomTextField
            name="latitude"
            label="Latitude"
            fullWidth
            isRequired
            type="number"
          />
          <CustomTextField
            name="longitude"
            label="Longitude"
            fullWidth
            isRequired
            type="number"
          />
        </GroupFields>
        <GroupFields>
          <CustomTextField
            name="radius"
            label="Radio de alcance (metros)"
            fullWidth
            isRequired
            type="number"
          />
          <CustomTextField
            name="power"
            label="Potência (kW)"
            fullWidth
            isRequired
            type="number"
          />
        </GroupFields>
        <TransmitterRadiusUpdater />

        <FileInput
          source="coverage"
          label="Mancha de cobertura (KMZ), Tamanho Máximo: 30 MB"
          multiple={false}
          maxSize={30000000}
          accept=".kml,.kmz"
        >
          <FileField src="src" title="title" source="src" />
        </FileInput>

        <FileInput
          source="contour"
          label="Contorno (KMZ), Tamanho Máximo: 30 MB"
          multiple={false}
          maxSize={30000000}
          accept=".kml,.kmz"
        >
          <FileField src="src" title="title" source="src" />
        </FileInput>

        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Edit>
  );
};

export default CustomerTransmitterEdit;

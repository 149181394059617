import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = notifications =>
{
  const data = notifications.map(notification => (
  {
    id: notification.id,
    afiliado: notification.customer_name,
    titulo: notification.title,
    mensagem: notification.message,
    lida: (notification.read ? 'Sim' : 'Não'),
    criado_em: notification.created_at_format
  }));
  const csv = convertToCSV(
  {
    data,
    fields: ['id', 'afiliado', 'titulo', 'mensagem', 'lida', 'criado_em']
  });
  downloadCSV(csv, 'notifications');
}

export default exporter;

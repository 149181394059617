import React, { useRef, useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import { setRadioPlay } from "./store";
import "./geo-radio-player.css";
import Slider from "@mui/material/Slider";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

const selectedRadioStuff = (state) => ({
  radioPlay: state.radioPlay,
  radioStreamUrl: state.radioStreamUrl,
  radioName: state.radioName,
});

const GeoRadioPlayer = () => {
  const { radioPlay, radioStreamUrl, radioName } = useSelector(
    selectedRadioStuff,
    shallowEqual
  );
  const dispatch = useDispatch();
  const audioRef = useRef(null);
  const [volume, setVolume] = useState(100);

  useEffect(() => {
    if (!audioRef.current) return;

    try {
      if (radioPlay) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    } catch (e) {
      console.log(e);
    }
  }, [radioPlay, radioStreamUrl]);

  const togglePlayStatus = React.useCallback(() => {
    dispatch(setRadioPlay(!radioPlay));
  }, [dispatch, radioPlay]);

  const handleChangeVolume = (volume) => {
    if (!audioRef.current) return;
    audioRef.current.volume = volume / 100.0;
    setVolume(volume);
  };

  if (!radioStreamUrl) return null;

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "1rem",
        background: "rgba(0,0,0,0.1)",
        borderRadius: 25,
        border: "1px solid rgba(0,0,0,0.1)",
      })}
    >
      <audio
        ref={audioRef}
        src={radioStreamUrl}
        autoPlay={false}
        preload={false}
      ></audio>
      <IconButton
        onClick={togglePlayStatus}
        sx={{ color: "white" }}
        size="small"
      >
        {radioPlay ? <PauseCircleOutlineIcon /> : <PlayCircleOutlineIcon />}
      </IconButton>

      <Box className="radio-marquee-container" sx={{ width: 100 }}>
        <div className="radio-marquee-text">
          <Typography variant="body2">{radioName}</Typography>
        </div>
      </Box>

      <Box sx={{ display: "flex", width: 40, marginRight: 1, marginLeft: 1 }}>
        <Slider
          aria-label="Volume"
          size="small"
          sx={{ color: "white" }}
          min={0}
          max={100}
          value={volume}
          onChange={(e, v) => handleChangeVolume(v)}
        />
      </Box>
      <VolumeUpIcon sx={{ marginRight: 0.5 }} />
    </Box>
  );
};

export default GeoRadioPlayer;

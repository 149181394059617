import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = spots =>
{
  const data = spots.map(spot => (
  {
    id: spot.id,
    cliente: spot.client.name,
    plano: spot.type_plan.name,
    nome: spot.name,
    tempo: spot.time,
    agencia: spot.agency,
    executiva_comercial: spot.commercial_executive,
    observacao: spot.note,
    emissora: spot.broadcaster,
    periodo_veiculacao: spot.broadcast,
    url: spot.url,
    tipo: spot.type,
    cidades: spot.cities_name,
    regioes: spot.regions_name,
    afiliados: spot.customers_name,
    criado_em: spot.created_at_format
  }));
  const csv = convertToCSV(
  {
    data,
    fields: ['id', 'cliente', 'plano', 'nome', 'tempo', 'agencia', 'executiva_comercial',
             'observacao', 'numero', 'emissora', 'periodo_veiculacao', 'url',
             'tipo', 'cidades', 'regioes', 'afiliados', 'criado_em']
  });
  downloadCSV(csv, 'spots');
}

export default exporter;

import { ReferenceInput, SelectInput } from "react-admin";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import CustomTextField from "../ui/custom-text-field";

const CustomFilterCustomerSpotReceipt = [
  <CustomTextField
    label="Buscar"
    source="q"
    alwaysOn
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput
    label="Spot"
    alwaysOn
    source="spot_id"
    reference="customer-spot-receipt-spots"
  >
    <SelectInput
      label="Spot"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}
    />
  </ReferenceInput>
];

export default CustomFilterCustomerSpotReceipt;

import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = spotDownloads =>
{
  const data = spotDownloads.map(spotDownload => (
  {
    id: spotDownload.id,
    spot: spotDownload.spot.name,
    afiliado: spotDownload.customer.profile.name,
    latitude: spotDownload.lat,
    longitude: spotDownload.lng,
    data: spotDownload.created_at_format
  }));
  const csv = convertToCSV(
  {
    data,
    fields: ['id', 'spot', 'afiliado', 'latitude', 'longitude', 'data']
  });
  downloadCSV(csv, 'spot-downloads');
}

export default exporter;

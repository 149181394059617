import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { useInput } from "react-admin";
import { Button, Chip, InputLabel, FormHelperText } from "@mui/material";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { useEffect, useRef, useState } from "react";

const gregorian_pt_br = {
  name: "gregorian_pt_br",
  months: [
    ["Janeiro", "Jan"],
    ["Fevereiro", "Fev"],
    ["Março", "Mar"],
    ["Abril", "Abr"],
    ["Maio", "Mai"],
    ["Junho", "Jun"],
    ["Julho", "Jul"],
    ["Agosto", "Aug"],
    ["Setembro", "Set"],
    ["Outubro", "Out"],
    ["Novembro", "Nov"],
    ["Dezembro", "Dez"],
  ],
  weekDays: [
    ["Sábado", "Sáb"],
    ["Domingo", "Dom"],
    ["Segunda", "Seg"],
    ["Terça-feira", "Ter"],
    ["Quarta-feira", "Qua"],
    ["Quinta-feira", "Qui"],
    ["Sexta", "Sex"],
  ],
  digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
  meridiems: [
    ["AM", "am"],
    ["PM", "pm"],
  ],
};

const CustomButton = ({ openCalendar, label }) => {
  return (
    <Button
      startIcon={<InsertInvitationIcon />}
      variant="contained"
      onClick={openCalendar}
    >
      {label}
    </Button>
  );
};

const CustomMultiDatePicker = ({ initDate, endDate, dates, ...props }) => {
  const { onChange, onBlur } = props;
  const {
    field,
    fieldState: { error },
    formState: { isSubmitted },
    isRequired,
  } = useInput({ onChange, onBlur, ...props });

  const [values, setValues] = useState([]);
  const datePickerRef = useRef();

  const reset = () => {
    setValues([]);
    field.onChange(null);
  };

  useEffect(() => {
    reset();
  }, [initDate, endDate]);

  useEffect(() => {
    if (dates) setValues(dates);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        margin: "10px 0 30px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <div>
          <InputLabel required={isRequired} style={{ padding: 0 }}>
            {props.label}
          </InputLabel>
          {isSubmitted && error && error.message && (
            <FormHelperText>{error.message}</FormHelperText>
          )}
        </div>
        <DatePicker
          ref={datePickerRef}
          multiple
          value={values}
          minDate={initDate}
          maxDate={endDate}
          render={<CustomButton label={props.callToAction} />}
          plugins={[<DatePanel />]}
          locale={gregorian_pt_br}
          format="DD/MM/YYYY"
          onChange={(dates) => {
            const toString = dates.join(",");
            field.onChange(toString.length === 0 ? null : toString);
            const toArray = toString.split(",");
            setValues(
              toArray.length === 0 ||
                (toArray.length === 1 && toArray[0] === "")
                ? []
                : toArray
            );
          }}
        />
      </div>
      <div style={{ display: "flex", gap: "0.5rem", marginTop: 5 }}>
        {values.length > 0 &&
          values.map((item, index) => <Chip key={index} label={item} />)}
      </div>
    </div>
  );
};

export default CustomMultiDatePicker;

import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = daysGroup =>
{
  const data = daysGroup.map(dayGroup => (
  {
    id: dayGroup.id,
    nome: dayGroup.name,
    dias: dayGroup.days_name,
    criado_em: dayGroup.created_at_format
  }));
  const csv = convertToCSV(
  {
    data,
    fields: ['id', 'nome', 'dias', 'criado_em']
  });
  downloadCSV(csv, 'days-group');
}

export default exporter;

import React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { DatagridSX, ListSX } from '../../../styled/custom';
import CustomFilterMediaDownload from '../../../filter/custom-filter-media-download';
import CustomListFilterExportToolbar from '../../../ui/custom-list-filter-export-toolbar';
import MediaDownloadExporter from '../../../exporter/media-download-exporter';

const MediaDownloadList = () => (
  <List
    title="Downloads de Mídia"
    sort={{ field: 'id', order: 'desc' }}
    filters={ CustomFilterMediaDownload }
    exporter={ MediaDownloadExporter }
    actions={ <CustomListFilterExportToolbar /> }
    sx={ListSX}>
    <Datagrid
      bulkActionButtons={false}
      sx={DatagridSX}>
      <TextField source="id" sortable={false} />
      <TextField source="media.name" label="Mídia" sortable={false} />
      <TextField source="customer.profile.name" label="Afiliado" sortable={false} />
      <TextField source="city" label="Cidade" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Data" sortable={false} showTime />
    </Datagrid>
  </List>
);

export default MediaDownloadList;

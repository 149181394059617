import React from "react";
import { Admin, CustomRoutes, Resource } from "react-admin";
import { Route } from "react-router-dom";
import LoginPage from "./view/login-page";
import CustomLayout from "./ui/custom-layout";
import polyglotI18nProvider from "ra-i18n-polyglot";
import portugueseMessages from "./i18n/pt";
import dataProvider from "./provider/data-provider";
import authProvider from "./provider/auth-provider";
import config from "./config/config";
import theme from "./customTheme";

// CUSTOMER PROGRAMMING MAP
import CustomerProgrammingMapIcon from "@mui/icons-material/SupervisedUserCircle";
import CustomerProgrammingMapList from "./container/user-customer/customer-programming-map/list";

// CUSTOMER MEDIA
import CustomerMediaIcon from "@mui/icons-material/NotStarted";
import CustomerMediaList from "./container/user-customer/customer-media/list";
import CustomerMediaShow from "./container/user-customer/customer-media/show";

// CUSTOMER SPOT RECEIPT
import CustomerSpotReceiptIcon from "@mui/icons-material/ReceiptLong";
import CustomerSpotReceiptList from "./container/user-customer/customer-spot-receipt/list";
import CustomerSpotReceiptCreate from "./container/user-customer/customer-spot-receipt/create";

// NOTIFICATIONS
import CustomerNotificationsIcon from "@mui/icons-material/Notifications";
import CustomerNotificationList from "./container/user-customer/customer-notification/list";
import CustomerNotificationShow from "./container/user-customer/customer-notification/show";

// ADMIN
import AdminIcon from "@mui/icons-material/SupervisorAccount";
import AdminList from "./container/user-admin/admin/list";
import AdminCreate from "./container/user-admin/admin/create";
import AdminEdit from "./container/user-admin/admin/edit";
import AdminShow from "./container/user-admin/admin/show";

// REGION
import RegionIcon from "@mui/icons-material/SouthAmerica";
import RegionList from "./container/user-admin/region/list";
import RegionCreate from "./container/user-admin/region/create";
import RegionEdit from "./container/user-admin/region/edit";
import RegionShow from "./container/user-admin/region/show";

// DAYS GROUP
import DaysGroupIcon from "@mui/icons-material/DateRange";
import DaysGroupList from "./container/user-admin/days-group/list";
import DaysGroupCreate from "./container/user-admin/days-group/create";
import DaysGroupEdit from "./container/user-admin/days-group/edit";
import DaysGroupShow from "./container/user-admin/days-group/show";

// TYPE SPOT
import TypeSpotIcon from "@mui/icons-material/Preview";
import TypeSpotList from "./container/user-admin/type-spot/list";
import TypeSpotCreate from "./container/user-admin/type-spot/create";
import TypeSpotEdit from "./container/user-admin/type-spot/edit";
import TypeSpotShow from "./container/user-admin/type-spot/show";

// TYPE SCHEME
import TypeSchemeIcon from "@mui/icons-material/CellTower";
import TypeSchemeList from "./container/user-admin/type-scheme/list";
import TypeSchemeCreate from "./container/user-admin/type-scheme/create";
import TypeSchemeEdit from "./container/user-admin/type-scheme/edit";
import TypeSchemeShow from "./container/user-admin/type-scheme/show";

// TYPE PLAN
import TypePlanIcon from "@mui/icons-material/NextPlan";
import TypePlanList from "./container/user-admin/type-plan/list";
import TypePlanCreate from "./container/user-admin/type-plan/create";
import TypePlanEdit from "./container/user-admin/type-plan/edit";
import TypePlanShow from "./container/user-admin/type-plan/show";

// PROGRAM
import ProgramIcon from "@mui/icons-material/Terminal";
import ProgramList from "./container/user-admin/program/list";
import ProgramCreate from "./container/user-admin/program/create";
import ProgramEdit from "./container/user-admin/program/edit";
import ProgramShow from "./container/user-admin/program/show";

// CATEGORY MEDIA
import CategoryMediaIcon from "@mui/icons-material/Class";
import CategoryMediaList from "./container/user-admin/category-media/list";
import CategoryMediaCreate from "./container/user-admin/category-media/create";
import CategoryMediaEdit from "./container/user-admin/category-media/edit";
import CategoryMediaShow from "./container/user-admin/category-media/show";

// MEDIA
import MediaIcon from "@mui/icons-material/NotStarted";
import MediaList from "./container/user-admin/media/list";
import MediaCreate from "./container/user-admin/media/create";
import MediaEdit from "./container/user-admin/media/edit";
import MediaShow from "./container/user-admin/media/show";

// MEDIA DOWNLOAD
import MediaDownloadIcon from "@mui/icons-material/DownloadForOffline";
import MediaDownloadList from "./container/user-admin/media-download/list";

// MEDIA DOWNLOAD MAP
import MediaDownloadMapIcon from "@mui/icons-material/Map";
import MediaDownloadMapList from "./container/user-admin/media-download-map/list";

// CLIENT
import ClientIcon from "@mui/icons-material/SupervisedUserCircle";
import ClientList from "./container/user-admin/client/list";
import ClientCreate from "./container/user-admin/client/create";
import ClientEdit from "./container/user-admin/client/edit";
import ClientShow from "./container/user-admin/client/show";

// CUSTOMER
import CustomerIcon from "@mui/icons-material/SupportAgent";
import CustomerList from "./container/user-admin/customer/list";
import CustomerCreate from "./container/user-admin/customer/create";
import CustomerEdit from "./container/user-admin/customer/edit";
import CustomerShow from "./container/user-admin/customer/show";

// SPOT
import SpotIcon from "@mui/icons-material/Movie";
import SpotList from "./container/user-admin/spot/list";
import SpotCreate from "./container/user-admin/spot/create";
import SpotEdit from "./container/user-admin/spot/edit";
import SpotShow from "./container/user-admin/spot/show";

// SPOT FILE
import SpotFileIcon from "@mui/icons-material/AttachFile";
import SpotFileList from "./container/user-admin/spot-file/list";
import SpotFileCreate from "./container/user-admin/spot-file/create";

// SPOT DOWNLOAD
import SpotDownloadIcon from "@mui/icons-material/CloudDownload";
import SpotDownloadList from "./container/user-admin/spot-download/list";

// SPOT RECEIPT
import SpotReceiptIcon from "@mui/icons-material/ReceiptLong";
import SpotReceiptList from "./container/user-admin/spot-receipt/list";

// SPOT DOWNLOAD MAP
import SpotDownloadMapIcon from "@mui/icons-material/Map";
import SpotDownloadMapList from "./container/user-admin/spot-download-map/list";

// NOTIFICATION
import NotificationIcon from "@mui/icons-material/CircleNotifications";
import NotificationList from "./container/user-admin/notification/list";
import NotificationCreate from "./container/user-admin/notification/create";
import NotificationShow from "./container/user-admin/notification/show";

// STAFF MEMBER
import CustomerStaffMemberIcon from "@mui/icons-material/AssignmentInd";
import CustomerStaffMemberEdit from "./container/user-admin/customer-staff-member/edit";
import CustomerStaffMemberCreate from "./container/user-admin/customer-staff-member/create";
import CustomerStaffMemberList from "./container/user-admin/customer-staff-member/list";
import CustomerStaffMemberShow from "./container/user-admin/customer-staff-member/show";

// TRANSMITTER
import CustomerTransmitterIcon from "@mui/icons-material/CellTower";
import CustomerTransmitterList from "./container/user-admin/customer-transmitter/list";
import CustomerTransmitterCreate from "./container/user-admin/customer-transmitter/create";
import CustomerTransmitterEdit from "./container/user-admin/customer-transmitter/edit";
import CustomerTransmitterShow from "./container/user-admin/customer-transmitter/show";

// GEO
import GeoMainPage from "./view/geo-main-page";
import SettingsEdit from "./container/user-admin/settings/edit";

// TESTIMONIAL
import TestimonialIcon from "@mui/icons-material/Message";
import TestimonialList from "./container/user-admin/testimonial/list";
import TestimonialCreate from "./container/user-admin/testimonial/create";
import TestimonialEdit from "./container/user-admin/testimonial/edit";
import TestimonialShow from "./container/user-admin/testimonial/show";

// AFFILIATION REQUEST
import AffiliationRequestIcon from "@mui/icons-material/AppRegistration";
import AffiliationRequestList from "./container/user-admin/affiliation-request/list";
import AffiliationRequestEdit from "./container/user-admin/affiliation-request/edit";

// MAP LAYER
import MapLayerIcon from "@mui/icons-material/Layers";
import MapLayerList from "./container/user-admin/map-layer/list";
import MapLayerCreate from "./container/user-admin/map-layer/create";
import MapLayerShow from "./container/user-admin/map-layer/show";
import MapLayerEdit from "./container/user-admin/map-layer/edit";

// CUSTOMER NOTE
import CustomerNoteIcon from "@mui/icons-material/Notes";
import CustomerNoteList from "./container/user-admin/customer-note/list";
import CustomerNoteCreate from "./container/user-admin/customer-note/create";
import CustomerNoteShow from "./container/user-admin/customer-note/show";
import CustomerNoteEdit from "./container/user-admin/customer-note/edit";

// DASHBOARD
import DashboardIcon from "@mui/icons-material/DashboardCustomize";
import DashboardList from "./container/user-admin/dashboard/list";
import DashboardCreate from "./container/user-admin/dashboard/create";
import DashboardEdit from "./container/user-admin/dashboard/edit";
import DashboardShow from "./container/user-admin/dashboard/show";


const i18nProvider = polyglotI18nProvider(() => portugueseMessages);

const App = () => (
  <Admin
    title="ItaSat - Dashboard"
    i18nProvider={i18nProvider}
    dataProvider={dataProvider}
    authProvider={authProvider}
    loginPage={LoginPage}
    theme={theme}
    layout={CustomLayout}
  >
    {(permissions) => {
      // if (!permissions)
      //   return;
      // CUSTOMER
      if (permissions === "admin-customer") {
        return [
          // NOTIFICATIONS
          <Resource
            name="customer_notification"
            options={{ label: "Notificações" }}
            icon={CustomerNotificationsIcon}
            list={CustomerNotificationList}
            show={CustomerNotificationShow}
          />,

          // PROGRAMMING MAP
          /* <Resource
              name="customer_programming_map"
              options={{ label: "Espelho de Veiculação" }}
              icon={CustomerProgrammingMapIcon}
              list={CustomerProgrammingMapList}
            />, */

          // CUSTOMER MEDIA
          <Resource
            name="customer_media"
            options={{ label: "Cesta de Produtos" }}
            icon={CustomerMediaIcon}
            list={CustomerMediaList}
            show={CustomerMediaShow}
          />,

          // CUSTOMER SPOT RECEIPT
          /*<Resource
              name="customer_spot_receipt"
              options={{ label: "Comprovantes" }}
              icon={CustomerSpotReceiptIcon}
              list={CustomerSpotReceiptList}
              create={CustomerSpotReceiptCreate}
            />*/
        ];
      }
      // ADMIN
      else {
        return [
          permissions.some((e) => e.key === config.roles.admin_gis) ? (
            <CustomRoutes noLayout>
              <Route path="/maps" element={<GeoMainPage />} />
            </CustomRoutes>
          ) : null,

          // USER ADMIN
          permissions.some((e) => e.key === config.roles.admin_users_admin) ? (
            <Resource
              name="admin"
              options={{
                label: "Usuários Admin",
                role: config.roles.admin_users_admin,
              }}
              icon={AdminIcon}
              list={AdminList}
              create={AdminCreate}
              edit={AdminEdit}
              show={AdminShow}
            />
          ) : null,

          // REGION
          permissions.some((e) => e.key === config.roles.admin_regions) ? (
            <Resource
              name="region"
              options={{ label: "Regiões", role: config.roles.admin_regions }}
              icon={RegionIcon}
              list={RegionList}
              create={RegionCreate}
              edit={RegionEdit}
              show={RegionShow}
            />
          ) : null,

          // DAYS GROUP
          permissions.some((e) => e.key === config.roles.admin_days_groups) ? (
            <Resource
              name="days_group"
              options={{
                label: "Grupos de Dias",
                role: config.roles.admin_days_groups,
              }}
              icon={DaysGroupIcon}
              list={DaysGroupList}
              create={DaysGroupCreate}
              edit={DaysGroupEdit}
              show={DaysGroupShow}
            />
          ) : null,

          // TYPE SPOT
          permissions.some((e) => e.key === config.roles.admin_types_spot) ? (
            <Resource
              name="type_spot"
              options={{
                label: "Tempos de Spot",
                role: config.roles.admin_types_spot,
              }}
              icon={TypeSpotIcon}
              list={TypeSpotList}
              create={TypeSpotCreate}
              edit={TypeSpotEdit}
              show={TypeSpotShow}
            />
          ) : null,

          // TYPE SCHEME
          permissions.some((e) => e.key === config.roles.admin_types_scheme) ? (
            <Resource
              name="type_scheme"
              options={{
                label: "Tipos de Veiculação",
                role: config.roles.admin_types_scheme,
              }}
              icon={TypeSchemeIcon}
              list={TypeSchemeList}
              create={TypeSchemeCreate}
              edit={TypeSchemeEdit}
              show={TypeSchemeShow}
            />
          ) : null,

          // TYPE PLAN
          permissions.some((e) => e.key === config.roles.admin_types_plan) ? (
            <Resource
              name="type_plan"
              options={{
                label: "Tipos de Plano",
                role: config.roles.admin_types_plan,
              }}
              icon={TypePlanIcon}
              list={TypePlanList}
              create={TypePlanCreate}
              edit={TypePlanEdit}
              show={TypePlanShow}
            />
          ) : null,

          // DASHBOARD
          permissions.some((e) => e.key === config.roles.admin_dashboards) ? (
            <Resource
              name="dashboard"
              options={{
                label: "Dashboards",
                role: config.roles.admin_dashboards,
              }}
              icon={DashboardIcon}
              list={DashboardList}
              create={DashboardCreate}
              edit={DashboardEdit}
              show={DashboardShow}
            />
          ) : null,

          // PROGRAM
          permissions.some((e) => e.key === config.roles.admin_programs) ? (
            <Resource
              name="program"
              options={{
                label: "Programas Retransmitidos",
                role: config.roles.admin_programs,
              }}
              icon={ProgramIcon}
              list={ProgramList}
              create={ProgramCreate}
              edit={ProgramEdit}
              show={ProgramShow}
            />
          ) : null,

          // CATEGORY MEDIA
          permissions.some(
            (e) => e.key === config.roles.admin_categories_media
          ) ? (
            <Resource
              name="category_media"
              options={{
                label: "Categorias de Mídia",
                role: config.roles.admin_categories_media,
              }}
              icon={CategoryMediaIcon}
              list={CategoryMediaList}
              create={CategoryMediaCreate}
              edit={CategoryMediaEdit}
              show={CategoryMediaShow}
            />
          ) : null,

          // MEDIA
          permissions.some((e) => e.key === config.roles.admin_medias) ? (
            <Resource
              name="media"
              options={{
                label: "Cesta de Produtos",
                role: config.roles.admin_medias,
              }}
              icon={MediaIcon}
              list={MediaList}
              create={MediaCreate}
              edit={MediaEdit}
              show={MediaShow}
            />
          ) : null,

          // MEDIA DOWNLOAD
          permissions.some((e) => e.key === config.roles.admin_medias) ? (
            <Resource
              name="media_download"
              options={{
                label: "Downloads de Mída",
                role: config.roles.admin_medias,
              }}
              icon={MediaDownloadIcon}
              list={MediaDownloadList}
            />
          ) : null,

          // MEDIA DOWNLOAD MAP
          permissions.some((e) => e.key === config.roles.admin_medias) ? (
            <Resource
              name="media_download_map"
              options={{
                label: "Mídia - Mapa de Downloads",
                role: config.roles.admin_medias,
              }}
              icon={MediaDownloadMapIcon}
              list={MediaDownloadMapList}
            />
          ) : null,

          // CLIENT
          permissions.some((e) => e.key === config.roles.admin_clients) ? (
            <Resource
              name="client"
              options={{ label: "Clientes", role: config.roles.admin_clients }}
              icon={ClientIcon}
              list={ClientList}
              create={ClientCreate}
              edit={ClientEdit}
              show={ClientShow}
            />
          ) : null,

          // CUSTOMER
          permissions.some((e) => e.key === config.roles.admin_customers) ? (
            <Resource
              name="customer"
              options={{
                label: "Afiliados",
                role: config.roles.admin_customers,
              }}
              icon={CustomerIcon}
              list={CustomerList}
              create={CustomerCreate}
              edit={CustomerEdit}
              show={CustomerShow}
            />
          ) : null,

          // CUSTOMER STAFF MEMBER
          permissions.some((e) => e.key === config.roles.admin_customers) ? (
            <Resource
              name="customer_staff_member"
              options={{
                label: "Membros do Staff",
                role: config.roles.admin_customers,
              }}
              icon={CustomerStaffMemberIcon}
              create={CustomerStaffMemberCreate}
              list={CustomerStaffMemberList}
              edit={CustomerStaffMemberEdit}
              show={CustomerStaffMemberShow}
            />
          ) : null,

          // CUSTOMER NOTES
          permissions.some((e) => e.key === config.roles.admin_customers) ? (
            <Resource
              name="customer_note"
              options={{
                label: "Notas do Afiliado",
                role: config.roles.admin_customers,
              }}
              icon={CustomerNoteIcon}
              create={CustomerNoteCreate}
              list={CustomerNoteList}
              edit={CustomerNoteEdit}
              show={CustomerNoteShow}
            />
          ) : null,

          // CUSTOMER TRANSMITTER
          permissions.some((e) => e.key === config.roles.admin_customers) ? (
            <Resource
              name="customer_transmitter"
              options={{
                label: "Transmissores",
                role: config.roles.admin_customers,
              }}
              icon={CustomerTransmitterIcon}
              create={CustomerTransmitterCreate}
              list={CustomerTransmitterList}
              edit={CustomerTransmitterEdit}
              show={CustomerTransmitterShow}
            />
          ) : null,

          // SPOT
          permissions.some((e) => e.key === config.roles.admin_spots) ? (
            <Resource
              name="spot"
              options={{
                label: "Mapa de Programação",
                role: config.roles.admin_spots,
              }}
              icon={SpotIcon}
              list={SpotList}
              create={SpotCreate}
              edit={SpotEdit}
              show={SpotShow}
            />
          ) : null,

          // SPOT FILE
          permissions.some((e) => e.key === config.roles.admin_spots) ? (
            <Resource
              name="spot_file"
              options={{
                label: "Arquivos do M.P.",
                role: config.roles.admin_spots,
              }}
              icon={SpotFileIcon}
              list={SpotFileList}
              create={SpotFileCreate}
            />
          ) : null,

          // SPOT DOWNLOAD
          permissions.some((e) => e.key === config.roles.admin_spots) ? (
            <Resource
              name="spot_download"
              options={{
                label: "Downloads de M.P.",
                role: config.roles.admin_spots,
              }}
              icon={SpotDownloadIcon}
              list={SpotDownloadList}
            />
          ) : null,

          // SPOT RECEIPT
          permissions.some((e) => e.key === config.roles.admin_spots) ? (
            <Resource
              name="spot_receipt"
              options={{
                label: "Comprovantes do M.P.",
                role: config.roles.admin_spots,
              }}
              icon={SpotReceiptIcon}
              list={SpotReceiptList}
            />
          ) : null,

          // SPOT DOWNLOAD MAP
          permissions.some((e) => e.key === config.roles.admin_spots) ? (
            <Resource
              name="spot_download_map"
              options={{
                label: "M.P. - Mapa de Downloads",
                role: config.roles.admin_spots,
              }}
              icon={SpotDownloadMapIcon}
              list={SpotDownloadMapList}
            />
          ) : null,

          // NOTIFICATION
          permissions.some(
            (e) => e.key === config.roles.admin_notifications
          ) ? (
            <Resource
              name="notification"
              options={{
                label: "Notificações",
                role: config.roles.admin_notifications,
              }}
              icon={NotificationIcon}
              list={NotificationList}
              create={NotificationCreate}
              show={NotificationShow}
            />
          ) : null,

          // USER ADMIN
          permissions.some((e) => e.key === config.roles.admin_users_admin) ? (
            <CustomRoutes>
              <Route path="/settings" element={<SettingsEdit />} />
            </CustomRoutes>
          ) : null,

          permissions.some((e) => e.key === config.roles.admin_users_admin) ? (
            <Resource
              name="testimonial"
              options={{
                label: "Depoimentos",
                role: config.roles.admin_users_admin,
              }}
              icon={TestimonialIcon}
              list={TestimonialList}
              create={TestimonialCreate}
              edit={TestimonialEdit}
              show={TestimonialShow}
            />
          ) : null,

          permissions.some((e) => e.key === config.roles.admin_users_admin) ? (
            <Resource
              name="affiliation_request"
              options={{
                label: "Requisições de afiliação",
                role: config.roles.admin_users_admin,
              }}
              icon={AffiliationRequestIcon}
              list={AffiliationRequestList}
              edit={AffiliationRequestEdit}
            />
          ) : null,

          permissions.some((e) => e.key === config.roles.admin_map_layers) ? (
            <Resource
              name="map_layer"
              options={{
                label: "Camadas do Mapa",
                role: config.roles.admin_map_layers,
              }}
              icon={MapLayerIcon}
              list={MapLayerList}
              create={MapLayerCreate}
              show={MapLayerShow}
              edit={MapLayerEdit}
            />
          ) : null,
        ];
      }
    }}
  </Admin>
);

export default App;

import React from "react";
import {
  AppBar,
  useAuthState,
  Button as RAButton,
  Link,
  usePermissions,
} from "react-admin";
import { AppBarRedSX, TypographySX } from "../../styled/custom";
import Toolbar from "@mui/material/Toolbar";
import logoWhite from "../../assets/logo-white.png";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { GeoSearchBox } from "./geo-search-box";
import { styled } from "@mui/material/styles";
import SettingsIcon from "@mui/icons-material/Settings";
import NumbersIcon from "@mui/icons-material/Numbers";
import NetworkIcon from "@mui/icons-material/Wifi";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import LoginIcon from "@mui/icons-material/Login";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowAffiliateRequest,
  setShowIntroduction,
  toggleItasatNetwork,
} from "./store";
import GeoRadioPlayer from "./geo-radio-player";

const CustomButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const CenterContainer = styled("div")(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
}));

const GeoAppBar = (props) => {
  const { authenticated } = useAuthState();
  const isAdmin = useSelector(state => state.isAdmin);
  const dispatch = useDispatch();

  const handleShowIntroduction = () => {
    dispatch(setShowIntroduction(true));
  };
  const handleShowAffiliationRequest = () => {
    dispatch(setShowAffiliateRequest(true));
  };

  const handleShowItasatNetwork = () => {
    dispatch(toggleItasatNetwork());
  };

  return (
    <AppBar {...props} sx={AppBarRedSX} userMenu={authenticated}>
      <img src={logoWhite} alt="Itatiaia" width={100} />
      <CenterContainer>
        {isAdmin && <GeoRadioPlayer />}
        {!isAdmin && <CustomButton
          variant="shadow-rounded-hover"
          startIcon={<NetworkIcon />}
          onClick={handleShowItasatNetwork}
        >
          Rede Itasat
        </CustomButton>}
        <GeoSearchBox />
      </CenterContainer>
      <Toolbar>
        {!authenticated && (
          <Button
            variant="shadow-rounded-hover"
            onClick={handleShowAffiliationRequest}
            startIcon={<AppRegistrationIcon />}
          >
            Quero ser afiliado
          </Button>
        )}

        <Button
          variant="shadow-rounded-hover"
          onClick={handleShowIntroduction}
          startIcon={<NumbersIcon />}
          label="Apresentação"
        >
          Apresentação
        </Button>

        {!authenticated && (
          <RAButton
            component={Link}
            to={{
              pathname: "/login",
            }}
            startIcon={<LoginIcon />}
            variant="shadow-rounded-hover"
            label="Login"
          >
            Login
          </RAButton>
        )}

        {authenticated && (
          <RAButton
            startIcon={<SettingsIcon />}
            component={Link}
            to={{
              pathname: "/admin",
            }}
            variant="shadow-rounded-hover"
            label="Painel"
          ></RAButton>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default GeoAppBar;

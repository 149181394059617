import React from "react";
import { Button, useRecordContext, Link } from "react-admin";
import CreateIcon from "@mui/icons-material/Add";

const ButtonCreateRelated = ({ resource, field, relatedField }) => {
  const record = useRecordContext();
  return (
    <Button
      component={Link}
      to={{
        pathname: `/${resource}/create`,
        search: `?${relatedField}=${record[field]}`,
      }}
      variant="contained"
      label="NOVO"
      startIcon={<CreateIcon />}
    />
  );
};

export default ButtonCreateRelated;

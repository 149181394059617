import {
  GoogleMap,
} from "@react-google-maps/api";
import { setAppMap } from "./store";
import React from "react";

const defaultMapCenter = { lat: -19.9109697, lng: -43.9466398 };

const GeoMaps = React.memo(({ children }) => {

  React.useEffect(() => {
    console.log("Montou o GeoMaps");
    return () => {
      console.log("Desmontou o GeoMaps");
    }
  }, [])

  return (
    <GoogleMap
      mapContainerStyle={{
        width: "100%",
        height: "100%",
      }}
      center={defaultMapCenter}
      zoom={12}
      onLoad={map => setAppMap(map)}
    >
      {children}
    </GoogleMap>
  );
});

export default GeoMaps;

import React from "react";
import { BooleanInput, Edit, ImageField, ImageInput, SimpleForm } from "react-admin";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomTextField from "../../../ui/custom-text-field";
import { ContentPageSX } from "../../../styled/custom";
import { email, hasValue, minLength } from "../../../utils/schemas";
import EditTitle from "../../../ui/edit-title";
import CustomEditToolbar from "../../../ui/custom-edit-toolbar";
import { useGetList } from "ra-core";
import CustomSelect from "../../../ui/custom-select";
import GroupFields from "../../../styled/group-fields";

const Schema = Yup.object({
  name: minLength(2, "Digite o nome"),
  position: minLength(2, "Digite o cargo"),
  radio: minLength(2, "Digite o nome da radio"),
  place: minLength(2, "Digite o local da radio"),
  message: minLength(2, "Digite o depoimento"),
});

const TestimonialEdit = () => {
  return (
    <Edit
      title={<EditTitle subtitle="Editar Depoimento: " source="name" />}
      redirect="list"
      mutationMode="pessimistic"
      sx={ContentPageSX}
    >
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomEditToolbar />}
      >
        <CustomTextField name="name" label="Nome" fullWidth isRequired />
        <CustomTextField name="position" label="Cargo" fullWidth isRequired />
        <CustomTextField name="radio" label="Radio" fullWidth isRequired />
        <CustomTextField name="place" label="Local" fullWidth isRequired />
        <CustomTextField name="message" label="Mensagem" fullWidth isRequired />

        <ImageInput source="files" label="Foto" multiple={false} accept="image/jpeg">
          <ImageField source="src" title="title" />
        </ImageInput>

        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Edit>
  );
};

export default TestimonialEdit;

import { TextField } from "@mui/material";
import { useInput } from "react-admin";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import ptLocale from "date-fns/locale/pt-BR";
import { useCallback, useState } from "react";

function CustomDateTimePickers({ Component, ...props }) {
  const { onChange, onBlur, ...rest } = props;
  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
    isRequired,
  } = useInput({ onChange, onBlur, ...props });
  const [value, setValue] = useState(field && field.value ? field.value : null);

  const handleChange = useCallback(
    (value) => {
      setValue(value);
      field.onChange(value);
    },
    [field]
  );

  const handleBlur = () => {
    field.onBlur(field.value ? field.value : null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
      <Component
        {...field}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              error={(isTouched || isSubmitted) && invalid}
              helperText={
                (isTouched || isSubmitted) && invalid ? error.message : ""
              }
              required={isRequired}
              {...rest}
            />
          );
        }}
        {...rest}
      />
    </LocalizationProvider>
  );
}

export const DateInput = (props) => (
  <CustomDateTimePickers Component={DatePicker} {...props} />
);
export const DateTimeInput = (props) => (
  <CustomDateTimePickers Component={DateTimePicker} {...props} />
);
export const TimeInput = (props) => (
  <CustomDateTimePickers Component={TimePicker} {...props} />
);

import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = regions =>
{
  const data = regions.map(region => (
  {
    id: region.id,
    nome: region.name,
    cidades: region.cities_name,
    criado_em: region.created_at_format
  }));
  const csv = convertToCSV(
  {
    data,
    fields: ['id', 'nome', 'cidades', 'criado_em']
  });
  downloadCSV(csv, 'regions');
}

export default exporter;

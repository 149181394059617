import React from "react";
import { BooleanInput, Edit, NumberInput, SimpleForm } from "react-admin";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomTextField from "../../../ui/custom-text-field";
import { ContentPageSX } from "../../../styled/custom";
import { color, inRange, minLength } from "../../../utils/schemas";
import EditTitle from "../../../ui/edit-title";
import CustomEditToolbar from "../../../ui/custom-edit-toolbar";
import { ColorInput } from "react-admin-color-picker";

const Schema = Yup.object({
  name: minLength(2, "Digite o nome"),
  fill_opacity: inRange(0.0, 1.0),
  stroke_opacity: inRange(0.0, 1.0),
  stroke_width: inRange(1, 40),
  stroke_color: color(),
  fill_color: color(),
});

const MapLayerEdit = () => {
  return (
    <Edit
      title={<EditTitle subtitle="Editar Camada do Mapa: " source="name" />}
      redirect="list"
      mutationMode="pessimistic"
      sx={ContentPageSX}
    >
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomEditToolbar />}
      >
        <CustomTextField name="name" label="Nome" fullWidth isRequired />
        <BooleanInput source="active" label="Ativo" />

        <ColorInput source="stroke_color" name="stroke_color" label="Cor do traçado" variant='filled' />
        <NumberInput source="stroke_width" name="stroke_width" label="Espessura do traçado" variant="filled" defaultValue={1} />
        <NumberInput source="stroke_opacity" name="stroke_opacity" label="Opacidade do traçado" variant="filled" defaultValue={1} />
        <ColorInput source="fill_color" name="fill_color" label="Cor do preenchimento" variant='filled' />
        <NumberInput source="fill_opacity" name="fill_opacity" label="Opacidade do preenchimento" variant="filled" defaultValue={1} />

      </SimpleForm>
    </Edit>
  );
};

export default MapLayerEdit;

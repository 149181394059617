import { configureStore, createSlice } from "@reduxjs/toolkit";

let _appMap = null;

export const createFeatureInformation = (name, link, data, metadata) => ({
  name,
  link,
  data,
  metadata,
});

const initialState = {
  openDrawer: false,
  drawerType: "",
  isAdmin: false,

  radioPlay: false,
  radioStreamUrl: "", //https://8903.brasilstream.com.br/stream',
  radioName: "",

  showIntroduction: false,
  showAffiliationRequest: false,
  showItasatNetwork: false,

  showPopulationLayer: false,
  showPibLayer: false,
  showCoverageLayer: false,
  showCoverageContourLayer: false,
  showCoverageSpotLayer: false,
  showCoverageSubItems: [],
  showAffiliatesLayer: true,
  affiliatesLayerFilter: ["SEDE", "FILIAL", "AFILIADA", "PROSPECÇÃO"],
  showRegionsLayer: false,
  showRadiosnetLayer: false,
  showRadiosnetHeatmapLayer: false,

  radiosSelectList: [],
  radiosSelectPosition: {},
  radiosSelectOpen: false,
  radiosSelected: null,
  showRadiosSelectedProfile: false,

  regionsList: [],
  regionsFilter: [],

  publicRadios: [],
  selectedRadio: null,
  publicRadioFilter: "",

  showQueryTool: false,
  queryShape: "none",
  queryColor: "#ba00ff",
  regionQueryList: [],

  showCustomerProfile: false,
  customerId: null,

  showFeatureInformation: false,
  featureInformation: createFeatureInformation("", "", {}),

  mapLayers: [],
};

const closeOtherDrawers = (state, drawer) => {
  const list = [
      "showQueryTool",
      "showCustomerProfile",
      "showRadiosSelectedProfile",
      "showItasatNetwork",
      "showFeatureInformation",
  ];
  for (let item of list) {
    if (item !== drawer)
      state[item] = false;
  }
}

export const appSlice = createSlice({
  name: "geo",
  initialState,
  reducers: {
    setIsAdmin(state, action) {
      state.isAdmin = action.payload;
    },
    setShowIntroduction(state, action) {
      state.showIntroduction = action.payload;
    },
    setShowAffiliateRequest(state, action) {
      state.showAffiliationRequest = action.payload;
    },
    setDrawerType(state, action) {
      state.drawerType = action.payload;
    },
    toggleItasatNetwork(state, action) {
      if (action.payload == null) {
        state.showItasatNetwork = !state.showItasatNetwork;
      } else {
        state.showItasatNetwork = action.payload;
      }
      if (state.showItasatNetwork) {
        closeOtherDrawers(state, "showItasatNetwork");
      }
    },
    toggleQueryTool(state, action) {
      if (action.payload == null) {
        state.showQueryTool = !state.showQueryTool;
      } else {
        state.showQueryTool = action.payload;
      }
      
      if (state.showQueryTool) {
        closeOtherDrawers(state, "showQueryTool");
      }
    },
    toggleFeatureInformation(state, action) {
      if (action.payload == null) {
        state.showFeatureInformation = !state.showFeatureInformation;
      } else {
        state.showFeatureInformation = action.payload;
      }

      if (state.showFeatureInformation) {
        closeOtherDrawers(state, "showFeatureInformation");
      }
    },
    setFeatureInformation(state, action) {
      state.featureInformation = action.payload;
    },
    setShowPopulationLayer(state, action) {
      state.showPopulationLayer = action.payload;
    },
    setShowPibLayer(state, action) {
      state.showPibLayer = action.payload;
    },
    setShowRadiosnetLayer(state, action) {
      state.showRadiosnetLayer = action.payload;
    },
    setShowRadiosnetHeatmapLayer(state, action) {
      state.showRadiosnetHeatmapLayer = action.payload;
    },
    setShowCoverageLayer(state, action) {
      state.showCoverageLayer = action.payload;
    },
    setShowCoverageContourLayer(state, action) {
      state.showCoverageContourLayer = action.payload;
    },
    setShowCoverageSpotLayer(state, action) {
      state.showCoverageSpotLayer = action.payload;
    },
    setShowAffiliatesLayer(state, action) {
      state.showAffiliatesLayer = action.payload;
    },
    toggleAffiliatesLayerFilter(state, action) {
      const filter = action.payload;
      const idx = state.affiliatesLayerFilter.indexOf(filter);
      if (idx >= 0) {
        state.affiliatesLayerFilter.splice(idx, 1);
      } else {
        state.affiliatesLayerFilter.push(filter);
      }
    },
    setShowRegionsLayer(state, action) {
      state.showRegionsLayer = action.payload;
    },
    setRegionsList(state, action) {
      state.regionsList = action.payload;
    },
    setRegionsFilter(state, action) {
      state.regionsFilter = action.payload;
    },
    toggleRegionFilter(state, action) {
      const filter = action.payload;
      const idx = state.regionsFilter.indexOf(filter);
      if (idx >= 0) {
        state.regionsFilter.splice(idx, 1);
      } else {
        state.regionsFilter.push(filter);
      }
    },

    setPublicRadios(state, action) {
      state.publicRadios = action.payload;
    },
    setSelectedRadio(state, action) {
      state.selectedRadio = action.payload;
    },
    setPublicRadioFilter(state, action) {
      state.publicRadioFilter = action.payload;
    },
    addRegionQuery(state, action) {
      state.regionQueryList.push(action.payload);
    },
    clearRegionQueries(state, action) {
      state.regionQueryList.forEach((e) => e.feature.setMap(null));
      state.regionQueryList = [];
    },
    setQueryShape(state, action) {
      if (action.payload === "circle" || action.payload === "polygon")
        state.queryShape = action.payload;
      else state.queryShape = "none";
    },
    setQueryColor(state, action) {
      state.queryColor = action.payload;
    },
    toggleRegionVisibility(state, action) {
      const { index, value } = action.payload;
      if (index < state.regionQueryList.length) {
        if (value == null) {
          if (state.regionQueryList[index].visible) {
            state.regionQueryList[index].feature.setMap(null);
            state.regionQueryList[index].visible = false;
          } else {
            state.regionQueryList[index].feature.setMap(state.map);
            state.regionQueryList[index].visible = true;
          }
        } else {
          if (value) {
            state.regionQueryList[index].feature.setMap(state.map);
            state.regionQueryList[index].visible = true;
          } else {
            state.regionQueryList[index].feature.setMap(null);
            state.regionQueryList[index].visible = false;
          }
        }
      }
    },
    removeRegion(state, action) {
      const index = action.payload;
      if (index < state.regionQueryList.length && index >= 0) {
        let newList = [...state.regionQueryList];
        state.regionQueryList[index].feature.setMap(null);
        newList.splice(index, 1);
        state.regionQueryList = newList;
      }
    },

    toggleCustomerProfile(state, action) {
      if (action.payload == null) {
        state.showCustomerProfile = !state.showCustomerProfile;
      } else {
        state.showCustomerProfile = action.payload;
      }
      if (state.showCustomerProfile) {
        closeOtherDrawers(state, "showCustomerProfile");
      }
      
    },

    setCustomerId(state, action) {
      state.customerId = action.payload;
    },

    setMapLayers(state, action) {
      state.mapLayers = action.payload || [];
    },

    setMapLayerVisible(state, action) {
      const { id, visible } = action.payload;
      const idx = state.mapLayers.findIndex((item) => item.id === id);
      if (idx >= 0) {
        state.mapLayers[idx].visible = visible;
      }
    },

    setMapLayerLoading(state, action) {
      const { id, loading } = action.payload;
      const idx = state.mapLayers.findIndex((item) => item.id === id);
      if (idx >= 0) {
        state.mapLayers[idx].isLoading = loading;
      }
    },

    setRadioPlay(state, action) {
      state.radioPlay = action.payload;
    },

    setRadioStreamUrl(state, action) {
      state.radioStreamUrl = action.payload;
    },

    setRadioName(state, action) {
      state.radioName = action.payload;
    },

    setRadiosSelectList(state, action) {
      state.radiosSelectList = action.payload;
    },
    setRadiosSelectPosition(state, action) {
      state.radiosSelectPosition = action.payload;
    },
    setRadiosSelectOpen(state, action) {
      state.radiosSelectOpen = action.payload;
    },
    setRadiosSelected(state, action) {
      state.radiosSelected = action.payload;
    },
    setShowRadiosSelectedProfile(state, action) {
      state.showRadiosSelectedProfile = action.payload;
      if (state.showRadiosSelectedProfile) {
        closeOtherDrawers(state, "showRadiosSelectedProfile");
      }
    },
  },
});

export const setAppMap = (map) => (_appMap = map);
export const getAppMap = () => _appMap;

// Action creators are generated for each case reducer function
export const {
  setIsAdmin,
  setShowIntroduction,
  setShowAffiliateRequest,
  setDrawerType,
  toggleItasatNetwork,
  setShowPopulationLayer,
  setShowPibLayer,
  setShowCoverageLayer,
  setShowCoverageContourLayer,
  setShowCoverageSpotLayer,
  setShowAffiliatesLayer,
  toggleAffiliatesLayerFilter,
  setShowRegionsLayer,
  setPublicRadios,
  setSelectedRadio,
  setPublicRadioFilter,
  setFeatureInformation,
  toggleFeatureInformation,

  setRadiosSelectList,
  setRadiosSelectPosition,
  setRadiosSelectOpen,

  setShowRadiosnetLayer,
  setShowRadiosnetHeatmapLayer,

  addRegionQuery,
  clearRegionQueries,
  toggleQueryTool,
  setQueryShape,
  setQueryColor,
  toggleRegionVisibility,
  removeRegion,

  setRegionsList,
  setRegionsFilter,
  toggleRegionFilter,

  toggleCustomerProfile,
  setCustomerId,
  setMapLayers,
  setMapLayerVisible,
  setMapLayerLoading,

  setRadioPlay,
  setRadioStreamUrl,
  setRadioName,

  setRadiosSelected,
  setShowRadiosSelectedProfile,
} = appSlice.actions;

export const createStore = (initialState) => {
  return configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: true,
        serializableCheck: true,
      }),
    reducer: appSlice.reducer,
  });
};

let appStore = createStore();

export const getAppStore = () => appStore;
export const resetAppStore = () => {
  appStore = createStore();
};

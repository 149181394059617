import React from 'react';
import { Edit, SimpleForm, BooleanInput } from 'react-admin';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import EditTitle from '../../../ui/edit-title';
import CustomEditToolbar from '../../../ui/custom-edit-toolbar';
import CustomTextField from '../../../ui/custom-text-field';
import { minLength, hasValue } from '../../../utils/schemas';
import { ContentPageSX } from '../../../styled/custom';

const Schema = Yup.object(
{
  name: minLength(3, "Digite o nome"),
  time: hasValue("Digite o tempo")
});

const TypeSpotEdit = (props) => (
  <Edit
    title={ <EditTitle subtitle="Editar Tempo de Spot: " source="name" /> }
    redirect="list"
    mutationMode="pessimistic"
    sx={ContentPageSX}>
    <SimpleForm
      noValidate
      resolver={yupResolver(Schema)}
      toolbar={ <CustomEditToolbar /> }>
      <CustomTextField source="id" label="Id" disabled />
      <CustomTextField name="name" label="Nome" fullWidth isRequired />
      <CustomTextField type="number" name="time" label="Tempo" step={10} fullWidth isRequired />
      <BooleanInput source="active" label="Ativo" />
    </SimpleForm>
  </Edit>
);

export default TypeSpotEdit;

import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = spotFiles =>
{
  const data = spotFiles.map(spotFile => (
  {
    id: spotFile.id,
    spot: spotFile.spot_name,
    nome: spotFile.name,
    url: spotFile.url,
    tipo: spotFile.type,
    criado_em: spotFile.created_at_format
  }));
  const csv = convertToCSV(
  {
    data,
    fields: ['id', 'spot', 'nome', 'url', 'type', 'criado_em']
  });
  downloadCSV(csv, 'spotFiles');
}

export default exporter;

import { defaultTheme } from "react-admin";
import merge from "lodash/merge";

const theme = merge({}, defaultTheme, {
  palette: {
    mode: "light",
    primary: {
      main: "#2f2f2f",
    },
    secondary: {
      main: "#db1d3f",
    },
    success: {
      main: "#05ab47",
    },
    gray: {
      50: "#F7FAFC",
    },
  },
  typography: {
    fontFamily: [
      "TT Commons",
      "Flatface Haleys Sans",
      "Flatface Haleys Script",
    ].join(","),
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      variants: [
        {
          props: { variant: "red-rounded" },
          style: {
            background: "#db1d3f",
            border: `1px solid white`,
            borderRadius: 64,
            textTransform: "none",
            color: "white",
            "&:hover": {
              background: "rgba(219, 29, 63, 0.7)",
            }
          }
        },
        {
          props: { variant: "white-rounded" },
          style: {
            background: "#F7FAFC",
            border: `1px solid white`,
            borderRadius: 64,
            textTransform: "none",
            color: "#2f2f2f",
            "&:hover": {
              background: "rgba(0, 0, 0, 0.1)",
            }
          }
        },
        {
          props: { variant: "white-rounded-filled" },
          style: {
            background: "white",
            border: `1px solid white`,
            borderRadius: 64,
            textTransform: "none",
            color: "#db1d3f",
            "&:hover": {
              opacity: 1,
              background: "white"
            }
          }
        },
        {
          props: { variant: "shadow-rounded-hover" },
          style: {
            background: "none",
            border: "none",
            borderRadius: 64,
            textTransform: "none",
            "&:hover": {
              background: "rgba(0, 0, 0, 0.1)",
            }
          }
        }
      ]
    },
    MuiChip: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          fontSize: "1rem",
          backgroundColor: "#db1d3f",
          color: "white",
          margin: 0,
        },
        deleteIcon: {
          color: "white",
          "&:hover": {
            color: "white",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: "dense",
        size: "small",
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          paddingLeft: "12px",
          paddingRight: "4px",
        },
      },
    },
  },
});

export default theme;

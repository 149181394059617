import { styled } from "@mui/material";

const GroupFields = styled("div")({
  display: "flex",
  flexDirection: "row",
  gap: 6,
  width: "100%",
});

export default GroupFields;

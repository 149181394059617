import React from "react";
import { TopToolbar, FilterButton } from "react-admin";

const CustomListFilterToolbar = () => (
  <TopToolbar>
    <FilterButton />
  </TopToolbar>
);

export default CustomListFilterToolbar;

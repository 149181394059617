import React from 'react';
import { Edit, SimpleForm, BooleanInput, useGetList } from 'react-admin';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import EditTitle from '../../../ui/edit-title';
import CustomEditToolbar from '../../../ui/custom-edit-toolbar';
import CustomTextField from '../../../ui/custom-text-field';
import CustomSelect from '../../../ui/custom-select';
import { minLength, leastOneOption } from '../../../utils/schemas';
import { ContentPageSX } from '../../../styled/custom';

const Schema = Yup.object(
{
  name: minLength(3, "Digite o nome"),
  days_id: leastOneOption("Selecione pelo menos um dia")
});

const DaysGroupEdit = (props) =>
{
  const { data, isLoading } = useGetList("setup/days");
  return (
    <Edit
      title={ <EditTitle subtitle="Editar Grupo de Dia: " source="name" /> }
      redirect="list"
      mutationMode="pessimistic"
      sx={ContentPageSX}>
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomEditToolbar /> }>
        <CustomTextField source="id" label="Id" disabled />
        <CustomTextField name="name" label="Nome" fullWidth />
        { !isLoading && <CustomSelect data={data} name="days_id" label="Dia(s)" fullWidth isRequired /> }
        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Edit>
  );
}

export default DaysGroupEdit;
